<template>
  <div>
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="research == null && loadingResearch"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- reports logic -->
    <template v-if="research != null">
      <!-- when the resports is visible -->
      <div v-if="research.accepted_by_REC && research.accepted_by_RSC">
        <!-- reports list -->
        <div
          class="conatiner"
          v-if="
            (research.withFund && research.contract_approved) ||
            (!research.withFund && !research.contract_approved)
          "
        >
          <!-- new report button -->
          <div class="row my-3 justify-content-end">
            <!-- make report button -->
            <div
              class="col-md-3"
              v-if="
                research.status.name != 'Closed' &&
                research.status.name != 'Suspended'
              "
            >
              <button
                v-if="!admins()"
                @click="newReport"
                class="float-end btn btn-md btn-soft-info rounded-pill border border-info mx-2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z"
                    fill="#18446d"
                  ></path>
                  <path
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z"
                    fill="#104f8b"
                  ></path>
                </svg>
                Make a report
              </button>
            </div>
          </div>
          <!-- reports column -->
          <div class="row" v-if="reports.length > 0">
            <div
              class="reportsList-container col-lg-6 col-md-12 col-sm-12"
              v-for="report in reports"
              :key="report.id"
            >
              <ol class="p-0 px-2">
                <li
                  class="border border-1 shadow rounded p-3 position-relative my-3"
                >
                  <!-- report card -->
                  <div
                    class="numberCircle"
                    :class="report.final_report ? 'bg-danger border-white' : ''"
                  >
                    {{ report.final_report ? "" : report.report_num }}
                    <svg
                      v-if="report.final_report"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z"
                        fill="#ffff"
                      ></path>
                      <path
                        d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z"
                        fill="#ffff"
                      ></path>
                    </svg>
                  </div>
                  <reportCard :report="report" :key="report.id" />
                </li>
              </ol>
            </div>
            <!-- Pagination -->
            <pagination
              :links="reportsLinks"
              :perPage="reportsPerPage"
              :total="totalReports"
              :fetchNext="fetchReports"
              :inSubmission="in_submission"
            />
            <!-- End Pagination -->
          </div>
          <!-- loading the reports column -->
          <div
            class="d-flex justify-content-center text-primary"
            v-else-if="reports.length == 0 && in_submission"
          >
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <!-- no reports found yet -->
          <div class="row" v-else>
            <div class="col-auto text-center mt-5 pt-5">
              <img src="/img/oc-error.jpg" class="w-25" alt="no researches" />
              <p class="text-muted fw-bold fs-5 pt-5 text-center">
                there's no reports has been made yet.
              </p>
            </div>
          </div>
        </div>
        <!-- upload the contract for researcher -->
        <div class="container my-4" v-else-if="!admins()">
          <div class="row justify-content-center">
            <div class="col-auto text-center">
              <img src="/img/oc-marketing.jpg" class="w-25" alt="" />
              <p class="text-muted fw-bold fs-5 pt-5 text-center">
                Please upload your contract in order to make reports.
              </p>
              <button
                @click="uploadContract"
                class="btn btn-md btn-soft-info rounded-pill border border-info m-4"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M5 16C3.3 16 2 14.7 2 13C2 11.3 3.3 10 5 10H5.1C5 9.7 5 9.3 5 9C5 6.2 7.2 4 10 4C11.9 4 13.5 5 14.3 6.5C14.8 6.2 15.4 6 16 6C17.7 6 19 7.3 19 9C19 9.4 18.9 9.7 18.8 10C18.9 10 18.9 10 19 10C20.7 10 22 11.3 22 13C22 14.7 20.7 16 19 16H5ZM8 13.6H16L12.7 10.3C12.3 9.89999 11.7 9.89999 11.3 10.3L8 13.6Z"
                    fill="#104f8b"
                  ></path>
                  <path
                    d="M11 13.6V19C11 19.6 11.4 20 12 20C12.6 20 13 19.6 13 19V13.6H11Z"
                    fill="#003b73"
                  ></path>
                </svg>
                Upload contract
              </button>
            </div>
            <!-- previous upload -->
            <div class="col-12" v-if="contract != null">
              <!-- contract file -->
              <b class="text-secondary fs-6 mb-3">Uploaded contract:</b>
              <ul class="list-unstyled">
                <li class="pb-1">
                  <a
                    class="cursor-p fw-bold"
                    @click="downloadFile(contract.storage_path)"
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                        fill="#3182ce"
                      ></path>
                      <path
                        d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                        fill="#2670b6"
                      ></path>
                    </svg>
                    {{ contract.storage_path.split("/")[1] }}
                    <span
                      class="badge bg-danger mx-2"
                      v-if="!research.contract_approved"
                      >not approved yet</span
                    >
                    <span class="badge bg-success mx-2" v-else>approved</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- for super admin only-->
        <div class="container" v-else-if="hasRole(['admin'])">
          <div class="row justify-content-center">
            <div class="col-auto text-center mt-5 pt-5">
              <!--  no contract uploaded -->
              <div v-if="contract == null">
                <img src="/img/oc-error.jpg" class="w-25" alt="no researches" />
                <p class="text-muted fw-bold fs-5 pt-5 text-center">
                  there's no contract has been uploaded yet.
                </p>
              </div>
              <!-- review uploaded contract to Approve/unApprove -->
              <div class="text-center" v-else>
                <!-- contract file -->
                <b class="text-secondary fs-6 mb-3">Uploaded contract:</b>
                <ul class="list-unstyled">
                  <li class="pb-1">
                    <a
                      class="cursor-p fw-bold"
                      @click="downloadFile(contract.storage_path)"
                      ><svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                          fill="#2670b6"
                        ></path>
                      </svg>
                      {{ contract.storage_path.split("/")[1] }}</a
                    >
                  </li>
                </ul>
                <!-- approve unapprove buttons -->
                <div class="my-2">
                  <!-- approve button -->
                  <button
                    @click="approveContractModal"
                    class="btn btn-md btn-soft-success rounded-pill border border-success mx-2"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                        fill="#035A4B"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                        fill="#035A4B"
                      ></path>
                    </svg>
                    Approve
                  </button>
                  <!-- unapprove button -->
                  <button
                    @click="unApproveContractModal"
                    class="btn btn-md btn-soft-danger rounded-pill border border-danger mx-2"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                        fill="#035A4B"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                        fill="#4b132a"
                      ></path>
                    </svg>
                    Unapprove
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- for the other admin (REC, RSC) -->
        <div class="container" v-else>
          <div class="row justify-content-center">
            <div class="col-auto text-center mt-5 pt-5">
              <!-- contract is pending on the researcher and the super admin -->
              <div>
                <img src="/img/oc-error.jpg" class="w-25" alt="no researches" />
                <p class="text-muted fw-bold fs-5 pt-5 text-center">
                  the contract now is pending on the researcher to upload and
                  the admin to approve/unapprove.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- when the resports is not visible -->
      <div class="container" v-else>
        <div class="row">
          <div class="col-auto text-center mt-5 pt-5">
            <img src="/img/oc-error.jpg" class="w-25" alt="no researches" />
            <p class="text-muted fw-bold fs-5 pt-5 text-center">
              this section is not available.
            </p>
          </div>
        </div>
      </div>

      <new-report
        :researchId="research.id"
        :getResearchInfo="getResearchInfo"
        :fetchReports="fetchReports"
      />
      <upload-contract
        :researchId="research.id"
        :getResearchInfo="getResearchInfo"
      />
      <approve-contract
        :researchId="research.id"
        :getResearchInfo="getResearchInfo"
      />
      <un-approve-contract
        :researchId="research.id"
        :getResearchInfo="getResearchInfo"
      />
    </template>
  </div>
</template>

<script>
import newReport from "@/components/reports/newReport.vue";
import uploadContract from "@/components/reports/uploadContract.vue";
import approveContract from "@/components/reports/approveContract.vue";
import UnApproveContract from "@/components/reports/unApproveContract.vue";
import reportCard from "@/components/reports/reportCard.vue";
import pagination from "@/components/pagination";
import { hasRole, downloadFile } from "@/includes/helpers.js";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "reportsMain",
  props: {
    getResearchInfo: {
      required: true,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    newReport,
    uploadContract,
    approveContract,
    UnApproveContract,
    reportCard,
    pagination,
  },
  data() {
    return {
      in_submission: false,
      contract: null,
      reports: [],
      reportsLinks: [],
      totalReports: 0,
      reportsPerPage: 0,
    };
  },
  methods: {
    newReport() {
      document.getElementById("newReportBtn").click();
    },
    uploadContract() {
      document.getElementById("uploadContractBtn").click();
    },
    admins() {
      return hasRole([
        "admin",
        "REC-member",
        "RSC-member",
        "REC-admin",
        "RSC-admin",
      ]);
    },
    approveContractModal() {
      document.getElementById("approveContractBtn").click();
    },
    unApproveContractModal() {
      document.getElementById("UnApproveContractBtn").click();
    },
    async fetchReports({ page = 1 }) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      await axiosConfig
        .get(`api/fetchReports/${researchId}?page=${page}`)
        .then((res) => {
          this.reports = res.data.data;
          this.reportsLinks = res.data.links;
          this.totalReports = res.data.total;
          this.reportsPerPage = res.data.per_page;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    //saving the contract in the view if exists before
    getUploadedContract() {
      if (this.research != null) {
        const files = this.research.files;
        files.forEach((item) => {
          if (item.type.name == "contract") {
            this.contract = item;
            return;
          }
        });
      }
    },
    hasRole,
    downloadFile,
  },
  activated() {
    this.reports.length == 0 && !this.in_submission
      ? this.fetchReports({})
      : "";
    this.contract == null ? this.getUploadedContract() : "";
  },
  watch: {
    research() {
      //saving the contract in the view if exists before
      this.getUploadedContract();
      //getting the report when the research is loaded
      this.fetchReports({});
    },
  },
};
</script>

<style scoped>
.reportsList-container strong {
  color: #767676;
}
.reportsList-container ol li {
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f1f1f1;
}

.numberCircle {
  width: 39.6px;
  height: 39.6px;
  border-radius: 50%;
  text-align: center;
  font-size: 21.6px;
  border: 2px solid #7abeef;
  color: white;
  background: #3182ce;
  position: absolute;
  top: -20px;
  right: -30px;
  transform: translateX(-50%);
}
</style>
