<template>
  <div
    class="modal fade"
    id="unassignMemberModal"
    tabindex="-1"
    aria-labelledby="unassignMemberModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="unassignMemberModal">
            Unassign member confirmation
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeUnAssignedModal"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <h6>
                  Do you want to unassign this member from the current proposal
                  ?
                </h6>
                <p>{{ email }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            @click="unassignedMember(email)"
            type="button"
            class="btn btn-danger"
            v-if="!in_submission"
          >
            Confirm
          </button>
          <!-- spinners -->
          <div class="d-flex justify-content-center my-2" v-if="in_submission">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <button
            type="button"
            class="btn btn-white text-danger"
            data-bs-dismiss="modal"
          >
            Close
          </button>
        </div>
        <!-- alert message -->
        <div
          v-if="message != null && !in_submission"
          class="alert d-flex align-items-center text-white m-3 text-start"
          :class="messageClass"
          role="alert"
        >
          <div>{{ message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  props: {
    fetchAssignedMembers: {
      type: Function,
      required: true,
    },
    email: {
      required: true,
    },
  },
  name: "unassignMemberModal",
  data() {
    return {
      in_submission: false,
      message: null,
      messageClass: null,
    };
  },
  methods: {
    async unassignedMember(email) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      await axiosConfig
        .post(`api/unassignMemberToResearch/${researchId}/${email}`)
        .then((res) => {
          console.log(res);
          this.messageClass = "alert-success";
          this.message = res.data;
          this.fetchAssignedMembers();
        })
        .catch((err) => {
          console.log(err.response);
          this.messageClass = "alert-danger";
          this.message = "Error, please try again later.";
          return;
        });
      setTimeout(() => {
        document.getElementById("closeUnAssignedModal").click();
        this.message = null;
      }, 4000);
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
