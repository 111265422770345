<template>
  <div>
    <!-- Form for searching the members -->
    <div class="mb-2 mt-2">
      <label for="members" class="form-label visually-hidden"
        >Choose the Member</label
      >
      <div class="liveSearchDropDown">
        <input
          type="text"
          class="form-control"
          name="members"
          id="liveSearchInput"
          placeholder="Search for members to get their data"
          v-model="currentSearch"
          autocomplete="off"
        />
        <div
          class="liveSearchResult"
          v-if="members.length > 0 && !in_submission"
        >
          <template v-for="member in members" :key="member.id">
            <span class="m-2 p-2 cursor-p" @click="selectMember(member.id)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                  fill="#2670b6"
                ></path>
                <path
                  d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                  fill="#2670b6"
                ></path>
              </svg>
              <a class="mx-2 fw-bold">{{ member.full_name }}</a>
              <small class="text-muted d-block ms-5">{{ member.email }}</small>
              <small v-for="role in member.roles" :key="role.id">
                {{ role.name }}
              </small>
            </span>
          </template>
        </div>
        <div class="d-flex justify-content-center m-5" v-if="in_submission">
          <div class="spinner-grow text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Form -->
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "members",
  data() {
    return {
      in_submission: false,
      currentSearch: null,
      members: [],
    };
  },
  methods: {
    async searchAuthors(currentSearch) {
      this.in_submission = true;
      let search = {
        search: currentSearch,
      };
      await axiosConfig
        .post("api/searchMembers", search)
        .then((res) => {
          this.members = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    // Method to emit the selected member's ID to the parent component
    selectMember(reseacherId) {
      this.$emit("member-selected", reseacherId);
    },
  },
  watch: {
    currentSearch: async function (newVal) {
      if (newVal) {
        this.members = [];
        await this.searchAuthors(newVal);
        this.in_submission = false;
      }
    },
  },
};
</script>
