<template>
  <div class="d-none overlay" id="overlay">
    <div class="spinner-grow text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "overlar",
};
</script>

<style scoped>
.overlay {
  background-color: rgba(255, 255, 255, 0.7);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  opacity: 0.5;
}

.overlay .spinner-grow {
  position: absolute;
  left: 50%;
  top: 50%;
}
</style>
>
