<template>
  <div>
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="research == null && loadingResearch"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- research details -->
    <div v-if="research != null" class="container-fluid">
      <!-- for the research -->
      <div
        class="research-content research-item border border-1 shadow rounded"
      >
        <!-- research full date -->
        <div class="d-flex flex-column float-end">
          <h4 class="research-title text-center">Submitted At</h4>
          <div class="research-date float-end">
            <!-- format the research dates -->
            {{ this.splitResearchFullDate(research.created_at) }}
            <span class="date">{{ researchDate }}</span
            ><span class="hour">{{ researchHour }}</span>
          </div>
        </div>
        <!-- research title -->
        <h4 class="research-title">
          {{ research.title }}
          <!-- edit the research button -->
          <a
            @click="
              $router.push({
                name: 'register-research',
                params: { id: research.id },
              })
            "
            v-if="isEditable()"
            class="btn btn-sm btn-soft-primary rounded-pill border border-primary mx-2 fw-bold rounded-pill"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="edit the research"
          >
            <svg
              class="me-1"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303V8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                fill="#3182ce"
              ></path>
              <path
                d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75953 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                fill="#2670b6"
              ></path>
            </svg>
            edit</a
          >
          <!-- suspend the research button -->
          <a
            @click="ToggleSuspend()"
            v-if="hasRole(['admin'])"
            class="btn btn-sm btn-soft-warning rounded-pill border border-warning mx-2 fw-bold rounded-pill"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                fill="#ffa84f"
              ></path>
              <path
                d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                fill="#ffa84f"
              ></path>
            </svg>
            {{
              research.status.name == "Suspended"
                ? "UnSuspend the proposal"
                : "Suspend the proposal"
            }}</a
          >
          <!-- close the research button -->
          <a
            @click="closeResearch()"
            v-if="
              hasRole(['admin']) &&
              research.status.name == 'Under reporting period'
            "
            class="btn btn-sm btn-soft-success rounded-pill border border-success mx-2 fw-bold rounded-pill"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M6 22H4V3C4 2.4 4.4 2 5 2C5.6 2 6 2.4 6 3V22Z"
                fill="#035A4B"
              ></path>
              <path
                d="M18 14H4V4H18C18.8 4 19.2 4.9 18.7 5.5L16 9L18.8 12.5C19.3 13.1 18.8 14 18 14Z"
                fill="#035A4B"
              ></path>
            </svg>
            Close the research
          </a>
        </h4>
        <!-- research meta data -->
        <div class="meta-info-research">
          <!-- authors number -->
          <span
            ><svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 14H18V10H20C20.6 10 21 10.4 21 11V13C21 13.6 20.6 14 20 14ZM21 19V17C21 16.4 20.6 16 20 16H18V20H20C20.6 20 21 19.6 21 19ZM21 7V5C21 4.4 20.6 4 20 4H18V8H20C20.6 8 21 7.6 21 7Z"
                fill="#3182ce"
              ></path>
              <path
                opacity="0.3"
                d="M17 22H3C2.4 22 2 21.6 2 21V3C2 2.4 2.4 2 3 2H17C17.6 2 18 2.4 18 3V21C18 21.6 17.6 22 17 22ZM10 7C8.9 7 8 7.9 8 9C8 10.1 8.9 11 10 11C11.1 11 12 10.1 12 9C12 7.9 11.1 7 10 7ZM13.3 16C14 16 14.5 15.3 14.3 14.7C13.7 13.2 12 12 10.1 12C8.10001 12 6.49999 13.1 5.89999 14.7C5.59999 15.3 6.19999 16 7.39999 16H13.3Z"
                fill="#2670b6"
              ></path>
            </svg>
            <a>{{ research.authors.length }} Authors</a></span
          >
          <!-- categories name -->
          <span
            ><svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205V3.47205Z"
                fill="#3182ce"
              ></path>
              <path
                d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804V9.86804Z"
                fill="#2670b6"
              ></path>
            </svg>
            <a>{{ research.category.name }}</a></span
          >
          <!-- fund -->
          <span
            ><svg
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 19.725V18.725C20 18.125 19.6 17.725 19 17.725H5C4.4 17.725 4 18.125 4 18.725V19.725H3C2.4 19.725 2 20.125 2 20.725V21.725H22V20.725C22 20.125 21.6 19.725 21 19.725H20Z"
                fill="#3182ce"
              ></path>
              <path
                opacity="0.3"
                d="M22 6.725V7.725C22 8.325 21.6 8.725 21 8.725H18C18.6 8.725 19 9.125 19 9.725C19 10.325 18.6 10.725 18 10.725V15.725C18.6 15.725 19 16.125 19 16.725V17.725H15V16.725C15 16.125 15.4 15.725 16 15.725V10.725C15.4 10.725 15 10.325 15 9.725C15 9.125 15.4 8.725 16 8.725H13C13.6 8.725 14 9.125 14 9.725C14 10.325 13.6 10.725 13 10.725V15.725C13.6 15.725 14 16.125 14 16.725V17.725H10V16.725C10 16.125 10.4 15.725 11 15.725V10.725C10.4 10.725 10 10.325 10 9.725C10 9.125 10.4 8.725 11 8.725H8C8.6 8.725 9 9.125 9 9.725C9 10.325 8.6 10.725 8 10.725V15.725C8.6 15.725 9 16.125 9 16.725V17.725H5V16.725C5 16.125 5.4 15.725 6 15.725V10.725C5.4 10.725 5 10.325 5 9.725C5 9.125 5.4 8.725 6 8.725H3C2.4 8.725 2 8.325 2 7.725V6.725L11 2.225C11.6 1.925 12.4 1.925 13.1 2.225L22 6.725ZM12 3.725C11.2 3.725 10.5 4.425 10.5 5.225C10.5 6.025 11.2 6.725 12 6.725C12.8 6.725 13.5 6.025 13.5 5.225C13.5 4.425 12.8 3.725 12 3.725Z"
                fill="#2670b6"
              ></path>
            </svg>
            <a v-if="research.withFund == 1">with fund</a>
            <a v-else>with out fund</a>
          </span>
          <!-- graduate project -->
          <span v-if="research.graduate_project == 1">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M20 15H4C2.9 15 2 14.1 2 13V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V13C22 14.1 21.1 15 20 15ZM13 12H11C10.5 12 10 12.4 10 13V16C10 16.5 10.4 17 11 17H13C13.6 17 14 16.6 14 16V13C14 12.4 13.6 12 13 12Z"
                fill="#3182ce"
              ></path>
              <path
                d="M14 6V5H10V6H8V5C8 3.9 8.9 3 10 3H14C15.1 3 16 3.9 16 5V6H14ZM20 15H14V16C14 16.6 13.5 17 13 17H11C10.5 17 10 16.6 10 16V15H4C3.6 15 3.3 14.9 3 14.7V18C3 19.1 3.9 20 5 20H19C20.1 20 21 19.1 21 18V14.7C20.7 14.9 20.4 15 20 15Z"
                fill="#2670b6"
              ></path>
            </svg>
            <a>graduate project</a>
          </span>
        </div>
        <div class="research-desc mt-5">
          <!-- aditional info -->
          <p class="text-dark">
            {{
              research.info ||
              "There's no additional information about this research."
            }}
          </p>
          <!-- research other info -->
          <div>
            <p class="d-block text-dark">
              <b>- Limu id:</b> {{ research.limu_id }}
            </p>
            <p class="d-block text-dark">
              <b>- Type of study:</b> {{ research.study.name }}
            </p>
            <p class="d-block text-dark">
              <b>- Faculty:</b> {{ research.faculty.name }}
            </p>
            <p class="d-block text-dark">
              <b>- Category:</b> {{ research.category.name }}
            </p>
          </div>
          <div class="d-flex flex-row justify-content-between mb-2 pt-0 pb-0">
            <!-- research status -->
            <p class="d-block text-dark">
              <b>- Research status:</b>
              <span
                class="badge m-2 fs-8 align-self-center fw-bold border shadow"
                :class="{
                  'bg-soft-primary text-primary':
                    research.status.name != 'Closed' &&
                    research.status.name != 'Rejected' &&
                    research.status.name != 'Suspended',
                  'bg-soft-danger text-danger':
                    research.status.name == 'Rejected',
                  'bg-soft-success text-success':
                    research.status.name == 'Closed',
                  'bg-soft-warning text-warning':
                    research.status.name == 'Suspended',
                }"
                >{{ research.status.name }}</span
              >
            </p>
            <!-- underReviw button -->
            <button
              @click="underReviewMSG"
              type="button"
              class="btn btn-sm btn-soft-primary rounded-pill border border-primary mx-2 fw-bold rounded-pill h-50"
              v-if="
                hasRole(['REC-admin']) &&
                this.research.accepted_by_REC == 0 &&
                (research.status.name === 'Registered' ||
                  research.status.name === 'Under review')
              "
            >
              <!-- change to under review icon -->
              <svg
                v-if="research.status.name === 'Registered'"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.6 7C12 7 11.6 6.6 11.6 6V3C11.6 2.4 12 2 12.6 2C13.2 2 13.6 2.4 13.6 3V6C13.6 6.6 13.2 7 12.6 7ZM10 7.59998C10.5 7.29998 10.6 6.69995 10.4 6.19995L9 3.80005C8.7 3.30005 8.10001 3.20002 7.60001 3.40002C7.10001 3.70002 7.00001 4.30005 7.20001 4.80005L8.60001 7.19995C8.80001 7.49995 9.1 7.69995 9.5 7.69995C9.7 7.69995 9.9 7.69998 10 7.59998ZM8 9.30005C8.3 8.80005 8.10001 8.20002 7.60001 7.90002L5.5 6.69995C5 6.39995 4.40001 6.59998 4.10001 7.09998C3.80001 7.59998 4 8.2 4.5 8.5L6.60001 9.69995C6.80001 9.79995 6.90001 9.80005 7.10001 9.80005C7.50001 9.80005 7.9 9.70005 8 9.30005ZM7.20001 12C7.20001 11.4 6.80001 11 6.20001 11H4C3.4 11 3 11.4 3 12C3 12.6 3.4 13 4 13H6.20001C6.70001 13 7.20001 12.6 7.20001 12Z"
                  fill="#2670b6"
                ></path>
                <path
                  opacity="0.3"
                  d="M17.4 5.5C17.4 6.1 17 6.5 16.4 6.5C15.8 6.5 15.4 6.1 15.4 5.5C15.4 4.9 15.8 4.5 16.4 4.5C17 4.5 17.4 5 17.4 5.5ZM5.80001 17.1L7.40001 16.1C7.90001 15.8 8.00001 15.2 7.80001 14.7C7.50001 14.2 6.90001 14.1 6.40001 14.3L4.80001 15.3C4.30001 15.6 4.20001 16.2 4.40001 16.7C4.60001 17 4.90001 17.2 5.30001 17.2C5.50001 17.3 5.60001 17.2 5.80001 17.1ZM8.40001 20.2C8.20001 20.2 8.10001 20.2 7.90001 20.1C7.40001 19.8 7.3 19.2 7.5 18.7L8.30001 17.3C8.60001 16.8 9.20002 16.7 9.70002 16.9C10.2 17.2 10.3 17.8 10.1 18.3L9.30001 19.7C9.10001 20 8.70001 20.2 8.40001 20.2ZM12.6 21.2C12 21.2 11.6 20.8 11.6 20.2V18.8C11.6 18.2 12 17.8 12.6 17.8C13.2 17.8 13.6 18.2 13.6 18.8V20.2C13.6 20.7 13.2 21.2 12.6 21.2ZM16.7 19.9C16.4 19.9 16 19.7 15.8 19.4L15.2 18.5C14.9 18 15.1 17.4 15.6 17.1C16.1 16.8 16.7 17 17 17.5L17.6 18.4C17.9 18.9 17.7 19.5 17.2 19.8C17 19.9 16.8 19.9 16.7 19.9ZM19.4 17C19.2 17 19.1 17 18.9 16.9L18.2 16.5C17.7 16.2 17.6 15.6 17.8 15.1C18.1 14.6 18.7 14.5 19.2 14.7L19.9 15.1C20.4 15.4 20.5 16 20.3 16.5C20.1 16.8 19.8 17 19.4 17ZM20.4 13H19.9C19.3 13 18.9 12.6 18.9 12C18.9 11.4 19.3 11 19.9 11H20.4C21 11 21.4 11.4 21.4 12C21.4 12.6 20.9 13 20.4 13ZM18.9 9.30005C18.6 9.30005 18.2 9.10005 18 8.80005C17.7 8.30005 17.9 7.70002 18.4 7.40002L18.6 7.30005C19.1 7.00005 19.7 7.19995 20 7.69995C20.3 8.19995 20.1 8.79998 19.6 9.09998L19.4 9.19995C19.3 9.19995 19.1 9.30005 18.9 9.30005Z"
                  fill="#035A4B"
                ></path>
              </svg>
              <!-- change to registered icon -->
              <svg
                v-if="research.status.name === 'Under review'"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.4 13.9411L10.7 5.24112C10.4 4.94112 10 4.84104 9.60001 5.04104C9.20001 5.24104 9 5.54107 9 5.94107V18.2411C9 18.6411 9.20001 18.941 9.60001 19.141C9.70001 19.241 9.9 19.2411 10 19.2411C10.2 19.2411 10.4 19.141 10.6 19.041C11.4 18.441 12.1 17.941 12.9 17.541L14.4 21.041C14.6 21.641 15.2 21.9411 15.8 21.9411C16 21.9411 16.2 21.9411 16.4 21.8411C17.2 21.5411 17.5 20.6411 17.2 19.8411L15.7 16.2411C16.7 15.9411 17.7 15.741 18.8 15.541C19.2 15.541 19.5 15.2411 19.6 14.8411C19.8 14.6411 19.7 14.2411 19.4 13.9411Z"
                  fill="#2670b6"
                ></path>
                <path
                  opacity="0.3"
                  d="M15 6.941C14.8 6.941 14.7 6.94102 14.6 6.84102C14.1 6.64102 13.9 6.04097 14.2 5.54097L15.2 3.54097C15.4 3.04097 16 2.84095 16.5 3.14095C17 3.34095 17.2 3.941 16.9 4.441L15.9 6.441C15.7 6.741 15.4 6.941 15 6.941ZM18.4 9.84102L20.4 8.84102C20.9 8.64102 21.1 8.04097 20.8 7.54097C20.6 7.04097 20 6.84095 19.5 7.14095L17.5 8.14095C17 8.34095 16.8 8.941 17.1 9.441C17.3 9.841 17.6 10.041 18 10.041C18.2 9.94097 18.3 9.94102 18.4 9.84102ZM7.10001 10.941C7.10001 10.341 6.70001 9.941 6.10001 9.941H4C3.4 9.941 3 10.341 3 10.941C3 11.541 3.4 11.941 4 11.941H6.10001C6.70001 11.941 7.10001 11.541 7.10001 10.941ZM4.89999 17.1409L6.89999 16.1409C7.39999 15.9409 7.59999 15.341 7.29999 14.841C7.09999 14.341 6.5 14.141 6 14.441L4 15.441C3.5 15.641 3.30001 16.241 3.60001 16.741C3.80001 17.141 4.1 17.341 4.5 17.341C4.6 17.241 4.79999 17.2409 4.89999 17.1409Z"
                  fill="#ffff"
                ></path>
              </svg>
              <span v-if="research.status.name === 'Registered'"
                >Under Review</span
              >
              <span v-else>Registered</span>
              <i class="bi-basket ms-1"></i>
            </button>
          </div>
          <blockquote class="default">
            <!-- research attached files -->
            <div class="my-2">
              <b class="text-secondary">- Attached Files:</b>
              <ul>
                <li v-for="file in research.files" :key="file.id" class="pb-1">
                  <a
                    class="cursor-p fw-bold"
                    @click="downloadFile(file.storage_path)"
                    ><svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M4.425 20.525C2.525 18.625 2.525 15.525 4.425 13.525L14.825 3.125C16.325 1.625 18.825 1.625 20.425 3.125C20.825 3.525 20.825 4.12502 20.425 4.52502C20.025 4.92502 19.425 4.92502 19.025 4.52502C18.225 3.72502 17.025 3.72502 16.225 4.52502L5.82499 14.925C4.62499 16.125 4.62499 17.925 5.82499 19.125C7.02499 20.325 8.82501 20.325 10.025 19.125L18.425 10.725C18.825 10.325 19.425 10.325 19.825 10.725C20.225 11.125 20.225 11.725 19.825 12.125L11.425 20.525C9.525 22.425 6.425 22.425 4.425 20.525Z"
                        fill="#3182ce"
                      ></path>
                      <path
                        d="M9.32499 15.625C8.12499 14.425 8.12499 12.625 9.32499 11.425L14.225 6.52498C14.625 6.12498 15.225 6.12498 15.625 6.52498C16.025 6.92498 16.025 7.525 15.625 7.925L10.725 12.8249C10.325 13.2249 10.325 13.8249 10.725 14.2249C11.125 14.6249 11.725 14.6249 12.125 14.2249L19.125 7.22493C19.525 6.82493 19.725 6.425 19.725 5.925C19.725 5.325 19.525 4.825 19.125 4.425C18.725 4.025 18.725 3.42498 19.125 3.02498C19.525 2.62498 20.125 2.62498 20.525 3.02498C21.325 3.82498 21.725 4.825 21.725 5.925C21.725 6.925 21.325 7.82498 20.525 8.52498L13.525 15.525C12.325 16.725 10.525 16.725 9.32499 15.625Z"
                        fill="#2670b6"
                      ></path>
                    </svg>
                    {{ file.storage_path.split("/")[1] }}</a
                  >
                </li>
              </ul>
            </div>
          </blockquote>
          <div class="row my-5">
            <!-- research authors list -->
            <div class="col">
              <div class="widget-authors">
                <h3 class="widget-title">Authors</h3>
                <p class="text-muted" v-if="research.authors.length == 0">
                  there's no other authors in this research.
                </p>
                <ul v-if="research.authors.length > 0">
                  <li v-for="author in research.authors" :key="author.id">
                    <span class="me-1 p-1 cursor-p"
                      ><svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                          fill="#2670b6"
                        ></path>
                        <path
                          d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                          fill="#2670b6"
                        ></path>
                      </svg>
                      <a class="mx-1 fw-bold">{{ author.name }}</a>
                      <small
                        class="fw-bold"
                        v-if="
                          research.creator.email.toLowerCase() ==
                          author.email.toLowerCase()
                        "
                        >- (main author)</small
                      >
                      <a class="ms-5 text-muted d-block">{{
                        author.email
                      }}</a></span
                    >
                  </li>
                </ul>
              </div>
            </div>
            <div class="col">
              <!-- keyword -->
              <div class="widget-keywords">
                <h3 class="widget-title">Research keywords</h3>
                <p class="text-muted" v-if="research.keywords.length == 0">
                  there's no keywords.
                </p>
                <ul class="keywords" v-if="research.keywords.length > 0">
                  <li
                    v-for="keyword in splitKeyWords(research.keywords)"
                    :key="keyword"
                  >
                    <a
                      ><b>{{ keyword }}</b></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- for the author/researcher -->
      <div class="research-author border border-1 shadow rounded">
        <div class="author-bio">
          <h3 class="author_name">{{ research.creator.full_name }}</h3>
          <div class="d-flex flex-row">
            <h5 class="px-2">
              <b>Role </b>{{ research.creator.category.name }}
            </h5>
            <h5><b>Faculty </b>{{ research.creator.faculty.name }}</h5>
          </div>
          <p class="author_det text-dark my-2">
            {{ research.creator.bio }}
          </p>
          <div class="d-flex flex-column float-start author-footer">
            <p class="text-muted">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z"
                  fill="#3182ce"
                ></path>
                <path
                  d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z"
                  fill="#2670b6"
                ></path>
              </svg>
              {{ research.creator.email }}
            </p>
            <p class="text-muted">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z"
                  fill="#2670b6"
                ></path>
                <path
                  opacity="0.3"
                  d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z"
                  fill="#3182ce"
                ></path>
                <path
                  d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z"
                  fill="#2670b6"
                ></path>
              </svg>
              {{ research.creator.phone || "not available" }}
            </p>
          </div>
        </div>
        <div class="author-desc">
          <img
            :src="
              research.creator.profile_img_path ||
              '/img/default_profile_image.jpg'
            "
            alt="researcher image"
            class="step-avatar-img"
          />
        </div>
      </div>

      <!-- for researcher only to download the certificate once it's ethically approved -->
      <div
        class="container text-center my-5 research-options-button"
        v-if="hasRole(['researcher', 'REC-admin']) && research != null"
      >
        <!-- download -->
        <button
          @click="certificateModal()"
          class="btn btn-md btn-soft-success rounded-pill border-success mx-2"
          v-if="research.accepted_by_REC == 1"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.85714 1H11.7364C12.0911 1 12.4343 1.12568 12.7051 1.35474L17.4687 5.38394C17.8057 5.66895 18 6.08788 18 6.5292V19.0833C18 20.8739 17.9796 21 16.1429 21H4.85714C3.02045 21 3 20.8739 3 19.0833V2.91667C3 1.12612 3.02045 1 4.85714 1ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
              fill="#035A4B"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.85714 3H14.7364C15.0911 3 15.4343 3.12568 15.7051 3.35474L20.4687 7.38394C20.8057 7.66895 21 8.08788 21 8.5292V21.0833C21 22.8739 20.9796 23 19.1429 23H6.85714C5.02045 23 5 22.8739 5 21.0833V4.91667C5 3.12612 5.02045 3 6.85714 3ZM7 13C7 12.4477 7.44772 12 8 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H8C7.44772 14 7 13.5523 7 13ZM8 16C7.44772 16 7 16.4477 7 17C7 17.5523 7.44772 18 8 18H11C11.5523 18 12 17.5523 12 17C12 16.4477 11.5523 16 11 16H8Z"
              fill="#035A4B"
            ></path>
          </svg>
          <span class="ps-2">Download ethical approved certificate</span>
        </button>
        <ethicalClearanceCertificateModal>
          <template #body>
            <ethical-clearance-certificate :research="research" />
          </template>
        </ethicalClearanceCertificateModal>
      </div>

      <!-- for admins only (Accept, modifications, reject) -->
      <div
        class="container text-center my-5 research-options-button"
        v-if="hasRole(['REC-admin', 'RSC-admin']) && research != null"
      >
        <!-- accept -->
        <button
          class="btn btn-md btn-soft-success rounded-pill border-success mx-2"
          @click="acceptTheResearch(research)"
          :disabled="adminsActionFilter"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M4.875 20.75C4.63542 20.75 4.39583 20.6542 4.20417 20.4625L2.2875 18.5458C1.90417 18.1625 1.90417 17.5875 2.2875 17.2042C2.67083 16.8208 3.29375 16.8208 3.62917 17.2042L4.875 18.45L8.0375 15.2875C8.42083 14.9042 8.99583 14.9042 9.37917 15.2875C9.7625 15.6708 9.7625 16.2458 9.37917 16.6292L5.54583 20.4625C5.35417 20.6542 5.11458 20.75 4.875 20.75Z"
              fill="#035A4B"
            ></path>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5 3C3.34315 3 2 4.34315 2 6V11.865C3.04486 11.3127 4.23591 11 5.5 11C9.47412 11 12.7263 14.091 12.9836 18H18.0249L20.3178 20.2929C20.9478 20.9229 22.0249 20.4767 22.0249 19.5858V15L22 15.0098L22 15V6C22 4.34315 20.6569 3 19 3H5ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44771 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6Z"
              fill="#035A4B"
            ></path>
          </svg>
          <span class="ps-2">Accept the proposal</span>
        </button>
        <!-- accept with modifications -->
        <button
          class="btn btn-md btn-soft-primary rounded-pill border-primary mx-2"
          @click="modificationProposalModal"
          :disabled="adminsActionFilter"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.3"
              d="M8 8C8 7.4 8.4 7 9 7H16V3C16 2.4 15.6 2 15 2H3C2.4 2 2 2.4 2 3V13C2 13.6 2.4 14 3 14H5V16.1C5 16.8 5.79999 17.1 6.29999 16.6L8 14.9V8Z"
              fill="#3182ce"
            ></path>
            <path
              d="M22 8V18C22 18.6 21.6 19 21 19H19V21.1C19 21.8 18.2 22.1 17.7 21.6L15 18.9H9C8.4 18.9 8 18.5 8 17.9V7.90002C8 7.30002 8.4 6.90002 9 6.90002H21C21.6 7.00002 22 7.4 22 8ZM19 11C19 10.4 18.6 10 18 10H12C11.4 10 11 10.4 11 11C11 11.6 11.4 12 12 12H18C18.6 12 19 11.6 19 11ZM17 15C17 14.4 16.6 14 16 14H12C11.4 14 11 14.4 11 15C11 15.6 11.4 16 12 16H16C16.6 16 17 15.6 17 15Z"
              fill="#2670b6"
            ></path>
          </svg>
          <span class="ps-2"> Accept the proposal with modifications </span>
        </button>
        <!-- reject -->
        <button
          class="btn btn-md btn-soft-danger rounded-pill border-danger mx-2"
          @click="rejectTheProposalModal"
          :disabled="adminsActionFilter"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.2166 8.50002L10.5166 7.80007C10.1166 7.40007 10.1166 6.80005 10.5166 6.40005L13.4166 3.50002C15.5166 1.40002 18.9166 1.50005 20.8166 3.90005C22.5166 5.90005 22.2166 8.90007 20.3166 10.8001L17.5166 13.6C17.1166 14 16.5166 14 16.1166 13.6L15.4166 12.9C15.0166 12.5 15.0166 11.9 15.4166 11.5L18.3166 8.6C19.2166 7.7 19.1166 6.30002 18.0166 5.50002C17.2166 4.90002 16.0166 5.10007 15.3166 5.80007L12.4166 8.69997C12.2166 8.89997 11.6166 8.90002 11.2166 8.50002ZM11.2166 15.6L8.51659 18.3001C7.81659 19.0001 6.71658 19.2 5.81658 18.6C4.81658 17.9 4.71659 16.4 5.51659 15.5L8.31658 12.7C8.71658 12.3 8.71658 11.7001 8.31658 11.3001L7.6166 10.6C7.2166 10.2 6.6166 10.2 6.2166 10.6L3.6166 13.2C1.7166 15.1 1.4166 18.1 3.1166 20.1C5.0166 22.4 8.51659 22.5 10.5166 20.5L13.3166 17.7C13.7166 17.3 13.7166 16.7001 13.3166 16.3001L12.6166 15.6C12.3166 15.2 11.6166 15.2 11.2166 15.6Z"
              fill="#4b0000"
            ></path>
            <path
              opacity="0.3"
              d="M5.0166 9L2.81659 8.40002C2.31659 8.30002 2.0166 7.79995 2.1166 7.19995L2.31659 5.90002C2.41659 5.20002 3.21659 4.89995 3.81659 5.19995L6.0166 6.40002C6.4166 6.60002 6.6166 7.09998 6.5166 7.59998L6.31659 8.30005C6.11659 8.80005 5.5166 9.1 5.0166 9ZM8.41659 5.69995H8.6166C9.1166 5.69995 9.5166 5.30005 9.5166 4.80005L9.6166 3.09998C9.6166 2.49998 9.2166 2 8.5166 2H7.81659C7.21659 2 6.71659 2.59995 6.91659 3.19995L7.31659 4.90002C7.41659 5.40002 7.91659 5.69995 8.41659 5.69995ZM14.6166 18.2L15.1166 21.3C15.2166 21.8 15.7166 22.2 16.2166 22L17.6166 21.6C18.1166 21.4 18.4166 20.8 18.1166 20.3L16.7166 17.5C16.5166 17.1 16.1166 16.9 15.7166 17L15.2166 17.1C14.8166 17.3 14.5166 17.7 14.6166 18.2ZM18.4166 16.3L19.8166 17.2C20.2166 17.5 20.8166 17.3 21.0166 16.8L21.3166 15.9C21.5166 15.4 21.1166 14.8 20.5166 14.8H18.8166C18.0166 14.8 17.7166 15.9 18.4166 16.3Z"
              fill="#ffff"
            ></path>
          </svg>
          <span class="ps-2">Reject the proposal</span>
        </button>
      </div>
    </div>

    <!-- REC acceptation modals-->
    <recAccepationModal
      v-if="research != null && hasRole(['REC-admin'])"
      :research="research"
      :getResearchInfo="getResearchInfo"
    />

    <!-- RSC acceptation modals -->
    <rscAcceptationModal
      v-if="research != null && hasRole(['RSC-admin'])"
      :research="research"
      :getResearchInfo="getResearchInfo"
    />

    <!-- accept the proposal with modification modal -->
    <modificationProposalModal
      v-if="research != null && hasRole(['RSC-admin', 'REC-admin'])"
      :research="research"
      :getResearchInfo="getResearchInfo"
    />

    <!-- Reject the proposal modal -->
    <rejectProposalModal
      v-if="research != null && hasRole(['RSC-admin', 'REC-admin'])"
      :research="research"
      :getResearchInfo="getResearchInfo"
    />

    <!-- underReview modal -->
    <underReviewLabel
      v-if="research != null && hasRole(['REC-admin'])"
      :researchStatusName="research.status.name"
      :changeStatusToUnderReview="changeStatusToUnderReview"
    />

    <!-- suspend unsuspend modal -->
    <toggle-suspend
      v-if="research != null && hasRole(['admin'])"
      :researchId="research.id"
      :researchStatus="research.status"
      :getResearchInfo="getResearchInfo"
    />

    <!-- close research modal -->
    <template v-if="research != null && hasRole(['admin'])">
      <close-research
        v-if="research.status.name == 'Under reporting period'"
        :researchId="research.id"
        :getResearchInfo="getResearchInfo"
      />
    </template>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import { hasRole, downloadFile } from "@/includes/helpers.js";
import { mapGetters } from "vuex";
import store from "@/store";
import recAccepationModal from "@/components/research/recAcceptationModal.vue";
import rscAcceptationModal from "@/components/research/rscAcceptationModal.vue";
import rejectProposalModal from "@/components/research/rejectProposalModal.vue";
import modificationProposalModal from "@/components/research/modificationProposalModal";
import underReviewLabel from "@/components/research/underReviewModal.vue";
import ToggleSuspend from "@/components/statusModels/toggleSuspend.vue";
import closeResearch from "@/components/statusModels/closeResearch.vue";
import ethicalClearanceCertificate from "@/components/research/ethicalClearanceCertificate.vue";
import ethicalClearanceCertificateModal from "@/components/research/ethicalClearanceCertificateModal.vue";

export default {
  name: "researchDetails",
  components: {
    recAccepationModal,
    underReviewLabel,
    rscAcceptationModal,
    rejectProposalModal,
    modificationProposalModal,
    ToggleSuspend,
    closeResearch,
    ethicalClearanceCertificate,
    ethicalClearanceCertificateModal,
  },
  props: {
    getResearchInfo: {
      required: true,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      researchDate: null,
      researchHour: null,
      in_submission: false,
      RSCError: null,
    };
  },
  computed: {
    adminsActionFilter() {
      return (
        (hasRole(["REC-admin"]) && this.research.accepted_by_REC == 1) ||
        (hasRole(["RSC-admin"]) && this.research.accepted_by_RSC == 1) ||
        (hasRole(["RSC-admin"]) && this.research.accepted_by_REC == 0) ||
        this.research.status.name === "Closed" ||
        this.research.status.name === "Rejected"
      );
    },
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    splitResearchFullDate(date) {
      let fullDate = date.split(" ");
      this.researchDate = fullDate[0];
      this.researchHour = fullDate[1] + " " + fullDate[2];
    },
    splitKeyWords(string) {
      return string.split(",");
    },
    acceptTheResearch(research) {
      if (
        !research.accepted_by_REC &&
        hasRole(["REC-admin"]) &&
        !research.accepted_by_RSC
      ) {
        console.log("YUP REC");
        document.getElementById("recAcceptationModalBtn").click();
      } else if (
        !research.accepted_by_RSC &&
        hasRole(["RSC-admin"]) &&
        research.accepted_by_REC
      ) {
        document.getElementById("rscAcceptationModalBtn").click();
        console.log("YUP RSC");
      }
    },
    underReviewMSG() {
      document.getElementById("underReviewBTN").click();
    },
    async changeStatusToUnderReview(
      researchStatusName = this.research.status.name
    ) {
      // to change the status to underReview when enter
      if (hasRole(["REC-admin"])) {
        await axiosConfig
          .get(
            `api/changeResearchStatus/${this.research.id}/${researchStatusName}`
          )
          .then((res) => {
            console.log(res);
            this.getResearchInfo();
          })
          .catch((err) => {
            console.log(err.response);
            return;
          });
      }
    },
    rejectTheProposalModal() {
      document.getElementById("rejectProposalModelBtn").click();
    },
    modificationProposalModal() {
      document.getElementById("modificationProposalBtn").click();
    },
    ToggleSuspend() {
      document.getElementById("suspendModalButton").click();
    },
    closeResearch() {
      document.getElementById("closeModalButton").click();
    },
    certificateModal() {
      document.getElementById("certificateModalButton").click();
    },
    isEditable() {
      return (
        this.research.creator.id == this.user.id &&
        (this.research.status.name == "Registered" ||
          this.research.status.name == "With modifications") &&
        hasRole(["researcher"])
      );
    },
    hasRole,
    downloadFile,
  },
  watch: {
    async research(newVal, oldVal) {
      if (oldVal == null && newVal != null) {
        this.research.status.name == "Registered"
          ? await this.changeStatusToUnderReview("Under review")
          : "";
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>
