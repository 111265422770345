<template>
  <!-- sign up Modal -->
  <div
    id="signUpModal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="signUpModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header mb-4 border-0">
          <h4 class="modal-title fs-3">Sign up</h4>
          <button
            type="button"
            class="btn-close float-end m-0"
            data-bs-dismiss="modal"
            id="closeSignUpModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body px-4">
          <!-- Content -->
          <!-- Card -->
          <div class="card border-0">
            <!-- Card Body -->
            <div class="bg-white card-body p-0">
              <!-- password instructions -->
              <div
                class="alert alert-danger"
                role="alert"
                v-if="passwordInstructions != null"
              >
                <h6 class="alert-heading fw-bold">Attention weak password !</h6>
                <p>The password must contain:</p>
                <p class="text-light" v-html="passwordInstructions"></p>
              </div>
              <!-- alert error message -->
              <div
                v-if="errors != null && !in_Submission"
                class="alert alert-danger d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ errors }}</div>
              </div>
              <!-- alert success message -->
              <div
                v-if="success != null && !in_Submission"
                class="alert alert-success d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ success }}</div>
              </div>
              <vee-form :validationSchema="registerSchema" @submit="register">
                <!-- Form for full name and ORCID-->
                <div class="d-flex flex-row">
                  <div class="mb-2 mx-1 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label text-muted" for="name"
                        >Your name <i class="text-danger fw-bold">*</i></label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        type="text"
                        class="form-control p-3"
                        name="fullName"
                        id="fullName"
                        placeholder="Your Full name"
                        required
                      />
                    </div>

                    <error-message name="fullName" class="text-danger" />
                  </div>
                  <div class="mb-2 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label text-muted" for="name"
                        >Your ORCID code</label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        type="text"
                        class="form-control p-3"
                        name="ORCID"
                        id="ORCID"
                        placeholder="Your ORCID code"
                      />
                    </div>
                    <a href="https://orcid.org/" class="fs-8 m-0 p-0"
                      >I don't have ORCID code.</a
                    >
                    <error-message name="ORCID" class="text-danger" />
                  </div>
                </div>
                <!-- End Form -->

                <!-- Form for the email -->
                <div class="mb-4">
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="form-label text-muted" for="email"
                      >Your email <i class="text-danger fw-bold">*</i></label
                    >
                  </div>

                  <div class="input-group input-group-merge">
                    <vee-field
                      type="email"
                      class="form-control p-3"
                      name="email"
                      id="email"
                      placeholder="email@limu.edu.ly"
                      aria-label="email@limu.edu.ly"
                    />
                  </div>
                  <error-message name="email" class="text-danger" />
                </div>
                <!-- End Form -->

                <!-- Form for the faculty and the user category -->
                <div class="d-flex flex-row">
                  <div class="mb-4 mx-1 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label text-muted" for="faculty"
                        >Your program is
                        <i class="text-danger fw-bold">*</i></label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        as="select"
                        name="faculty"
                        id="faculty"
                        class="form-control p-3"
                        :disabled="in_Submission && faculties.length == 0"
                      >
                        <option value="" selected disabled>
                          choose your faculty
                        </option>
                        <option
                          v-show="!faculty.disabled"
                          v-for="faculty in faculties"
                          :key="faculty.id"
                          :value="faculty.id"
                        >
                          {{ faculty.name }}
                        </option>
                      </vee-field>
                    </div>
                    <error-message name="faculty" class="text-danger" />
                  </div>

                  <div class="mb-4 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label text-muted" for="category"
                        >You are <i class="text-danger fw-bold">*</i></label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        as="select"
                        name="category"
                        id="category"
                        class="form-control p-3"
                        :disabled="in_Submission && usersCategories.length == 0"
                      >
                        <option value="" selected disabled>
                          choose your category in limu
                        </option>
                        <option
                          v-for="category in usersCategories"
                          :key="category.id"
                          :value="category.id"
                        >
                          {{ category.name }}
                        </option>
                      </vee-field>
                    </div>
                    <error-message name="category" class="text-danger" />
                  </div>
                </div>
                <!-- End Form -->

                <!-- Form for the password and the confirmation of that password-->
                <div class="d-flex flex-row">
                  <div class="mb-4 mx-1 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label class="form-label text-muted" for="password"
                        >Password <i class="text-danger fw-bold">*</i></label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        type="password"
                        class="form-control p-3"
                        name="password"
                        id="password"
                        placeholder="8+ characters"
                      />
                    </div>
                    <error-message name="password" class="text-danger" />
                  </div>

                  <div class="mb-4 w-50">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <label
                        class="form-label text-muted"
                        for="loginFormPassword"
                        >Confirm password
                        <i class="text-danger fw-bold">*</i></label
                      >
                    </div>

                    <div class="input-group input-group-merge">
                      <vee-field
                        type="password"
                        class="form-control p-3"
                        name="password_confirmation"
                        id="password_confirmation"
                        placeholder="confirm your password"
                      />
                    </div>
                    <error-message
                      name="password_confirmation"
                      class="text-danger"
                    />
                  </div>
                </div>
                <!-- End Form -->

                <!-- buttons -->
                <div class="d-grid mb-4" v-if="!in_Submission">
                  <button
                    type="submit"
                    class="btn btn-primary btn-lg"
                    :disabled="in_Submission"
                  >
                    sign up
                  </button>
                  <button
                    type="reset"
                    class="d-none"
                    id="resetRegisterForm"
                  ></button>
                  <span class="divider-center my-2">OR</span>
                  <a
                    class="btn btn-white btn-lg border border-1 not-allowed disabled"
                    href="#"
                    :disabled="in_Submission"
                  >
                    <span
                      class="d-flex justify-content-center align-items-center"
                    >
                      <img
                        class="avatar avatar-xss me-2"
                        src="/img/google-icon.svg"
                        alt="Image Description"
                      />
                      sign up with Google
                    </span>
                  </a>
                </div>
                <!-- spinners -->
                <div
                  class="d-flex justify-content-center my-2"
                  v-if="in_Submission"
                >
                  <div class="spinner-border text-primary" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </vee-form>
            </div>
            <!-- End Card Body -->
          </div>
          <!-- End Card -->
          <!-- End Content -->
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
  <overlay />
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import { csrf } from "@/includes/token.js";
import { checkPasswordStrenth } from "@/includes/helpers.js";
import { mapGetters } from "vuex";
import overlay from "@/components/staticPages/overlay.vue";

export default {
  name: "signUpModal",
  components: {
    overlay,
  },
  computed: {
    ...mapGetters({
      faculties: "faculties",
      usersCategories: "userCategories",
    }),
  },
  data() {
    return {
      registerSchema: {
        fullName: "required|max:100|alpha_spaces",
        ORCID: "regex:^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$",
        email: "required|regex:^[A-Za-z0-9._%+-]+@limu.edu.ly$",
        faculty: "required|numeric",
        category: "required|numeric",
        password: "required",
        password_confirmation: "required|confirmed:@password",
      },
      passwordInstructions: null,
      in_Submission: false,
      errors: null,
      success: null,
    };
  },
  methods: {
    async register(val) {
      let result = checkPasswordStrenth(val.password);
      if (!result) {
        console.log("enter");
        this.passwordInstructions =
          "<ul><li>At least 8 characters</li><li>Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number</li> <li>Can contain special characters</li></ul>";
      } else {
        this.passwordInstructions = null;
        document.getElementById("overlay").classList.toggle("d-none");
        this.in_Submission = true;
        await csrf();
        axiosConfig
          .post("register", val)
          .then(async (res) => {
            res.status == 204
              ? (this.success = "the user has been created successfully")
              : (this.success = null);
            await this.$store.dispatch("checkIfAuthenticated");
            this.$router.push({ name: "verifyEmail" });
            document.getElementById("closeSignUpModal").click();
            this.in_Submission = false;
          })
          .catch((err) => {
            console.log(err.response);
            document.getElementById("overlay").classList.toggle("d-none");
            /* error status 422 */
            if (err.response ? err.response.status == 422 : false) {
              this.errors = Object.values(err.response.data.errors)[0][0];
            } else {
              this.errors =
                "An error has been occured, please try again later or check your network";
            }
            console.log(err);
            this.in_Submission = false;
            return;
          });
        setTimeout(() => {
          this.errors = null;
          this.success = null;
        }, 3000);
      }
    },
  },
};
</script>

<style scoped>
.card {
  border-width: 0;
  box-shadow: unset !important;
}
</style>
