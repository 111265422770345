<template>
  <!-- chart title and filter -->
  <div class="text-center">
    <h6 class="research-title">Research Portal Bar chart Faculties Ranking</h6>
    <!-- bar chart filters -->
    <div class="text-sm fs-8">
      <!-- Form Check (all) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Inprogress"
          class="form-check-input p-2"
          name="ResearchStatuses"
          @change="changeChartData('%')"
          checked
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="Inprogress">All</label>
      </div>
      <!-- End Form Check -->

      <!-- Form Check (Accepted By REC) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Inprogress"
          class="form-check-input p-2"
          name="ResearchStatuses"
          @change="changeChartData('%', 'Accepted', '%')"
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="Inprogress"
          >Accepted By REC</label
        >
      </div>
      <!-- End Form Check -->

      <!-- Form Check (Accepted By RSC) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Inprogress"
          class="form-check-input p-2"
          name="ResearchStatuses"
          @change="changeChartData('%', '%', 'Accepted')"
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="Inprogress"
          >Accepted By RSC</label
        >
      </div>
      <!-- End Form Check -->

      <!-- Form Check (closed) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Closed"
          class="form-check-input indeterminate-checkbox p-2"
          name="ResearchStatuses"
          @change="changeChartData('Closed')"
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="formInlineRadio2"
          >Closed</label
        >
      </div>
      <!-- End Form Check -->

      <!-- Form Check (suspended) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Suspended"
          class="form-check-input p-2"
          name="ResearchStatuses"
          @change="changeChartData('Suspended')"
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="formInlineRadio3"
          >Suspended</label
        >
      </div>
      <!-- End Form Check -->

      <!-- Form Check (rejected) -->
      <div class="form-check form-check-inline">
        <input
          type="radio"
          id="Rejected"
          class="form-check-input p-2"
          name="ResearchStatuses"
          @change="changeChartData('Rejected')"
          :disabled="in_submission"
        />
        <label class="form-check-label p-1" for="formInlineRadio3"
          >Rejected</label
        >
      </div>
      <!-- End Form Check -->
    </div>
  </div>
  <div v-if="in_submission" class="d-flex justify-content-center text-primary">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!in_submission">
    <canvas id="barChartRankingFaculties"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import { mapGetters } from "vuex";
import axiosConfig from "@/includes/axiosConfig";

export default {
  name: "barChartRankingFaculties",
  props: {
    destroy: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters({
      faculties: "faculties",
    }),
  },
  data() {
    return {
      in_submission: false,
      labels: [],
      colors: [
        "#0885ce", //primaryColor
        "#077c76", //successColor
        "#f1b980", //warningColor
        "#692340", //dangerColor
      ],
      data: [],
      myChart: null,
    };
  },
  methods: {
    async fetchBarChartRankingFaculties(
      status = "%",
      accptedByRec = "%",
      accptedByRsc = "%"
    ) {
      this.in_submission = true;
      let vals = {};
      vals.status = status;
      vals.accptedByRec = accptedByRec;
      vals.accptedByRsc = accptedByRsc;
      await axiosConfig
        .post(`api/barChartRankingFaculties`, vals)
        .then((res) => {
          console.log(res);
          this.data = res.data.map((item) => item.total);
          this.labels = res.data.map((item) => item.faculty);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    createChartInctance() {
      const ctx = document.getElementById("barChartRankingFaculties");
      const data = {
        labels: this.labels,
        datasets: [
          {
            label: "Total Researches",
            data: this.data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
              "rgba(255, 205, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(201, 203, 207, 0.2)",
            ],
            borderColor: [
              "rgb(255, 99, 132)",
              "rgb(255, 159, 64)",
              "rgb(255, 205, 86)",
              "rgb(75, 192, 192)",
              "rgb(54, 162, 235)",
              "rgb(153, 102, 255)",
              "rgb(201, 203, 207)",
            ],
            borderWidth: 1,
          },
        ],
      };
      this.myChart = new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              ticks: {
                stepSize: 1,
                beginAtZero: true,
              },
            },
          },
        },
      });
      this.myChart;
    },
    async changeChartData(status, accptedByRec, accptedByRsc) {
      await this.fetchBarChartRankingFaculties(
        status,
        accptedByRec,
        accptedByRsc
      );
      this.destroy(this.myChart);
      this.createChartInctance();
    },
  },
  async created() {
    await this.fetchBarChartRankingFaculties();
    if (this.faculties.length > 0) {
      // this.labels = [];
      // this.faculties.forEach((faculty) => {
      //   this.labels.push(faculty["name"]);
      // });
    }
    this.createChartInctance();
  },
  unmounted() {
    this.destroy(this.myChart);
  },
};
</script>

<style></style>
