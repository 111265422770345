<template>
  <!-- alert message -->
  <div
    v-if="message != null && !in_submission"
    class="alert d-flex align-items-center text-white m-3 mt-0 text-start"
    :class="messageClass"
    role="alert"
  >
    <div>{{ message }}</div>
  </div>
  <div class="container mt-4">
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="(research == null && loadingResearch) || in_submission == true"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Comment -->
    <div class="row justify-content-lg-center">
      <div class="col-lg-12">
        <!-- Comment -->
        <ul class="list-comment">
          <!-- comments with replies Item -->
          <li
            class="list-comment-item bg-white p-4 shadow border rounded"
            v-for="comment in comments"
            :key="comment.id"
          >
            <!-- sender profile and name -->
            <div class="d-flex align-items-center mb-3">
              <div class="flex-shrink-0">
                <img
                  class="avatar avatar-circle"
                  :src="
                    comment.sender.profile_img_path ||
                    `/img/default_profile_image.jpg`
                  "
                  alt="Image Description"
                />
              </div>

              <div class="flex-grow-1 ms-3">
                <div class="d-flex justify-content-between align-items-center">
                  <h6>{{ comment.sender.full_name }}</h6>
                  <span class="d-block small text-muted">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      data-v-0bdb9d0d=""
                    >
                      <path
                        opacity="0.3"
                        d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                        fill="#3182ce"
                        data-v-0bdb9d0d=""
                      ></path>
                      <path
                        d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                        fill="#2670b6"
                        data-v-0bdb9d0d=""
                      ></path>
                    </svg>
                    {{
                      moment(comment.created_at).format("Y-M-DD | H:mm a")
                    }}</span
                  >
                </div>
              </div>
            </div>
            <!-- end sender profile and name -->
            <!-- content -->
            <span>
              <p class="overflow-hidden comment-content">
                {{ comment.content }}
              </p>
            </span>
            <!-- end content -->
            <!-- replay options -->
            <div class="container">
              <div class="row">
                <div class="col">
                  <!-- replay button -->
                  <a
                    class="link"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#replayModal"
                    href="#"
                    @click="currentReplay = comment"
                    >Reply
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 4L14 12L6 20H10L17.3 12.7C17.7 12.3 17.7 11.7 17.3 11.3L10 4H6Z"
                        fill="#3182ce"
                      ></path>
                    </svg>
                  </a>
                  <!-- end replay button -->
                  <!-- show replies button -->
                  <a
                    v-if="comment.replies.length > 0"
                    class="link mx-3"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="`#commentCollapse-${comment.id}`"
                    aria-expanded="false"
                  >
                    Show Replies ({{ comment.replies.length }})
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18 9V13.4H16V9C16 7.9 15.1 7 14 7H3C2.4 7 2 6.6 2 6C2 5.4 2.4 5 3 5H14C16.2 5 18 6.8 18 9Z"
                        fill="#3182ce"
                      ></path>
                      <path
                        opacity="0.3"
                        d="M12 13.4L16.3 17.7C16.7 18.1 17.3 18.1 17.7 17.7L22 13.4H12Z"
                        fill="#035A4B"
                      ></path>
                    </svg>
                  </a>
                  <!-- end show replies button -->
                </div>
              </div>
            </div>
            <!-- comments replies -->
            <div
              class="accordion"
              :id="`accordionExample-${comment.id}`"
              v-if="comment.replies.length > 0"
            >
              <div class="accordion-item">
                <div
                  :id="`commentCollapse-${comment.id}`"
                  class="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  :data-bs-parent="`#accordionExample-${comment.id}`"
                >
                  <div class="accordion-body">
                    <!-- Comment -->
                    <ul class="list-comment ms-5">
                      <!-- Item -->
                      <li
                        class="list-comment-item"
                        v-for="replay in comment.replies"
                        :key="replay.id"
                      >
                        <!-- Media -->
                        <div class="d-flex align-items-center mb-3">
                          <div class="flex-shrink-0">
                            <img
                              class="avatar avatar-circle"
                              :src="
                                replay.sender.profile_img_path ||
                                `/img/default_profile_image.jpg`
                              "
                              alt="Image Description"
                            />
                          </div>

                          <div class="flex-grow-1 ms-3">
                            <div
                              class="d-flex justify-content-between align-items-center"
                            >
                              <h6>{{ replay.sender.full_name }}</h6>
                              <span class="d-block small text-muted">{{
                                moment(replay.created_at).format(
                                  "Y-M-DD | H:mm a"
                                )
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <!-- End Media -->

                        <p>{{ replay.content }}</p>
                      </li>
                      <!-- End Item -->
                    </ul>
                    <!-- End Comment -->
                  </div>
                </div>
              </div>
            </div>
            <!-- end comments replaies -->
            <!-- end replay options -->
          </li>
          <!-- End comments with replies Item-->
        </ul>
        <!-- End Comment -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Comment -->
  <!-- no comments message -->
  <div
    class="row justify-content-center"
    v-if="comments.length == 0 && !in_submission"
  >
    <div class="col-12 text-center">
      <img src="/img/oc-chat.png" alt="research portal" class="w-25" />
      <h5 class="mt-3 text-muted fw-bold">
        there's no comments has been added yet.
      </h5>
    </div>
  </div>
  <!-- end no comments message -->
  <div class="row justify-content-lg-center">
    <div class="col-lg-12">
      <!-- Hero -->
      <div class="overflow-hidden">
        <div class="container content-space-1">
          <div class="w-100">
            <!-- Input Card -->
            <div class="input-card shadow">
              <div class="input-card-form">
                <textarea
                  v-model="content"
                  class="form-control"
                  rows="1"
                  placeholder="Type A Comment"
                  aria-label="Type A Comment"
                ></textarea>
              </div>
              <button
                type="button"
                class="btn btn-primary"
                @click="addNewComment(content)"
                :disabled="in_submission"
              >
                Send
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.43 8.56949L10.744 15.1395C10.6422 15.282 10.5804 15.4492 10.5651 15.6236C10.5498 15.7981 10.5815 15.9734 10.657 16.1315L13.194 21.4425C13.2737 21.6097 13.3991 21.751 13.5557 21.8499C13.7123 21.9488 13.8938 22.0014 14.079 22.0015H14.117C14.3087 21.9941 14.4941 21.9307 14.6502 21.8191C14.8062 21.7075 14.9261 21.5526 14.995 21.3735L21.933 3.33649C22.0011 3.15918 22.0164 2.96594 21.977 2.78013C21.9376 2.59432 21.8452 2.4239 21.711 2.28949L15.43 8.56949Z"
                    fill="#ffff"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M20.664 2.06648L2.62602 9.00148C2.44768 9.07085 2.29348 9.19082 2.1824 9.34663C2.07131 9.50244 2.00818 9.68731 2.00074 9.87853C1.99331 10.0697 2.04189 10.259 2.14054 10.4229C2.23919 10.5869 2.38359 10.7185 2.55601 10.8015L7.86601 13.3365C8.02383 13.4126 8.19925 13.4448 8.37382 13.4297C8.54839 13.4145 8.71565 13.3526 8.85801 13.2505L15.43 8.56548L21.711 2.28448C21.5762 2.15096 21.4055 2.05932 21.2198 2.02064C21.034 1.98196 20.8409 1.99788 20.664 2.06648V2.06648Z"
                    fill="#ffff"
                  ></path>
                </svg>
              </button>
            </div>
            <!-- End Input Card -->
          </div>
        </div>
      </div>
      <!-- End Hero -->
    </div>
  </div>
  <replayModal :comment="currentReplay" :fetchComments="fetchComments" />
</template>

<script>
import replayModal from "@/components/forum/replayModal.vue";
import axiosConfig from "@/includes/axiosConfig.js";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    replayModal,
  },
  props: {
    getResearchInfo: {
      required: true,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  name: "forumMain",
  data() {
    return {
      in_submission: false,
      message: null,
      messageClass: null,
      content: null,
      comments: [],
      currentReplay: null,
    };
  },
  methods: {
    async addNewComment(content) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      let vals = {
        content: content,
      };
      await axiosConfig
        .post(`api/addComment/${this.user.id}/${researchId}`, vals)
        .then((res) => {
          this.messageClass = "alert-success";
          this.message = res.data;
          this.content = null;
          this.fetchComments();
        })
        .catch((err) => {
          console.log(err.response);
          this.messageClass = "alert-danger";
          this.message = "Error, please try again later.";
        });
      setTimeout(() => {
        this.message = null;
      }, 3000);
      this.in_submission = false;
    },
    async fetchComments() {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      await axiosConfig
        .get(`api/fetchComments/${researchId}`)
        .then((res) => {
          console.log(res);
          this.comments = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
      setTimeout(() => {
        this.message = null;
      }, 3000);
      this.in_submission = false;
    },
    moment,
  },
  activated() {
    this.comments.length == 0 && !this.in_submission
      ? this.fetchComments()
      : "";
  },
  watch: {
    research() {
      this.fetchComments();
    },
  },
};
</script>

<style>
.accordion-item {
  border: 0px !important;
}

.comment-content:hover {
  overflow: auto !important;
}
</style>
