<template>
  <div
    class="dropdown text-end me-4 notification-bell my-4"
    v-if="$store.state.authenticated"
  >
    <!-- bell icon and badge number -->
    <a
      href="#"
      class="d-block link-dark text-decoration-none dropdown-toggle"
      id="dropdownUser1"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          opacity="0.3"
          d="M12 22C13.6569 22 15 20.6569 15 19C15 17.3431 13.6569 16 12 16C10.3431 16 9 17.3431 9 19C9 20.6569 10.3431 22 12 22Z"
          fill="#2670b6"
        ></path>
        <path
          d="M19 15V18C19 18.6 18.6 19 18 19H6C5.4 19 5 18.6 5 18V15C6.1 15 7 14.1 7 13V10C7 7.6 8.7 5.6 11 5.1V3C11 2.4 11.4 2 12 2C12.6 2 13 2.4 13 3V5.1C15.3 5.6 17 7.6 17 10V13C17 14.1 17.9 15 19 15ZM11 10C11 9.4 11.4 9 12 9C12.6 9 13 8.6 13 8C13 7.4 12.6 7 12 7C10.3 7 9 8.3 9 10C9 10.6 9.4 11 10 11C10.6 11 11 10.6 11 10Z"
          fill="#18446d"
        ></path>
      </svg>

      <span class="notification-style-2" v-if="totalNotification != null">{{
        totalNotification
      }}</span>
    </a>
    <!-- notifications list -->
    <ul
      class="dropdown-menu text-small"
      aria-labelledby="dropdownUser1"
      style=""
    >
      <div class="row px-2">
        <!-- notification header -->
        <div class="col-auto w-100">
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">
                <h6 class="m-0 text-muted fs-8 fw-bold">
                  Unread Notifications
                </h6>
              </div>
              <div class="col-auto">
                <!-- button to lunch sidebarNotificaitons component -->
                <a
                  href="#!"
                  class="smal fs-8 fw-bold"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#sideBarNotifications"
                  aria-controls="sideBarNotifications"
                  >View All</a
                >
              </div>
            </div>
          </div>
        </div>
        <!-- notification list -->
        <div class="col-auto w-100" v-if="notifications.length > 0">
          <li v-for="notification in notifications" :key="notification.id">
            <a
              @click="goToNotification(notification)"
              class="text-reset notification-item dropdown-item p-1 my-2 cursor-p"
              ><div class="media w-100">
                <div class="avatar-xs mr-3">
                  <!-- notification avatar -->
                  <span
                    class="avatar-title step-avatar rounded-circle font-size-16"
                  >
                    <img
                      class="step-avatar-img"
                      :src="
                        notification.data.avatar ||
                        '/img/default_profile_image.jpg'
                      "
                      alt=""
                  /></span>
                </div>
                <div class="media-body ms-2">
                  <!-- notification title -->
                  <h6 class="mt-0 mb-1 font-size-16">
                    {{ notification.data.title }}
                  </h6>
                  <div class="font-size-12 text-muted">
                    <!-- notification context -->
                    <p class="mb-1">
                      {{ notification.data.context }}
                    </p>
                    <!-- notification time -->
                    <p class="mb-0">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                          fill="#3182ce"
                        ></path>
                        <path
                          d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                          fill="#2670b6"
                        ></path>
                      </svg>
                      {{ formatDate(notification.notification_date) }}
                    </p>
                  </div>
                </div>
              </div></a
            >
          </li>
        </div>
        <!-- there's no notifications -->
        <div
          class="container"
          v-if="notifications.length == 0 && !in_submission"
        >
          <div class="row">
            <div class="col-auto w-100 text-center">
              <p class="fw-bold fs-8 pt-5 text-center">
                you don't have new notifications.
              </p>
            </div>
          </div>
        </div>
        <!-- load more button -->
        <div class="text-center w-100">
          <li
            class="btn btn-outline-primary btn-sm"
            v-if="!in_submission && nextPage != null"
            @click="fetchNotifications(nextPage)"
          >
            Load more
            <svg
              class="mx-1"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1 15.8C16.6 15.7 16 16 15.9 16.5C15.7 17.4 14.9 18 14 18H6C4.9 18 4 17.1 4 16V8C4 6.9 4.9 6 6 6H14C15.1 6 16 6.9 16 8V9.4H18V8C18 5.8 16.2 4 14 4H6C3.8 4 2 5.8 2 8V16C2 18.2 3.8 20 6 20H14C15.8 20 17.4 18.8 17.9 17.1C17.9 16.5 17.6 16 17.1 15.8Z"
                fill="#3182ce"
              ></path>
              <path
                opacity="0.3"
                d="M11.9 9.39999H21.9L17.6 13.7C17.2 14.1 16.6 14.1 16.2 13.7L11.9 9.39999Z"
                fill="#2670b6"
              ></path>
            </svg>
          </li>
        </div>
        <!-- loading icon -->
        <div class="col-auto text-center w-100 p-5" v-if="in_submission">
          <div class="d-flex justify-content-center text-primary">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </ul>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import moment from "moment";

export default {
  name: "notificationBell",
  data() {
    return {
      notifications: [],
      totalNotification: null,
      nextPage: null,
      in_submission: false,
    };
  },
  methods: {
    async fetchNotifications(page = 1) {
      this.in_submission = true;
      await axiosConfig
        .get(`api/fetchUnReadNotifications?page=${page}`)
        .then((res) => {
          console.log(res);
          res.data.data.forEach((item) => {
            this.notifications.push(item);
          });
          res.data.next_page_url != null
            ? (this.nextPage = res.data.next_page_url.slice(-1))
            : (this.nextPage = null);
          this.totalNotification = res.data.total;
        })
        .catch((err) => {
          console.log(err);
        });
      this.in_submission = false;
    },
    formatDate(date) {
      var today = moment(new Date()).format("MM-DD-YYYY");
      if (date == today) {
        return "today";
      } else if (moment(date).isSame(new Date(), "week")) {
        return "this week";
      } else if (moment(date).isSame(new Date(), "month")) {
        return "this month";
      } else if (moment(date).isSame(new Date(), "year")) {
        return "this year";
      }
    },
    async goToNotification(notification) {
      this.in_submission = true;

      this.$router.push({ path: notification.data.url });
      await axiosConfig
        .get(`api/notificationAsReaded/${notification.id}`)
        .then((res) => {
          console.log(res);
          this.notifications = this.notifications.filter((val) => {
            return val.id != notification.id;
          });
          this.totalNotification = this.totalNotification - 1;
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
  created() {
    this.fetchNotifications();
  },
};
</script>

<style>
.notification-style-2 {
  position: relative;
  background: #c32545;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  top: -12px;
  padding: 2px 5px;
  font-size: 10px;
  font-weight: bolder;
  letter-spacing: 2px;
  text-transform: uppercase;
  right: 8px;
  color: #ffff;
}

.notification-bell ul {
  min-width: 20rem;
  max-height: 25rem;
  overflow: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8c98a4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8c98a4;
}

.notification-bell ul li a {
  white-space: unset !important;
}

.text-reset {
  color: inherit !important;
}

.media {
  display: flex;
  align-items: flex-start;
}
.avatar-xs {
  height: 2rem;
  width: 2rem;
}

.font-size-16 {
  font-size: 16px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.avatar-title {
  align-items: center;
  color: #fff;
  display: flex;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  width: 100%;
}

.media-body {
  flex: 1;
}

.step-avatar-img {
  max-width: 100%;
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
</style>
