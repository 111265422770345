<template>
  <RRSheader />
  <!-- if the research is editable -->
  <page-title v-if="!researchEditable">
    <template v-slot:titlePrefix>Register a new </template>
    <template v-slot:mainTitle>Research </template>
  </page-title>
  <!-- if not editable -->
  <page-title v-else>
    <template v-slot:titlePrefix>Edit your </template>
    <template v-slot:mainTitle>Research </template>
  </page-title>
  <!-- loading icon -->
  <div
    class="d-flex justify-content-center text-primary pt-5"
    v-if="loadingPage"
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <!-- research form -->
  <div class="container" v-if="!loadingPage">
    <div class="row justify-content-center">
      <div class="col m-5 p-4 border border-1 rounded shadow">
        <!-- Form -->
        <vee-form
          id="researchRegistrationForm"
          @submit="researchRegistration"
          :validationSchema="researchForm"
          :initialValues="researchDefaultValues"
          @keypress.enter="$event.preventDefault()"
        >
          <div class="mb-5">
            <h6>General Information</h6>
          </div>

          <!-- Form for the title-->
          <div class="row mb-3">
            <label for="fullNameCareersForm" class="col-sm-4 col-form-label"
              >Title of study <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <vee-field
                type="text"
                class="form-control"
                placeholder="title of the study"
                id="title"
                name="title"
                :rules="{ regex: `/^[\s\d\pL\pM]+$/u` }"
              ></vee-field>
              <error-message class="text-danger" name="title" />
            </div>
          </div>
          <!-- End Form -->

          <!-- Form for the keywords -->
          <div class="row mb-3">
            <label for="keyword" class="col-sm-4 col-form-label"
              >Keywords</label
            >
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="keyword"
                name="keyword"
                placeholder="press enter after inserting a keyword"
                @keypress="getKeyword"
                autocomplete="off"
              />

              <!-- keywords area -->
              <div class="my-3" v-if="keywords.length > 0">
                <span
                  v-for="keyword in keywords"
                  :key="keyword"
                  class="badge bg-primary rounded-pill m-1"
                  >{{ keyword }}
                  <!-- remove keyword button -->
                  <a
                    class="btn btn-sm p-0"
                    @click="popFromArray(keywords, keyword)"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                        fill="#ffff"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                        fill="#ffff"
                      ></path>
                    </svg>
                  </a>
                </span>
              </div>
            </div>
          </div>
          <!-- End Form -->

          <!-- Form for the study types -->
          <div class="row mb-3">
            <label for="typeOfStudy" class="col-sm-4 col-form-label"
              >Type of study <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <vee-field
                as="select"
                name="typeOfStudy"
                class="form-control"
                :disabled="in_submission"
              >
                <option value="" disabled selected>choose a study type</option>
                <option
                  v-for="studyType in studiesTypes"
                  :key="studyType.id"
                  :value="studyType.id"
                >
                  {{ studyType.name }}
                </option>
              </vee-field>
              <error-message class="text-danger" name="typeOfStudy" />
            </div>
          </div>
          <!-- End Form -->

          <!-- Form for the faculties -->
          <div class="row mb-3">
            <label for="resumeCVCareersForm" class="col-sm-4 col-form-label"
              >Choose a program <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <vee-field
                as="select"
                name="faculty"
                class="form-control"
                :disabled="in_submission"
                ><option value="" disabled selected>choose a program</option>
                <option
                  v-show="!faculty.disabled"
                  v-for="faculty in faculties"
                  :key="faculty.id"
                  :value="faculty.id"
                >
                  {{ faculty.name }}
                </option>
              </vee-field>
              <error-message class="text-danger" name="faculty" />
            </div>
          </div>
          <!-- End Form -->

          <!-- Form research category -->
          <div class="row mb-3">
            <label for="researchCategory" class="col-sm-4 col-form-label"
              >Choose a category <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <vee-field
                name="researchCategory"
                as="select"
                class="form-control"
                :disabled="in_submission"
              >
                <option value="" disabled selected>
                  choose the research category
                </option>
                <option
                  v-for="category in researchCategories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.name }}
                </option>
              </vee-field>
              <error-message class="text-danger" name="researchCategory" />
            </div>
          </div>
          <!-- End Form -->

          <!-- Form for the research graduate project -->
          <div class="row mb-3">
            <label for="withFund" class="col-sm-4 col-form-label"
              >This is a graduate project
              <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <!-- radio button -->
              <div class="form-check mb-3 my-2">
                <input
                  type="radio"
                  name="graduateProject"
                  class="form-check-input"
                  value="true"
                  @input="graduateProject = 'true'"
                  :checked="graduateProject == 'true'"
                />
                <label class="form-check-label" for="graduateProject"
                  >Yes</label
                >
              </div>
              <!-- End radio button -->
              <!-- radio button -->
              <div class="form-check mb-3 my-2">
                <input
                  type="radio"
                  name="graduateProject"
                  class="form-check-input"
                  value="false"
                  @input="graduateProject = 'false'"
                  :checked="graduateProject == 'false'"
                />
                <label class="form-check-label" for="graduateProject">No</label>
              </div>
              <!-- radio button -->
            </div>
          </div>
          <!-- End Form -->

          <!-- Form for the research fund -->
          <div class="row mb-3" v-show="graduateProject == 'false'">
            <label for="withFund" class="col-sm-4 col-form-label"
              >I want this research to be funded by LIMU
              <i class="text-danger fw-bold">*</i></label
            >
            <div class="col-sm-8">
              <!-- radio button -->
              <div class="form-check mb-3 my-2">
                <input
                  type="radio"
                  name="ResearchFund"
                  class="form-check-input"
                  value="true"
                  @input="researchFund = 'true'"
                  :checked="researchFund == 'true'"
                />
                <label class="form-check-label" for="ResearchFund">Yes</label>
              </div>
              <!-- End radio button -->
              <!-- radio button -->
              <div class="form-check mb-3 my-2">
                <input
                  type="radio"
                  name="ResearchFund"
                  class="form-check-input"
                  value="false"
                  @input="researchFund = 'false'"
                  :checked="researchFund == 'false'"
                />
                <label class="form-check-label" for="ResearchFund">No</label>
              </div>
              <!-- radio button -->
            </div>
          </div>
          <!-- End Form -->

          <hr class="my-5 my-sm-10" />

          <!-- authors section -->
          <authors
            :researchAuthors="researchAuthors"
            :pushAuthor="pushInArray"
            :popAuthor="popFromArray"
          />
          <!-- authors section ends here -->

          <hr class="my-5 my-sm-10" />

          <!-- upload files section -->
          <upload-files
            :attachedFiles="attachedFiles"
            :attachedProposal="attachedProposal"
            :pushInArray="pushInArray"
            :popFromArray="popFromArray"
            :resetArray="resetArray"
          />
          <!-- error -->
          <b class="text-danger fw-bold d-block" v-if="error != null">{{
            error
          }}</b>
          <!-- end error -->
          <!-- upload files section edns here.. -->

          <hr class="my-5 my-sm-10" />

          <div class="mb-5">
            <h6>Additional Information</h6>
          </div>

          <!-- Form -->
          <div class="mb-3">
            <label for="additionalInfo" class="form-label visually-hidden"
              >Additional Information</label
            >
            <vee-field
              as="textarea"
              class="form-control"
              name="additionalInfo"
              id="additionalInfo"
              placeholder="Add a cover letter or anything else you want to share."
              aria-label="Add a cover letter or anything else you want to share."
              rows="5"
            ></vee-field>
          </div>
          <!-- End Form -->

          <div class="d-grid text-center mt-7">
            <button
              v-if="!in_submission"
              class="btn btn-primary-dark btn-lg"
              type="submit"
            >
              {{ !researchEditable ? "Submit application" : "Update research" }}
            </button>
            <div class="d-flex justify-content-center text-light">
              <div v-if="in_submission" class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>

          <!-- Api message -->
          <div
            class="alert alert-dismissible fade show my-2"
            role="alert"
            v-if="apiMessage != null"
            :class="messageClass"
          >
            {{ apiMessage }}
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
          <!-- end Api message -->
        </vee-form>
        <!-- End Form -->
      </div>
    </div>
  </div>

  <div class="shape-container" v-if="!loadingPage">
    <div class="shape shape-bottom text-primary">
      <svg
        width="3000"
        height="400"
        viewBox="0 0 3000 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 400H3000V0L0 400Z" fill="#7abeef"></path>
      </svg>
    </div>
  </div>
  <popUpMessage />
</template>

<script>
import { mapGetters } from "vuex";
import { hasRole } from "@/includes/helpers.js";
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "@/components/RRSheader.vue";
import authors from "@/components/researchRegistration/authors.vue";
import store from "@/store/index.js";
import axiosConfig from "@/includes/axiosConfig.js";
import UploadFiles from "@/components/researchRegistration/uploadFiles.vue";

export default {
  name: "submitResearch",
  components: { pageTitle, RRSheader, authors, UploadFiles },
  computed: {
    ...mapGetters({
      user: "user",
      faculties: "faculties",
      researchCategories: "categories",
      studiesTypes: "studyTypes",
    }),
  },
  data() {
    return {
      researchForm: {
        title: "required|max:100|min:10", // aditional rules on the input
        typeOfStudy: "required|numeric",
        faculty: "required|numeric",
        researchCategory: "required|numeric",
        additionalInfo: "max:250",
      },
      researchFund: "",
      graduateProject: "",
      researchDefaultValues: {},
      keywords: [],
      researchAuthors: [],
      attachedProposal: [],
      attachedFiles: [],
      in_submission: false,
      loadingPage: false,
      error: null,
      apiMessage: null,
      messageClass: null,
      researchRegistrationForm: null,
      researchEditable: false,
    };
  },
  methods: {
    getKeyword(e) {
      if (e.keyCode == 13) {
        let keyword = document.getElementsByName("keyword")[0];
        this.pushInArray(this.keywords, keyword.value);
        keyword.value = "";
      }
    },
    pushInArray(array, val) {
      !array.includes(val) ? array.push(val) : "";
    },
    popFromArray(array, val) {
      let index = array.indexOf(val);
      array.splice(index, 1);
    },
    resetArray(array) {
      array.splice(0, array.length);
    },
    removeDuplicates(arr) {
      return [...new Set(arr)];
    },
    async researchRegistration(val) {
      val.researchFund = this.researchFund;
      val.graduateProject = this.graduateProject;
      // collecting all the attached files lenght
      let filesLength =
        this.attachedFiles.length + this.attachedProposal.length;
      if ((filesLength >= 1 && filesLength <= 3) || this.researchEditable) {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        };
        let data = new FormData();
        data.append("title", val.title || "");
        data.append("typeOfStudy", val.typeOfStudy || "");
        data.append("faculty", val.faculty || "");
        data.append("researchCategory", val.researchCategory || "");
        data.append("additionalInfo", val.additionalInfo || "");
        data.append("ResearchFund", val.researchFund || "");
        data.append("graduateProject", val.graduateProject || "");
        /* appending the files */
        /*the proposal*/
        this.attachedProposal.length > 0
          ? data.append("attachedProposal", this.attachedProposal[0] || "")
          : "";
        /* the other files */
        for (let i = 0; i < this.attachedFiles.length; i++) {
          data.append("AttachedFiles[]", this.attachedFiles[i]);
        }
        /* appending the authors including the researches who uploads the prposal*/
        !this.researchAuthors.includes(this.user.email)
          ? this.researchAuthors.unshift(this.user.email)
          : "";
        this.researchAuthors = this.removeDuplicates(this.researchAuthors);
        for (let i = 0; i < this.researchAuthors.length; i++) {
          data.append("authors[]", this.researchAuthors[i]);
        }
        /* appending the keywords */
        for (let i = 0; i < this.keywords.length; i++) {
          data.append("keywords[]", this.keywords[i]);
        }
        this.in_submission = true;
        let url = !this.researchEditable
          ? "api/researchRegistration"
          : `api/updateResearch/${this.$route.params.id}`;
        await axiosConfig
          .post(url, data, config)
          .then(() => {
            this.messageClass = "alert-success";
            this.apiMessage = !this.researchEditable
              ? "the proposal has been submitted successfully."
              : "the proposal has been updated successfully";
            document.getElementById("proposalInput").value = "";
            this.researchAuthors = !this.researchEditable
              ? []
              : this.researchAuthors;
            this.keywords = !this.researchEditable ? [] : this.keywords;
            this.attachedProposal = !this.researchEditable
              ? []
              : this.attachedProposal;
            this.attachedFiles = !this.researchEditable
              ? []
              : this.attachedFiles;
            setTimeout(() => {
              if (this.researchEditable) {
                this.$router.go(-1);
              } else {
                this.$store.dispatch("checkIfAuthenticated");
                this.$router.replace({ name: "main" });
              }
            }, 3000);
          })
          .catch((err) => {
            console.log(err.response);
            this.messageClass = "alert-danger";
            /* error status 422 */
            err.response.status == 422
              ? (this.apiMessage = err.response.data)
              : (this.apiMessage = null);
            /* error status 500 */
            err.response.status == 500
              ? (this.apiMessage =
                  "An error has been accured, please try again later.")
              : "";
            /* error status 403 */
            err.response.status == 403
              ? (this.apiMessage = "This action is unauthorized.")
              : "";
            return;
          });
        setTimeout(() => {
          this.error = null;
          this.apiMessage = null;
        }, 6000);
        this.in_submission = false;
      } else {
        this.error =
          "The maximum number of files is 3 and the minimum is 1, please hit the instuctions button above.";
      }
    },
    async makeResearchEditable(id) {
      if (id) {
        await axiosConfig
          .get(`api/researchInfo/${id}`)
          .then((res) => {
            console.log(res);
            this.researchEditable = true;
            let research = res.data;
            this.isEditable(research);
            this.researchDefaultValues.title = research.title;
            this.keywords =
              research.keywords.length > 0 ? research.keywords.split(",") : [];
            this.researchDefaultValues.typeOfStudy = research.study_type_id;
            this.researchDefaultValues.faculty = research.faculty_id;
            this.researchDefaultValues.additionalInfo = research.info;
            this.researchDefaultValues.researchCategory = research.category_id;
            this.researchFund = research.withFund ? "true" : "false";
            this.graduateProject = research.graduate_project ? "true" : "false";
            research.authors.forEach((val) => {
              this.researchAuthors.push(val.email);
            });
          })
          .catch((err) => {
            this.$router.replace({ name: "main" });
            console.log(err.response);
          });
      } else {
        this.$route.replace({ name: "main" });
      }
    },
    isEditable(research) {
      if (
        !(
          research.creator.id == this.user.id &&
          (research.status.name == "Registered" ||
            research.status.name == "With modifications")
        )
      ) {
        this.$router.go(-1);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (!hasRole(["researcher"])) {
      next({ name: "main" });
    } else {
      next();
    }
  },
  async created() {
    this.loadingPage = true;
    //determine what ever if the research is editable or not
    this.$route.params.id
      ? await this.makeResearchEditable(this.$route.params.id)
      : "";
    this.loadingPage = false;
  },
  mounted() {
    this.researchRegistrationForm = document.getElementById(
      "researchRegistrationForm"
    );
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: 0 1.375rem 1.5rem 0 rgb(0 0 0 / 13%) !important;
}
</style>
