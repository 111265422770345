<template>
  <div class="container-fluid bg-primary pt-3">
    <div class="row ms-5">
      <div class="col">
        <div class="d-flex flex-row justify-content-between">
          <h1 class="display-8 text-white mb-3 mb-md-4 fw-bold">
            <slot name="titlePrefix"></slot>
            <span class="text-info"><slot name="mainTitle"></slot></span>
          </h1>

          <button
            class="me-4 cursor-p btn p-0 pb-0 m-0 mb-2 text-white"
            @click="refresh"
            :disabled="in_submission"
          >
            Refresh
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5 20.7259C14.6 21.2259 14.2 21.826 13.7 21.926C13.2 22.026 12.6 22.0259 12.1 22.0259C9.5 22.0259 6.9 21.0259 5 19.1259C1.4 15.5259 1.09998 9.72592 4.29998 5.82592L5.70001 7.22595C3.30001 10.3259 3.59999 14.8259 6.39999 17.7259C8.19999 19.5259 10.8 20.426 13.4 19.926C13.9 19.826 14.4 20.2259 14.5 20.7259ZM18.4 16.8259L19.8 18.2259C22.9 14.3259 22.7 8.52593 19 4.92593C16.7 2.62593 13.5 1.62594 10.3 2.12594C9.79998 2.22594 9.4 2.72595 9.5 3.22595C9.6 3.72595 10.1 4.12594 10.6 4.02594C13.1 3.62594 15.7 4.42595 17.6 6.22595C20.5 9.22595 20.7 13.7259 18.4 16.8259Z"
                fill="#ffff"
              ></path>
              <path
                opacity="0.3"
                d="M2 3.62592H7C7.6 3.62592 8 4.02592 8 4.62592V9.62589L2 3.62592ZM16 14.4259V19.4259C16 20.0259 16.4 20.4259 17 20.4259H22L16 14.4259Z"
                fill="#ffff"
              ></path>
            </svg>
          </button>
        </div>
        <div class="col">
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <button class="btn d-inline p-0 pe-3" @click="$router.go(-1)">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z"
                    fill="#ffff"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z"
                    fill="#ffff"
                  ></path>
                </svg>
              </button>
              <li
                v-for="path in currentRouteFullPath"
                :key="path.name"
                class="breadcrumb-item"
              >
                <router-link :to="{ name: path.routeName }" :key="$route.path">
                  {{ path.name }}
                </router-link>
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pageTitle",
  props: {
    refresh: {
      type: Function,
      required: false,
    },
    in_submission: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    currentRouteFullPath() {
      /* old method */
      // let params = this.$route.params;
      // params = Object.values(params);
      // let currentFullPath = this.$route.fullPath;
      // currentFullPath = currentFullPath.split("/");
      // currentFullPath = currentFullPath.filter((val) => {
      //   if (!params.includes(val)) {
      //     console.log(val);
      //     return val;
      //   }
      // });
      /* new method */
      let currentFullPath = this.$route.meta.breadCrumb;
      return currentFullPath;
    },
  },
};
</script>

<style scoped>
.breadcrumb .breadcrumb-item .exact-active {
  color: #f0f0f0 !important;
  text-decoration: underline;
}
</style>
