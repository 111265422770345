<template>
  <RRSheader />
  <page-title>
    <template v-slot:titlePrefix>Your </template>
    <template v-slot:mainTitle>Profile </template>
  </page-title>
  <!-- User Profile -->
  <div class="bg-soft-info">
    <div class="container vh-100 content-space-1 content-space-md-2">
      <div class="row justify-content-md-start">
        <div class="col-md-8">
          <!-- Media -->
          <div class="d-sm-flex cursor-p">
            <div
              class="flex-shrink-0 mb-3 mb-sm-0"
              @mouseenter="!in_submission ? (showUserImg = true) : ''"
              @mouseleave="!in_submission ? (showUserImg = false) : ''"
            >
              <!-- pencil edit image -->
              <div
                class="position-relative"
                v-if="showUserImg"
                @click="!in_submission ? clickInputFile() : ''"
              >
                <a class="position-absolute start-0 top-0 text-center cursor-p">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303V8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75953 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>
              </div>
              <!-- avatar -->
              <img
                class="avatar avatar-xxl avatar-circle"
                :src="currentImg || `/img/default_profile_image.jpg`"
                alt="Image Description"
              />
              <input
                type="file"
                class="d-none"
                id="userImge"
                accept="image/*"
                @change="!in_submission ? loadImg($event, user.id) : ''"
              />
            </div>

            <div class="flex-grow-1 ms-sm-4">
              <!-- Media -->
              <div
                class="d-flex justify-content-between align-items-center mb-3"
              >
                <h4
                  @click="
                    !in_submission ? (showNameField = !showNameField) : ''
                  "
                  v-if="!showNameField"
                  class="mb-0"
                >
                  {{ user.full_name }}
                </h4>
                <!-- input field user name-->
                <div
                  v-if="showNameField"
                  class="input-card input-card-sm p-0 h-0 border border-1 border-secondary"
                >
                  <div class="input-card-form">
                    <input
                      type="text"
                      class="form-control"
                      v-model="userName"
                    />
                  </div>
                  <a
                    @click="!in_submission ? updateProfile(user.id) : ''"
                    class="pt-2 mx-1 cursor-p"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                        fill="#3182ce"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                        fill="#2670b6"
                      ></path>
                    </svg>
                  </a>
                  <a
                    @click="!in_submission ? (showNameField = false) : ''"
                    class="pt-2 mx-1 cursor-p"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                        fill="#3182ce"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                        fill="#2670b6"
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
              <!-- End Media -->

              <div class="d-lg-flex flex-lg-row mb-3">
                <div class="col-auto p-2">
                  <span class="h6">published research</span>
                  <span class="ms-1">{{ user.researches_count }}</span>
                </div>
                <!-- End Col -->

                <div class="col-auto p-2">
                  <span class="h6">Faculty </span>
                  <span class="ms-1">{{ user.faculty.name }}</span>
                </div>
                <!-- End Col -->

                <div class="col-auto p-2">
                  <span class="h6">Role</span>
                  <span class="ms-1"
                    >{{ user.category.name }} -
                    <template v-for="role in user.userRoles" :key="role">
                      {{ ` (${role}) ` }}
                    </template>
                  </span>
                </div>
              </div>
              <!-- End col -->
              <!-- End Row -->

              <p
                v-if="!showBioField"
                class="text-muted"
                @click="!in_submission ? (showBioField = !showBioField) : ''"
              >
                {{
                  user.bio ||
                  "Hey i'm using limu research registration system..."
                }}
              </p>
              <!-- input field user bio -->
              <div
                v-if="showBioField"
                class="input-card input-card-sm p-0 h-0 border border-1 border-secondary"
              >
                <div class="input-card-form">
                  <textarea
                    class="form-control"
                    v-model="userBio"
                    aria-label="Your business email"
                  ></textarea>
                </div>
                <a
                  @click="!in_submission ? updateProfile(user.id) : ''"
                  class="pt-2 mx-1 cursor-p"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>
                <a
                  @click="!in_submission ? (showBioField = false) : ''"
                  class="pt-2 mx-1 cursor-p"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <!-- End Media -->
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
    </div>
  </div>
  <!-- End User Profile -->
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "../RRSheader.vue";
import { mapGetters } from "vuex";
import axiosConfig from "@/includes/axiosConfig";
import store from "@/store";

export default {
  components: { pageTitle, RRSheader },
  name: "profile",
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  data() {
    return {
      userName: null,
      userBio: null,
      userImgFile: null,
      currentImg: null,
      showNameField: false,
      showBioField: false,
      showUserImg: false,
      in_submission: false,
    };
  },
  methods: {
    clickInputFile() {
      document.getElementById("userImge").click();
    },
    async updateProfile(id) {
      this.in_submission = true;
      this.showNameField = false;
      this.showBioField = false;
      this.showUserImg = false;
      console.log(this.userName, this.userBio, this.userImgFile, id);
      let data = new FormData();
      data.append("userName", this.userName || "");
      data.append("userBio", this.userBio || "");
      this.userImgFile != null
        ? data.append("userImgFile", this.userImgFile || "")
        : "";
      const config = {
        headers: {
          "content-type": "multipart/form-data",
          Accept: "application/json",
        },
      };
      await axiosConfig
        .post(`api/UpdateProfile/${id}`, data, config)
        .then((res) => {
          console.log(res);
          this.$store.dispatch("checkIfAuthenticated");
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    loadImg(e, id) {
      this.userImgFile = e.target.files[0];
      this.currentImg = URL.createObjectURL(e.target.files[0]);
      this.updateProfile(id);
    },
  },
  created() {
    this.userName = this.user.full_name;
    this.userBio = this.user.bio;
    this.currentImg = this.user.profile_img_path;
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>

<style></style>
