<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#underReview"
    id="underReviewBTN"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="underReview"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    aria-labelledby="underReviewLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="underReviewLabel">
            Do you want to change the proposal status to
            <span v-if="researchStatusName === 'Under review'">
              registered
            </span>
            <span v-else> under review </span>
            ?
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeUnderViewModal"
          ></button>
        </div>
        <div class="modal-body">
          We will notifiy the researcher that his proposal status has been
          changed.
        </div>
        <div class="modal-footer">
          <button
            v-if="!in_submission && researchStatusName == 'Registered'"
            type="button"
            class="btn btn-primary"
            id="yesUnderReviewBTN"
            @click="changeToUnderReview('Under review')"
          >
            Yes
          </button>
          <button
            v-if="!in_submission && researchStatusName == 'Under review'"
            type="button"
            class="btn btn-primary"
            @click="changeToUnderReview('Registered')"
          >
            Yes
          </button>
          <div
            class="spinner-border text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            Not now
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "underReviewModal",
  props: {
    researchStatusName: {
      required: true,
      type: String,
    },
    changeStatusToUnderReview: {
      type: Function,
    },
  },
  data() {
    return {
      in_submission: false,
    };
  },
  methods: {
    async changeToUnderReview(researchStatusName) {
      this.in_submission = true;
      await this.changeStatusToUnderReview(researchStatusName);
      document.getElementById("closeUnderViewModal").click();
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
