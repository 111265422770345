<template>
  <div>
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="loadingResearch"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- Step -->
    <ul
      class="step step-timeline-md"
      v-if="research != null && !loadingResearch"
    >
      <!-- Registered icon -->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark': !isCurrentStatusBool('Registered'),
              'step-icon-soft-warning': isCurrentStatusBool('Registered'),
            }"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              :fill="isCurrentStatusColors('Registered')[0]"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM15 17C15 16.4 14.6 16 14 16H8C7.4 16 7 16.4 7 17C7 17.6 7.4 18 8 18H14C14.6 18 15 17.6 15 17ZM17 12C17 11.4 16.6 11 16 11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H16C16.6 13 17 12.6 17 12ZM17 7C17 6.4 16.6 6 16 6H8C7.4 6 7 6.4 7 7C7 7.6 7.4 8 8 8H16C16.6 8 17 7.6 17 7Z"
                :fill="isCurrentStatusColors('Registered')[1]"
              ></path>
              <path
                d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z"
                :fill="isCurrentStatusColors('Registered')[1]"
              ></path>
            </svg>
          </span>
          <div class="step-content text-muted">
            <h4 :class="{ 'text-muted': !isCurrentStatusBool('Registered') }">
              Research registered
            </h4>
            <p
              class="step-text"
              :class="{ 'text-muted': !isCurrentStatusBool('Registered') }"
            >
              The researcher register the research for the first time and upload
              the necessary files.
            </p>
          </div>
        </div>
      </li>

      <!-- Pending for REC icon -->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark':
                !isCurrentStatusBool('Under review') &&
                !(
                  isCurrentStatusBool('With modifications') &&
                  !research.accepted_by_RSC &&
                  !research.accepted_by_REC
                ),
              'step-icon-soft-warning':
                isCurrentStatusBool('Under review') ||
                (isCurrentStatusBool('With modifications') &&
                  !research.accepted_by_RSC &&
                  !research.accepted_by_REC),
            }"
            ><span
              class="svg-icon svg-icon-sm"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=""
              data-bs-original-title="cod/cod005.svg"
            >
              <svg
                width="14"
                height="21"
                viewBox="0 0 14 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M12 6.20001V1.20001H2V6.20001C2 6.50001 2.1 6.70001 2.3 6.90001L5.6 10.2L2.3 13.5C2.1 13.7 2 13.9 2 14.2V19.2H12V14.2C12 13.9 11.9 13.7 11.7 13.5L8.4 10.2L11.7 6.90001C11.9 6.70001 12 6.50001 12 6.20001Z"
                  :fill="
                    isCurrentStatusBool('Under review')
                      ? isCurrentStatusColors('Under review')[0]
                      : !research.accepted_by_REC
                      ? isCurrentStatusColors('With modifications')[0]
                      : '#8b8b8b'
                  "
                ></path>
                <path
                  d="M13 2.20001H1C0.4 2.20001 0 1.80001 0 1.20001C0 0.600012 0.4 0.200012 1 0.200012H13C13.6 0.200012 14 0.600012 14 1.20001C14 1.80001 13.6 2.20001 13 2.20001ZM13 18.2H10V16.2L7.7 13.9C7.3 13.5 6.7 13.5 6.3 13.9L4 16.2V18.2H1C0.4 18.2 0 18.6 0 19.2C0 19.8 0.4 20.2 1 20.2H13C13.6 20.2 14 19.8 14 19.2C14 18.6 13.6 18.2 13 18.2ZM4.4 6.20001L6.3 8.10001C6.7 8.50001 7.3 8.50001 7.7 8.10001L9.6 6.20001H4.4Z"
                  :fill="
                    isCurrentStatusBool('Under review')
                      ? isCurrentStatusColors('Under review')[1]
                      : !research.accepted_by_REC
                      ? isCurrentStatusColors('With modifications')[1]
                      : '#747779'
                  "
                ></path>
              </svg>
            </span>
          </span>
          <div class="step-content">
            <h4
              :class="{
                'text-muted':
                  !isCurrentStatusBool('Under review') &&
                  !(
                    isCurrentStatusBool('With modifications') &&
                    !research.accepted_by_RSC &&
                    !research.accepted_by_REC
                  ),
              }"
            >
              Pending for ethics committee
            </h4>
            <p
              class="step-text"
              :class="{
                'text-muted':
                  !isCurrentStatusBool('Under review') &&
                  !(
                    isCurrentStatusBool('With modifications') &&
                    !research.accepted_by_RSC &&
                    !research.accepted_by_REC
                  ),
              }"
            >
              Your research has been seen by the Ethics Committee and is now
              under review for a decision.
            </p>
          </div>
        </div>
      </li>

      <!-- Pending for RSC icon -->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark':
                !isCurrentStatusBool('Pending for RSC') &&
                !(
                  isCurrentStatusBool('With modifications') &&
                  research.accepted_by_REC &&
                  !research.accepted_by_RSC
                ),
              'step-icon-soft-warning':
                isCurrentStatusBool('Pending for RSC') ||
                (isCurrentStatusBool('With modifications') &&
                  research.accepted_by_REC &&
                  !research.accepted_by_RSC),
            }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M17.9061 13H11.2061C11.2061 12.4 10.8061 12 10.2061 12C9.60605 12 9.20605 12.4 9.20605 13H6.50606L9.20605 8.40002V4C8.60605 4 8.20605 3.6 8.20605 3C8.20605 2.4 8.60605 2 9.20605 2H15.2061C15.8061 2 16.2061 2.4 16.2061 3C16.2061 3.6 15.8061 4 15.2061 4V8.40002L17.9061 13ZM13.2061 9C12.6061 9 12.2061 9.4 12.2061 10C12.2061 10.6 12.6061 11 13.2061 11C13.8061 11 14.2061 10.6 14.2061 10C14.2061 9.4 13.8061 9 13.2061 9Z"
                :fill="
                  isCurrentStatusBool('Pending for RSC')
                    ? isCurrentStatusColors('Pending for RSC')[0]
                    : research.accepted_by_REC && !research.accepted_by_RSC
                    ? isCurrentStatusColors('With modifications')[0]
                    : '#8b8b8b'
                "
              ></path>
              <path
                d="M18.9061 22H5.40605C3.60605 22 2.40606 20 3.30606 18.4L6.40605 13H9.10605C9.10605 13.6 9.50605 14 10.106 14C10.706 14 11.106 13.6 11.106 13H17.8061L20.9061 18.4C21.9061 20 20.8061 22 18.9061 22ZM14.2061 15C13.1061 15 12.2061 15.9 12.2061 17C12.2061 18.1 13.1061 19 14.2061 19C15.3061 19 16.2061 18.1 16.2061 17C16.2061 15.9 15.3061 15 14.2061 15Z"
                :fill="
                  isCurrentStatusBool('Pending for RSC')
                    ? isCurrentStatusColors('Pending for RSC')[1]
                    : research.accepted_by_REC && !research.accepted_by_RSC
                    ? isCurrentStatusColors('With modifications')[1]
                    : '#4b4b4b'
                "
              ></path>
            </svg>
          </span>
          <div class="step-content">
            <h4
              :class="{
                'text-muted':
                  !isCurrentStatusBool('Pending for RSC') &&
                  !(
                    isCurrentStatusBool('With modifications') &&
                    research.accepted_by_REC &&
                    !research.accepted_by_RSC
                  ),
              }"
            >
              Pending for scientific committee
            </h4>
            <p
              class="step-text"
              :class="{
                'text-muted':
                  !isCurrentStatusBool('Pending for RSC') &&
                  !(
                    isCurrentStatusBool('With modifications') &&
                    research.accepted_by_REC &&
                    !research.accepted_by_RSC
                  ),
              }"
            >
              the research has been accepted by the ethics committee and now is
              pending for the scientific committee
            </p>
          </div>
        </div>
      </li>

      <!-- Rejected icon -->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark': !isCurrentStatusBool('Rejected'),
              'step-icon-soft-danger': isCurrentStatusBool('Rejected'),
            }"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                :fill="isCurrentStatusBool('Rejected') ? '#692340' : '#8b8b8b'"
              ></path>
              <path
                d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                :fill="isCurrentStatusBool('Rejected') ? '#9e305e' : '#747779'"
              ></path>
            </svg>
          </span>
          <div class="step-content">
            <h4 :class="{ 'text-muted': !isCurrentStatusBool('Rejected') }">
              Rejected
            </h4>
            <p
              class="step-text"
              :class="{ 'text-muted': !isCurrentStatusBool('Rejected') }"
            >
              The research was rejected and no one can interact with it again.
            </p>
          </div>
        </div>
      </li>

      <!-- pending for contracting icon -->
      <li class="step-item" v-if="research.withFund">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark': !isCurrentStatusBool(
                'Pending for contracting'
              ),
              'step-icon-soft-warning': isCurrentStatusBool(
                'Pending for contracting'
              ),
            }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                :fill="isCurrentStatusColors('Pending for contracting')[0]"
              ></path>
              <path
                d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                :fill="isCurrentStatusColors('Pending for contracting')[1]"
              ></path>
              <path
                d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                :fill="isCurrentStatusColors('Pending for contracting')[1]"
              ></path>
            </svg>
          </span>
          <div class="step-content">
            <h4
              :class="{
                'text-muted': !isCurrentStatusBool('Pending for contracting'),
              }"
            >
              Pending for contracting
            </h4>
            <p
              class="step-text"
              :class="{
                'text-muted': !isCurrentStatusBool('Pending for contracting'),
              }"
            >
              The proposal has been accepted by the ethics and the scientific
              committee and now the research should upload his contract in order
              to make reports.
            </p>
          </div>
        </div>
      </li>

      <!-- under reporting period icon-->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark': !isCurrentStatusBool(
                'Under reporting period'
              ),
              'step-icon-soft-warning': isCurrentStatusBool(
                'Under reporting period'
              ),
            }"
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              :fill="isCurrentStatusColors('Under reporting period')[0]"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.1671 18.1421C22.4827 18.4577 23.0222 18.2331 23.0206 17.7868L23.0039 13.1053V5.52632C23.0039 4.13107 21.8729 3 20.4776 3H8.68815C7.2929 3 6.16183 4.13107 6.16183 5.52632V9H13C14.6568 9 16 10.3431 16 12V15.6316H19.6565L22.1671 18.1421Z"
                :fill="isCurrentStatusColors('Under reporting period')[0]"
              ></path>
              <path
                opacity="0.3"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1.98508 18V13C1.98508 11.8954 2.88051 11 3.98508 11H11.9851C13.0896 11 13.9851 11.8954 13.9851 13V18C13.9851 19.1046 13.0896 20 11.9851 20H4.10081L2.85695 21.1905C2.53895 21.4949 2.01123 21.2695 2.01123 20.8293V18.3243C1.99402 18.2187 1.98508 18.1104 1.98508 18ZM5.99999 14.5C5.99999 14.2239 6.22385 14 6.49999 14H11.5C11.7761 14 12 14.2239 12 14.5C12 14.7761 11.7761 15 11.5 15H6.49999C6.22385 15 5.99999 14.7761 5.99999 14.5ZM9.49999 16C9.22385 16 8.99999 16.2239 8.99999 16.5C8.99999 16.7761 9.22385 17 9.49999 17H11.5C11.7761 17 12 16.7761 12 16.5C12 16.2239 11.7761 16 11.5 16H9.49999Z"
                :fill="isCurrentStatusColors('Under reporting period')[1]"
              ></path>
            </svg>
          </span>
          <div class="step-content">
            <h4
              :class="{
                'text-muted': !isCurrentStatusBool('Under reporting period'),
              }"
            >
              Under reporting period
            </h4>
            <p
              class="step-text"
              :class="{
                'text-muted': !isCurrentStatusBool('Under reporting period'),
              }"
            >
              The researcher must upload his reports every specific period
              depends on the scientific committee determination.
            </p>
          </div>
        </div>
      </li>

      <!-- closed icon-->
      <li class="step-item">
        <div class="step-content-wrapper">
          <span
            class="step-icon"
            :class="{
              'step-icon-soft-dark': !isCurrentStatusBool('Closed'),
              'step-icon-soft-success': isCurrentStatusBool('Closed'),
            }"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M4.875 20.75C4.63542 20.75 4.39583 20.6542 4.20417 20.4625L2.2875 18.5458C1.90417 18.1625 1.90417 17.5875 2.2875 17.2042C2.67083 16.8208 3.29375 16.8208 3.62917 17.2042L4.875 18.45L8.0375 15.2875C8.42083 14.9042 8.99583 14.9042 9.37917 15.2875C9.7625 15.6708 9.7625 16.2458 9.37917 16.6292L5.54583 20.4625C5.35417 20.6542 5.11458 20.75 4.875 20.75Z"
                :fill="isCurrentStatusBool('Closed') ? '#077c76' : '#8b8b8b'"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M5 3C3.34315 3 2 4.34315 2 6V11.865C3.04486 11.3127 4.23591 11 5.5 11C9.47412 11 12.7263 14.091 12.9836 18H18.0249L20.3178 20.2929C20.9478 20.9229 22.0249 20.4767 22.0249 19.5858V15L22 15.0098L22 15V6C22 4.34315 20.6569 3 19 3H5ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44771 9 6 9H15C15.5523 9 16 8.55228 16 8C16 7.44772 15.5523 7 15 7H6Z"
                :fill="isCurrentStatusBool('Closed') ? '#066e69' : '#8b8b8b'"
              ></path>
            </svg>
          </span>
          <div class="step-content">
            <h4
              :class="{
                'text-muted': !isCurrentStatusBool('Closed'),
              }"
            >
              Closed
            </h4>
            <p
              class="step-text"
              :class="{
                'text-muted': !isCurrentStatusBool('Closed'),
              }"
            >
              The researcher have been uploaded his final version of the
              research and there's no reports to do.
            </p>
          </div>
        </div>
      </li>
    </ul>
    <!-- End Step -->
  </div>
</template>

<script>
export default {
  name: "roadMap",
  props: {
    getResearchInfo: {
      required: false,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    isCurrentStatusColors(status) {
      if (status == this.research.status.name) {
        return [this.activeStatusPrimaryColor, this.activeStatusSecondaryColor];
      } else {
        return [
          this.inactiveStatusPrimaryColor,
          this.inactiveStatusSecondaryColor,
        ];
      }
    },
    isCurrentStatusBool(status) {
      if (status == this.research.status.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  data() {
    return {
      activeStatusPrimaryColor: "#f4d0aa",
      activeStatusSecondaryColor: "#d8b085",
      inactiveStatusPrimaryColor: "#8b8b8b",
      inactiveStatusSecondaryColor: "#747779",
    };
  },
};
</script>
