<template>
  <!-- modal button -->
  <a
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    href="#recAcceptationModal"
    role="button"
    id="recAcceptationModalBtn"
  ></a>
  <div
    class="modal fade"
    id="recAcceptationModal"
    aria-hidden="true"
    aria-labelledby="recAcceptationModal"
    tabindex="-1"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title h4" id="recAcceptationModal">
            Review the ethical clearance certificate.
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            id="closeRecAcceptationModal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <!-- acceptation instructions -->
          <ul>
            <li>
              Please review the ethical clearance certificate in order to accept
              the proposal.
            </li>
            <li>
              this certificate will be send to the research creator via email so
              it's not necessary to give it to him.
            </li>
            <li>
              also we will notify the scientific committee members that now the
              proposal is pending to them.
            </li>
          </ul>
          <!-- Certificate Reference Template -->
          <ethicalClearanceCertificate
            :research="researchInfo"
            :RecCertificateNum="RecCertificateNum"
          />
        </div>
        <div class="modal-footer">
          <div
            v-if="message != null"
            class="alert w-100 m-3"
            :class="messageClass"
            role="alert"
          >
            <b>{{ message }}</b>
          </div>
          <button
            v-if="!in_submission"
            @click="AcceptTheProposal"
            type="button"
            class="btn btn-primary float-start"
          >
            Accept the proposal
          </button>
          <div
            class="spinner-border m-5 text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-white float-start"
            data-bs-dismiss="modal"
          >
            Return back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import ethicalClearanceCertificate from "@/components/research/ethicalClearanceCertificate";

export default {
  name: "recAccepationModal",
  components: { ethicalClearanceCertificate },
  props: {
    research: {
      type: Object,
      required: true,
    },
    getResearchInfo: {
      type: Function,
      required: true,
    },
  },
  computed: {
    researchInfo() {
      return this.research;
    },
  },
  data() {
    return {
      in_submission: null,
      message: null,
      messageClass: "alert-soft-danger",
      RecCertificateNum: "",
    };
  },
  methods: {
    async getRecCertificateNum() {
      await axiosConfig
        .get(`api/getRecCertificateNum/${this.researchInfo.id}`)
        .then((res) => {
          console.log(res);
          this.RecCertificateNum = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
    async AcceptTheProposal() {
      this.in_submission = true;
      await axiosConfig
        .get(`api/RECAcceptation/${this.researchInfo.id}`)
        .then((res) => {
          this.messageClass = "alert-soft-success";
          this.message =
            "The proposal was successfully accepted, we notified both the researcher and the scientific committee members about this action.";
          console.log(res);
          this.getResearchInfo();
          setTimeout(() => {
            document.getElementById("closeRecAcceptationModal").click();
          }, 4000);
        })
        .catch((err) => {
          this.messageClass = "alert-soft-danger";
          this.message = err.response.data.message;
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
  created() {
    this.getRecCertificateNum();
  },
};
</script>
