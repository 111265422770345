<template>
  <RRSheader />
  <page-title>
    <template v-slot:titlePrefix>Users </template>
    <template v-slot:mainTitle>List </template>
  </page-title>
  <!-- Form Filters -->
  <div class="my-3 container">
    <form>
      <div class="row gx-2 gx-md-3">
        <div class="col-sm-8 col-md-8 mb-2 mb-md-0">
          <!-- Form for searching -->
          <div class="input-group input-group-merge">
            <span class="input-group-prepend input-group-text">
              <span
                class="svg-icon svg-icon-sm text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Search for a research"
                data-bs-original-title="gen/gen004.svg"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                    fill="#3182ce"
                  ></path>
                  <path
                    opacity="0.3"
                    d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                    fill="#7abeef"
                  ></path>
                </svg>
              </span>
            </span>
            <input
              type="text"
              class="form-control"
              id="Searchforaresearch"
              placeholder="Search for a research"
              aria-label="Search for a research"
              v-model="searchableText"
            />
          </div>
          <!-- End Form -->
        </div>
        <!-- End Col -->

        <div class="col-sm-4 col-md-4 mb-2 mb-sm-0">
          <div class="input-group input-group-merge">
            <div class="input-group-prepend input-group-text">
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.3"
                  d="M8.9 21L7.19999 22.6999C6.79999 23.0999 6.2 23.0999 5.8 22.6999L4.1 21H8.9ZM4 16.0999L2.3 17.8C1.9 18.2 1.9 18.7999 2.3 19.1999L4 20.9V16.0999ZM19.3 9.1999L15.8 5.6999C15.4 5.2999 14.8 5.2999 14.4 5.6999L9 11.0999V21L19.3 10.6999C19.7 10.2999 19.7 9.5999 19.3 9.1999Z"
                  fill="#3182ce"
                ></path>
                <path
                  d="M21 15V20C21 20.6 20.6 21 20 21H11.8L18.8 14H20C20.6 14 21 14.4 21 15ZM10 21V4C10 3.4 9.6 3 9 3H4C3.4 3 3 3.4 3 4V21C3 21.6 3.4 22 4 22H9C9.6 22 10 21.6 10 21ZM7.5 18.5C7.5 19.1 7.1 19.5 6.5 19.5C5.9 19.5 5.5 19.1 5.5 18.5C5.5 17.9 5.9 17.5 6.5 17.5C7.1 17.5 7.5 17.9 7.5 18.5Z"
                  fill="#3182ce"
                ></path>
              </svg>
            </div>
            <!-- Select a faculty -->
            <select
              class="form-select"
              id="faculty"
              aria-label="Select faculty"
              v-model="searchableFaculty"
              :disabled="in_submission"
            >
              <option value="%" selected>all faculties</option>
              <option
                v-for="faculty in faculties"
                :key="faculty.id"
                :value="faculty.id"
              >
                {{ faculty.name }}
              </option>
            </select>
            <!-- End Select -->
          </div>
        </div>
        <!-- End Col -->
      </div>
      <!-- End Row -->
      <div class="row gx-2 gx-md-3 mt-3">
        <div class="col-sm-4 col-md-4">
          <!-- Form for categories -->
          <div class="input-group input-group-merge">
            <span class="input-group-prepend input-group-text">
              <span
                class="svg-icon svg-icon-sm text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Search for a research"
                data-bs-original-title="gen/gen004.svg"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M18.4 5.59998C18.7766 5.9772 18.9881 6.48846 18.9881 7.02148C18.9881 7.55451 18.7766 8.06577 18.4 8.44299L14.843 12C14.466 12.377 13.9547 12.5887 13.4215 12.5887C12.8883 12.5887 12.377 12.377 12 12C11.623 11.623 11.4112 11.1117 11.4112 10.5785C11.4112 10.0453 11.623 9.53399 12 9.15698L15.553 5.604C15.9302 5.22741 16.4415 5.01587 16.9745 5.01587C17.5075 5.01587 18.0188 5.22741 18.396 5.604L18.4 5.59998ZM20.528 3.47205C20.0614 3.00535 19.5074 2.63503 18.8977 2.38245C18.288 2.12987 17.6344 1.99988 16.9745 1.99988C16.3145 1.99988 15.661 2.12987 15.0513 2.38245C14.4416 2.63503 13.8876 3.00535 13.421 3.47205L9.86801 7.02502C9.40136 7.49168 9.03118 8.04568 8.77863 8.6554C8.52608 9.26511 8.39609 9.91855 8.39609 10.5785C8.39609 11.2384 8.52608 11.8919 8.77863 12.5016C9.03118 13.1113 9.40136 13.6653 9.86801 14.132C10.3347 14.5986 10.8886 14.9688 11.4984 15.2213C12.1081 15.4739 12.7616 15.6039 13.4215 15.6039C14.0815 15.6039 14.7349 15.4739 15.3446 15.2213C15.9543 14.9688 16.5084 14.5986 16.975 14.132L20.528 10.579C20.9947 10.1124 21.3649 9.55844 21.6175 8.94873C21.8701 8.33902 22.0001 7.68547 22.0001 7.02551C22.0001 6.36555 21.8701 5.71201 21.6175 5.10229C21.3649 4.49258 20.9947 3.93867 20.528 3.47205V3.47205Z"
                    fill="#3182ce"
                  ></path>
                  <path
                    d="M14.132 9.86804C13.6421 9.37931 13.0561 8.99749 12.411 8.74695L12 9.15698C11.6234 9.53421 11.4119 10.0455 11.4119 10.5785C11.4119 11.1115 11.6234 11.6228 12 12C12.3766 12.3772 12.5881 12.8885 12.5881 13.4215C12.5881 13.9545 12.3766 14.4658 12 14.843L8.44699 18.396C8.06999 18.773 7.55868 18.9849 7.02551 18.9849C6.49235 18.9849 5.98101 18.773 5.604 18.396C5.227 18.019 5.0152 17.5077 5.0152 16.9745C5.0152 16.4413 5.227 15.93 5.604 15.553L8.74701 12.411C8.28705 11.233 8.28705 9.92498 8.74701 8.74695C8.10159 8.99737 7.5152 9.37919 7.02499 9.86804L3.47198 13.421C2.52954 14.3635 2.00009 15.6417 2.00009 16.9745C2.00009 18.3073 2.52957 19.5855 3.47202 20.528C4.41446 21.4704 5.69269 21.9999 7.02551 21.9999C8.35833 21.9999 9.63656 21.4704 10.579 20.528L14.132 16.975C14.5987 16.5084 14.9689 15.9544 15.2215 15.3447C15.4741 14.735 15.6041 14.0815 15.6041 13.4215C15.6041 12.7615 15.4741 12.108 15.2215 11.4983C14.9689 10.8886 14.5987 10.3347 14.132 9.86804V9.86804Z"
                    fill="#2670b6"
                  ></path>
                </svg>
              </span>
            </span>

            <!-- Select a userCategories-->
            <select
              class="form-select"
              id="category"
              aria-label="Select category"
              v-model="searchableCategory"
              :disabled="in_submission"
            >
              <option value="%" selected>all categories</option>
              <option
                v-for="category in userCategories"
                :key="category.id"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </select>
            <!-- End Select -->
          </div>
          <!-- End Form -->
        </div>
        <!-- End Col -->
        <div class="col-sm-4 col-md-4">
          <!-- Form for user roles -->
          <div class="input-group input-group-merge">
            <span class="input-group-prepend input-group-text">
              <span
                class="svg-icon svg-icon-sm text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Search for a research"
                data-bs-original-title="gen/gen004.svg"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M18 22C19.7 22 21 20.7 21 19C21 18.5 20.9 18.1 20.7 17.7L15.3 6.30005C15.1 5.90005 15 5.5 15 5C15 3.3 16.3 2 18 2H6C4.3 2 3 3.3 3 5C3 5.5 3.1 5.90005 3.3 6.30005L8.7 17.7C8.9 18.1 9 18.5 9 19C9 20.7 7.7 22 6 22H18Z"
                    fill="#3182ce"
                  ></path>
                  <path
                    d="M18 2C19.7 2 21 3.3 21 5H9C9 3.3 7.7 2 6 2H18Z"
                    fill="#035A4B"
                  ></path>
                  <path
                    d="M9 19C9 20.7 7.7 22 6 22C4.3 22 3 20.7 3 19H9Z"
                    fill="#2670b6"
                  ></path>
                </svg>
              </span>
            </span>

            <!-- Select a role-->
            <select
              class="form-select"
              id="roles"
              aria-label="Select role"
              v-model="searchableRole"
              :disabled="in_submission"
            >
              <option value="%" selected>all roles</option>
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
            <!-- End Select -->
          </div>
          <!-- End Form -->
        </div>
        <!-- End Col -->
        <div class="col-sm-4 col-md-4">
          <!-- Form for user status -->
          <div class="input-group input-group-merge">
            <span class="input-group-prepend input-group-text">
              <span
                class="svg-icon svg-icon-sm text-primary"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="user status"
                data-bs-original-title="gen/gen004.svg"
                ><svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z"
                    fill="#3182ce"
                  ></path>
                  <path
                    opacity="0.3"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z"
                    fill="#2670b6"
                  ></path>
                </svg>
              </span>
            </span>

            <!-- Select a status-->
            <select
              class="form-select"
              id="statuses"
              aria-label="Select status"
              v-model="searchableStatus"
              :disabled="in_submission"
            >
              <option value="%" selected>all user statuses</option>
              <option value="0">inactive users</option>
              <option value="1">active users</option>
            </select>
            <!-- End Select -->
          </div>
          <!-- End Form -->
        </div>
        <!-- End Col -->
      </div>
    </form>
  </div>
  <!-- loading -->
  <div
    class="d-flex justify-content-center text-primary m-5"
    v-if="usersList.length == 0 && in_submission"
  >
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div class="container-fluid" v-if="usersList.length > 0">
    <div class="row justify-content-center">
      <div class="col mx-5 mb-5 p-4 border border-1 rounded shadow">
        <!-- Table -->
        <table class="table table-sm table-hover table-thead-bordered">
          <thead class="thead-light fw-bold">
            <tr class="fw-bold">
              <th scope="col" class="fw-bold">#</th>
              <th scope="col" class="fw-bold">Full name</th>
              <th scope="col" class="fw-bold">Email</th>
              <th scope="col" class="fw-bold">Phone</th>
              <th scope="col" class="fw-bold">Native role</th>
              <th scope="col" class="fw-bold">Status</th>
              <th scope="col" class="fw-bold">Faculty</th>
              <th scope="col" class="fw-bold">Options</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in usersList" :key="user.id" class="text-muted">
              <th class="fw-bold">{{ user.id }}</th>
              <td>{{ user.full_name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.phone || "don't have a phone number" }}</td>
              <td>
                {{
                  user.roles.map((val) => {
                    return val.name;
                  })[0]
                }}
              </td>
              <td>
                <span
                  class="badge"
                  :class="{
                    'bg-success': user.is_active,
                    'bg-danger': !user.is_active,
                  }"
                  >{{ user.is_active ? "active" : "inactive" }}</span
                >
              </td>
              <td>{{ user.faculty.name }}</td>
              <td>
                <!-- edit user option -->
                <a
                  title="edit user"
                  @click="
                    $router.push({
                      name: 'add-new-member',
                      params: { id: user.id },
                    })
                  "
                  class="btn btn-sm btn-soft-primary rounded-pill border border-primary mx-2 fw-bold rounded-pill"
                >
                  <svg
                    class="me-1"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M21.4 8.35303L19.241 10.511L13.485 4.755L15.643 2.59595C16.0248 2.21423 16.5426 1.99988 17.0825 1.99988C17.6224 1.99988 18.1402 2.21423 18.522 2.59595L21.4 5.474C21.7817 5.85581 21.9962 6.37355 21.9962 6.91345C21.9962 7.45335 21.7817 7.97122 21.4 8.35303V8.35303ZM3.68699 21.932L9.88699 19.865L4.13099 14.109L2.06399 20.309C1.98815 20.5354 1.97703 20.7787 2.03189 21.0111C2.08674 21.2436 2.2054 21.4561 2.37449 21.6248C2.54359 21.7934 2.75641 21.9115 2.989 21.9658C3.22158 22.0201 3.4647 22.0084 3.69099 21.932H3.68699Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M5.574 21.3L3.692 21.928C3.46591 22.0032 3.22334 22.0141 2.99144 21.9594C2.75953 21.9046 2.54744 21.7864 2.3789 21.6179C2.21036 21.4495 2.09202 21.2375 2.03711 21.0056C1.9822 20.7737 1.99289 20.5312 2.06799 20.3051L2.696 18.422L5.574 21.3ZM4.13499 14.105L9.891 19.861L19.245 10.507L13.489 4.75098L4.13499 14.105Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                </a>
                <!-- deactivate activate user option -->
                <button
                  :title="user.is_active ? `deactivate user` : `activate user`"
                  class="btn btn-sm rounded-pill border mx-1 fw-bold rounded-pill"
                  :class="{
                    'btn-soft-success border-success': !user.is_active,
                    'btn-soft-danger border-danger': user.is_active,
                  }"
                  @click="changeCurrentUserData(user.id, user.is_active)"
                >
                  <!-- if the user active -->
                  <svg
                    v-if="user.is_active"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                      fill="#4c1a2f"
                    ></path>
                    <path
                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                      fill="#4c1a2f"
                    ></path>
                  </svg>
                  <!-- if the user is not active -->
                  <svg
                    v-else
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M10.3 14.3L11 13.6L7.70002 10.3C7.30002 9.9 6.7 9.9 6.3 10.3C5.9 10.7 5.9 11.3 6.3 11.7L10.3 15.7C9.9 15.3 9.9 14.7 10.3 14.3Z"
                      fill="#035A4B"
                    ></path>
                    <path
                      d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM11.7 15.7L17.7 9.70001C18.1 9.30001 18.1 8.69999 17.7 8.29999C17.3 7.89999 16.7 7.89999 16.3 8.29999L11 13.6L7.70001 10.3C7.30001 9.89999 6.69999 9.89999 6.29999 10.3C5.89999 10.7 5.89999 11.3 6.29999 11.7L10.3 15.7C10.5 15.9 10.8 16 11 16C11.2 16 11.5 15.9 11.7 15.7Z"
                      fill="#035A4B"
                    ></path>
                  </svg>
                </button>
                <!-- adding roles options -->
              </td>
            </tr>
          </tbody>
        </table>
        <!-- Pagination -->
        <pagination
          :links="usersLinks"
          :perPage="usersPerPage"
          :total="totalUsers"
          :fetchNext="fetchUsers"
          :inSubmission="in_submission"
        />
        <!-- End Table -->
      </div>
    </div>
  </div>
  <!-- no results found -->
  <div
    class="row justify-content-center"
    v-if="usersList.length == 0 && !in_submission"
  >
    <div class="col-auto text-center mt-5 pt-5">
      <img
        src="/img/oc-looking-for-answers.jpg"
        class="w-25"
        alt="no researches"
      />
      <p class="text-muted fw-bold fs-5 pt-5 text-center">
        There's no results has been found.
      </p>
    </div>
  </div>
  <!-- toggle activation modal -->
  <toggleActivation
    :userId="currentUserId"
    :isActive="currentUserActive"
    :fetchUsers="fetchUsers"
  />
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "@/components/RRSheader.vue";
import pagination from "@/components/pagination";
import toggleActivation from "@/components/users/toggleActivation.vue";
import { hasRole } from "@/includes/helpers.js";
// import { checkPasswordStrenth } from "@/includes/helpers.js";
// import { mapGetters } from "vuex";
import axiosConfig from "@/includes/axiosConfig.js";
import store from "@/store/index.js";
import { mapGetters } from "vuex";

export default {
  name: "usersList.vue",
  components: { RRSheader, pageTitle, pagination, toggleActivation },
  computed: {
    ...mapGetters({
      user: "user",
      faculties: "faculties",
      roles: "roles",
      userCategories: "userCategories",
    }),
  },
  data() {
    return {
      usersList: [],
      in_submission: false,
      usersLinks: [],
      totalUsers: 0,
      usersPerPage: 0,
      searchableText: "",
      searchableFaculty: "%",
      searchableCategory: "%",
      searchableRole: "%",
      searchableStatus: "%",
      currentUserId: null,
      currentUserActive: null,
    };
  },
  methods: {
    async fetchUsers({ page = 1 }) {
      this.in_submission = true;
      let vals = {};
      vals.searchableText = this.searchableText;
      vals.searchableFaculty = this.searchableFaculty;
      vals.searchableCategory = this.searchableCategory;
      vals.searchableRole = this.searchableRole;
      vals.searchableStatus = this.searchableStatus;
      await axiosConfig
        .post(`api/fetchUsers?page=${page}`, vals)
        .then((res) => {
          console.log(res);
          this.usersList = res.data.data;
          this.usersLinks = res.data.links;
          this.totalUsers = res.data.total;
          this.usersPerPage = res.data.per_page;
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    changeCurrentUserData(userId, isActive) {
      this.currentUserId = userId;
      this.currentUserActive = isActive;
      document.getElementById("toggleActivationModalBtn").click();
    },
  },
  created() {
    this.fetchUsers({});
  },
  watch: {
    searchableText(after) {
      !this.in_submission || after.length == 0 ? this.fetchUsers({}) : "";
    },
    searchableFaculty() {
      !this.in_submission ? this.fetchUsers({}) : "";
    },
    searchableCategory() {
      !this.in_submission ? this.fetchUsers({}) : "";
    },
    searchableStatus() {
      !this.in_submission ? this.fetchUsers() : "";
    },
    searchableRole() {
      !this.in_submission ? this.fetchUsers({}) : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (!hasRole(["admin"])) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style></style>
