<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#uploadContractModal"
    id="uploadContractBtn"
  ></button>
  <!-- End Button trigger modal -->

  <!-- Modal -->
  <div
    id="uploadContractModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadContractModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadContractModalTitle">
            Upload your contract
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeRejectProposalModal"
          ></button>
        </div>
        <div class="modal-body">
          <!-- title -->
          <div class="mb-3">
            <small class="d-block mb-3"
              ><span class="text-muted"
                >note<span class="text-danger">*: </span></span
              >if you have uploaded your contract before, this upload will
              overwrite the previous upload.</small
            >
            <!-- uplaod contract -->
            <div class="row">
              <div class="col-md-2 col-sm-12 col-form-label">
                <label for="" class="d-none d-md-block d-lg-block"
                  >drop your contract</label
                >
              </div>
              <div class="col-md-8 col-sm-12 col-8">
                <div class="area d-none d-md-block d-lg-block">
                  <div
                    :class="{ 'dragOver-dropZone': isDragOver }"
                    id="dropZone"
                    @drag.prevent.stop=""
                    @dragstart.prevent.stop=""
                    @dragend.prevent.stop="isDragOver = false"
                    @dragover.prevent.stop="isDragOver = true"
                    @dragenter.prevent.stop="isDragOver = true"
                    @dragleave.prevent.stop="isDragOver = false"
                    @drop.prevent.stop="attach($event, attachedFiles)"
                    @click="attachContractManualy"
                  >
                    Drop the contract here
                  </div>
                </div>
              </div>
            </div>
            <!-- Form for uplaoding attach manualy only on sm and xs screen-->
            <div class="row my-3 d-block d-xl-none d-lg-none d-md-none">
              <label for="uploadContractSM" class="col-sm col-form-label"
                >drop your contract</label
              >
              <div class="col-sm-9">
                <input
                  type="file"
                  class="form-control"
                  id="uploadContractSM"
                  name="uploadContractSM"
                  placeholder=""
                  aria-label="example@site.com"
                  @change="attach($event, attachedFiles)"
                />
              </div>
            </div>
            <!-- uplaod contract ends here -->
            <!-- attached file list -->
            <div v-if="contract != null">
              <b>Your uploaded contract:</b>
              <ul class="text-primary">
                <li>
                  {{ contract.name }}
                  <!-- remove file button -->
                  <a class="cursor-p mx-2" @click="contract = null">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                        fill="#692340"
                      ></path>
                      <path
                        d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                        fill="#692340"
                      ></path>
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
            <!-- end attached file list -->
          </div>
        </div>
        <div class="modal-footer">
          <p
            class="fw-bold d-block w-100 mb-2"
            :class="messageClass"
            v-if="message != null"
          >
            {{ message }}
          </p>
          <button
            v-if="!in_submission"
            type="button"
            class="btn btn-primary-dark"
            @click="uploadContract"
          >
            Uplaod
          </button>
          <div
            class="spinner-border m-5 text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-white"
            data-bs-dismiss="modal"
            id="closeContractBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "uploadContract",
  props: {
    researchId: {
      required: true,
      type: Number,
    },
    getResearchInfo: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      contract: null,
      isDragOver: false,
      acceptedType: [],
      in_submission: false,
      message: null,
      messageClass: "",
    };
  },
  methods: {
    attach($event) {
      this.contract = null;
      this.isDragOver = false;
      const files = $event.dataTransfer
        ? [...$event.dataTransfer.files]
        : [...$event.target.files];
      if (
        files.length == 1 &&
        this.acceptedType.includes(files[0].type) &&
        files[0].size / 1000 / 1000 <= 3
      ) {
        this.contract = files[0];
      } else {
        this.messageClass = "text-danger";
        this.message =
          "please make sure that you have uploaded 1 file that has PDF or word document extensions and the maximum size is 1 MB";
        setTimeout(() => {
          this.message = null;
        }, 6000);
      }
      console.log(this.contract);
    },
    attachContractManualy() {
      document.getElementById("uploadContractSM").click();
    },
    async uploadContract() {
      this.in_submission = true;
      if (this.contract != null) {
        let data = new FormData();
        data.append("contract", this.contract);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        };
        await axiosConfig
          .post(`api/uploadContract/${this.researchId}`, data, config)
          .then((res) => {
            this.contract = null;
            this.messageClass = "text-success";
            this.message =
              "your contract has been uploaded successfully, please wait for the administrator to review it.";
            setTimeout(() => {
              document.getElementById("closeContractBtn").click();
              this.getResearchInfo();
            }, 2000);
            console.log(res);
          })
          .catch((err) => {
            this.messageClass = "text-danger";
            this.message = "An error has been occured please try again lates.";
            console.log(err.response);
          });
      } else {
        this.messageClass = "text-danger";
        this.message =
          "please make sure that you have uploaded 1 file that has PDF or word document extensions and the maximum size is 1 MB";
      }
      setTimeout(() => {
        this.message = null;
      }, 2000);
      this.in_submission = false;
    },
  },
  created() {
    this.acceptedType = [
      "application/pdf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
      "application/msword",
    ];
  },
};
</script>

<style></style>
