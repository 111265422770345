<template>
  <!-- Content -->
  <div class="bg-primary">
    <div class="container content-space-1 content-space-t-md-3">
      <div class="mx-auto" style="max-width: 30rem">
        <!-- Card -->
        <div class="card card-lg zi-2 border shadow">
          <!-- Header -->
          <div class="card-header text-center">
            <h4 class="card-title">Forgot password?</h4>
            <p class="card-text">
              Enter the email address you used when you joined and we'll send
              you instructions to reset your password.
            </p>
          </div>
          <!-- End Header -->

          <!-- Card Body -->
          <div class="card-body">
            <vee-form
              :validationSchema="resetPasswordForm"
              @submit="resetPassword"
            >
              <!-- Form -->
              <div class="mb-4">
                <label class="form-label" for="forgotPasswordFormEmail"
                  >Your email</label
                >
                <vee-field
                  type="email"
                  class="form-control"
                  name="email"
                  id="email"
                  placeholder="Enter your emaill address"
                  aria-label="Enter your emaill address"
                />
                <error-message name="email" class="text-danger" />
              </div>
              <!-- End Form -->

              <div class="d-grid mb-4" v-if="!in_submission">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg"
                  name="submit"
                  :disabled="in_Submission"
                >
                  Submit
                </button>
              </div>

              <!-- spinners -->
              <div
                class="d-flex justify-content-center my-2"
                v-if="in_submission"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
              <!-- alert success message -->
              <div
                v-if="success != null && !in_Submission"
                class="alert alert-success d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ success }}</div>
              </div>
              <!-- alert error message -->
              <div
                v-if="errors != null && !in_Submission"
                class="alert alert-danger d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ errors }}</div>
              </div>

              <div class="text-center">
                <a class="btn btn-link" @click="$router.push({ name: 'Home' })">
                  Back to log in
                </a>
              </div>
            </vee-form>
          </div>
          <!-- End Card Body -->
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
  <!-- End Content -->

  <!-- Shape -->
  <div class="shape-container">
    <div class="shape shape-bottom zi-1">
      <svg
        viewBox="0 0 3000 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 600V350.234L3000 0V600H0Z" fill="#fff" />
      </svg>
    </div>
  </div>
  <!-- End Shape -->
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "resetPassword",
  data() {
    return {
      resetPasswordForm: {
        email: "required|email",
      },
      in_submission: false,
      errors: null,
      success: null,
    };
  },
  methods: {
    async resetPassword(val) {
      console.log(val);
      this.in_submission = true;
      await axiosConfig
        .post("/forgot-password", val)
        .then((res) => {
          console.log(res);
          this.success = res.data.status;
        })
        .catch((err) => {
          console.log(err.response);
          this.errors = this.errors = err.response
            ? Object.values(err.response.data.errors)[0][0]
            : "please try again later";
        });
      this.in_submission = false;
      setTimeout(() => {
        this.errors = null;
        this.success = null;
      }, 2000);
    },
  },
};
</script>

<style></style>
