<template>
  <RRSheader />
  <page-title :refresh="getResearchInfo" :in_submission="loadingResearch">
    <template v-slot:titlePrefix>Research Portal </template>
    <template v-slot:mainTitle>Manuals </template>
  </page-title>

  <!-- Blog -->
  <div class="container content-space-1">
    <!-- Heading -->
    <div class="w-lg-65 text-center mx-lg-auto mb-7">
      <h3>Useful Resources</h3>
      <p class="fs-6">
        Here you can find the useful resources of the LIMU research portal to
        insure that the user takes the best full experience.
      </p>
    </div>
    <!-- End Heading -->

    <div class="row mb-5 mb-sm-5">
      <!-- researcher manual -->
      <div
        class="col-sm-6 col-lg-4 mb-3 mb-lg-5"
        v-if="
          hasRole([
            'researcher',
            'admin',
            'REC-admin',
            'RSC-admin',
            'REC-member',
            'RSC-member',
          ])
        "
      >
        <!-- Card -->
        <div class="card h-100 p-2 shadow">
          <img
            class="card-img"
            src="img/research_portal_manual.PNG"
            alt="Image Description"
          />
          <div class="card-body">
            <h5 class="card-title">Research Portal Researcher Manual v1.0</h5>
            <p class="card-text">
              The complete guide for the researcher to how use the research
              portal system
            </p>
            <a
              class="card-link cursor-p"
              @click="
                downloadFile(
                  'resources/ResearchPortal_ResearcherManual-v1.0.pdf'
                )
              "
              >Downlaod Now<i class="bi-chevron-right small ms-1"></i
            ></a>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Col -->
      <!-- REC committee manual-->
      <div
        class="col-sm-6 col-lg-4 mb-3 mb-lg-5"
        v-if="hasRole(['admin', 'REC-admin'])"
      >
        <!-- Card -->
        <div class="card h-100 p-2 shadow">
          <img
            class="card-img"
            src="img/research_portal_REC_manual.PNG"
            alt="Image Description"
          />
          <div class="card-body">
            <h5 class="card-title">
              Research Portal Ethics Committee Manual v1.0
            </h5>
            <p class="card-text">
              The complete guide for the ethical committee admin/memebrs to how
              use the research portal system.
            </p>
            <a
              class="card-link cursor-p"
              @click="
                downloadFile('resources/Research_portal_REC_manual-v1.0.pdf')
              "
              >Downlaod Now<i class="bi-chevron-right small ms-1"></i
            ></a>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Col -->
      <!-- RSC committee manual-->
      <div
        class="col-sm-6 col-lg-4 mb-3 mb-lg-5"
        v-if="hasRole(['admin', 'RSC-admin'])"
      >
        <!-- Card -->
        <div class="card h-100 p-2 shadow">
          <img
            class="card-img"
            src="img/research_portal_RSC_manual.PNG"
            alt="Image Description"
          />
          <div class="card-body">
            <h5 class="card-title">
              Research Portal Scientific Committee Manual v1.0
            </h5>
            <p class="card-text">
              The complete guide for the scientific committee to how use the
              research portal system.
            </p>
            <a
              class="card-link cursor-p"
              @click="
                downloadFile('resources/Research_portal_RSC_manual-v1.0.pdf')
              "
              >Downlaod Now<i class="bi-chevron-right small ms-1"></i
            ></a>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Col -->
      <!-- Super admin manual-->
      <div class="col-sm-6 col-lg-4 mb-3 mb-lg-5" v-if="hasRole(['admin'])">
        <!-- Card -->
        <div class="card h-100 p-2 shadow">
          <img
            class="card-img"
            src="img/research_portal_admin_manual.PNG"
            alt="Image Description"
          />
          <div class="card-body">
            <h5 class="card-title">Research Portal Admin Manual v1.0</h5>
            <p class="card-text">
              The complete guide for the super admin to how use the research
              portal system
            </p>
            <a
              class="card-link cursor-p"
              @click="
                downloadFile(
                  'resources/Research_portal_super_admin_manual-v1.0.pdf'
                )
              "
              >Downlaod Now<i class="bi-chevron-right small ms-1"></i
            ></a>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Col -->

      <!-- Research priorities -->
      <div
        class="col-sm-6 col-lg-4 mb-3 mb-lg-5"
        v-if="
          hasRole([
            'researcher',
            'admin',
            'REC-admin',
            'RSC-admin',
            'REC-member',
            'RSC-member',
          ])
        "
      >
        <!-- Card -->
        <div class="card h-100 p-2 shadow">
          <img
            class="card-img"
            src="img/Scientific_research_priorities.PNG"
            alt="Image Description"
          />
          <div class="card-body">
            <h5 class="card-title">Research priorities</h5>
            <p class="card-text">
              How do you determine the priority of research?
            </p>
            <a
              class="card-link cursor-p"
              @click="
                downloadFile('resources/Scientific_research_priorities.pdf')
              "
              >Downlaod Now<i class="bi-chevron-right small ms-1"></i
            ></a>
          </div>
        </div>
        <!-- End Card -->
      </div>
      <!-- End Col -->
    </div>
    <!-- End Row -->
  </div>
  <!-- End Blog -->
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "@/components/RRSheader.vue";
import store from "@/store";
import { downloadFile } from "@/includes/helpers.js";
import { hasRole } from "@/includes/helpers.js";

export default {
  name: "manuals",
  components: {
    pageTitle,
    RRSheader,
  },
  methods: {
    downloadFile,
    hasRole,
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>
