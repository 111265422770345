import store from "@/store";
import axiosConfig from "@/includes/axiosConfig.js";

function checkPasswordStrenth(password) {
  var regex = new RegExp(
    "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$"
  );
  return regex.test(password);
}

function hasRole(roles) {
  let hasTheRole = false;
  roles.forEach(function (role) {
    if (store.state.user.currentRole == role) {
      hasTheRole = true;
    }
  });
  return hasTheRole;
}

async function downloadFile(storagePath) {
  await axiosConfig
    .post(
      `api/downloadFile`,
      { storagePath: storagePath },
      { responseType: "arraybuffer" }
    )
    .then((res) => {
      console.log(res);
      let blob = new Blob([res.data], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = storagePath.split("/")[storagePath.split("/").length - 1];
      link.click();
      setTimeout(function () {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blob);
      }, 100);
    })
    .catch((err) => {
      console.log(err.response);
    });
}

function pushInArray(array, val) {
  !array.includes(val) ? array.push(val) : "";
}

function popFromArray(array, val) {
  let index = array.indexOf(val);
  array.splice(index, 1);
}

export {
  checkPasswordStrenth,
  hasRole,
  downloadFile,
  pushInArray,
  popFromArray,
};
