<template>
  <div>
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="research == null && loadingResearch"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- modifications list -->
    <div class="conatiner" v-if="modifications.length > 0">
      <!-- modifications column -->
      <div class="row">
        <small class="d-block fs-7 my-2"
          ><span class="text-muted"
            >Note<span class="text-danger">*: </span></span
          >if there is any required modifications by the (REC or RSC)
          committees, Go to the Research Details section --> Edit --> Do the
          required modifications, Re-upload the files if required .. Finally,
          Save the updates</small
        >
        <div
          class="modificationsList-container col-lg-6 col-md-12 col-sm-12"
          v-for="modification in modifications"
          :key="modification.id"
        >
          <ol class="p-0 px-2">
            <li
              class="border border-1 shadow rounded p-3 position-relative my-3"
            >
              <!-- modification card -->
              <blockquote class="default rounded shadow w-100">
                <b
                  >modification type:<span
                    v-if="modification.made_by_role == 'REC-admin'"
                    class="badge bg-soft-danger text-danger mx-2 fw-bold"
                    >Ethically modification</span
                  >
                  <span
                    v-else-if="modification.made_by_role == 'RSC-admin'"
                    class="badge bg-soft-success text-success mx-2"
                    >Scientifically modification</span
                  >
                </b>
                <!-- modification review box -->
                <div class="my-2">
                  <a class="cursor-p" @click="fullModification(modification)">
                    <span class="badge bg-soft-primary text-primary mx-2">
                      <a>View modification</a>
                    </span>
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.3"
                        d="M17 6H3C2.4 6 2 6.4 2 7V21C2 21.6 2.4 22 3 22H17C17.6 22 18 21.6 18 21V7C18 6.4 17.6 6 17 6Z"
                        fill="#8b8b8b"
                      ></path>
                      <path
                        d="M17.8 4.79999L9.3 13.3C8.9 13.7 8.9 14.3 9.3 14.7C9.5 14.9 9.80001 15 10 15C10.2 15 10.5 14.9 10.7 14.7L19.2 6.20001L17.8 4.79999Z"
                        fill="#4b4b4b"
                      ></path>
                      <path
                        opacity="0.3"
                        d="M22 9.09998V3C22 2.4 21.6 2 21 2H14.9L22 9.09998Z"
                        fill="#4b4b4b"
                      ></path>
                    </svg>
                  </a>
                </div>
              </blockquote>
              <!-- meta data (by user & date) -->
              <div class="d-flex flex-row justify-content-between">
                <small class="float-start">
                  <div class="step-avatar" v-if="modification.creator != null">
                    <img
                      class="step-avatar-img"
                      style="width: 8% !important"
                      :src="
                        modification.creator.profile_img_path ||
                        '/img/default_profile_image.jpg'
                      "
                      alt="Image Description"
                    />
                    <b class="px-2">{{ modification.creator.full_name }}</b>
                  </div>
                </small>
                <small class="float-end">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.3"
                      d="M20.9 12.9C20.3 12.9 19.9 12.5 19.9 11.9C19.9 11.3 20.3 10.9 20.9 10.9H21.8C21.3 6.2 17.6 2.4 12.9 2V2.9C12.9 3.5 12.5 3.9 11.9 3.9C11.3 3.9 10.9 3.5 10.9 2.9V2C6.19999 2.5 2.4 6.2 2 10.9H2.89999C3.49999 10.9 3.89999 11.3 3.89999 11.9C3.89999 12.5 3.49999 12.9 2.89999 12.9H2C2.5 17.6 6.19999 21.4 10.9 21.8V20.9C10.9 20.3 11.3 19.9 11.9 19.9C12.5 19.9 12.9 20.3 12.9 20.9V21.8C17.6 21.3 21.4 17.6 21.8 12.9H20.9Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      d="M16.9 10.9H13.6C13.4 10.6 13.2 10.4 12.9 10.2V5.90002C12.9 5.30002 12.5 4.90002 11.9 4.90002C11.3 4.90002 10.9 5.30002 10.9 5.90002V10.2C10.6 10.4 10.4 10.6 10.2 10.9H9.89999C9.29999 10.9 8.89999 11.3 8.89999 11.9C8.89999 12.5 9.29999 12.9 9.89999 12.9H10.2C10.4 13.2 10.6 13.4 10.9 13.6V13.9C10.9 14.5 11.3 14.9 11.9 14.9C12.5 14.9 12.9 14.5 12.9 13.9V13.6C13.2 13.4 13.4 13.2 13.6 12.9H16.9C17.5 12.9 17.9 12.5 17.9 11.9C17.9 11.3 17.5 10.9 16.9 10.9Z"
                      fill="#2670b6"
                    ></path>
                  </svg>
                  <b class="px-2">{{ modification.created_at }}</b></small
                >
              </div>
            </li>
          </ol>
        </div>
        <!-- Pagination -->
        <pagination
          :links="modificationsLinks"
          :perPage="modificationsPerPage"
          :total="totalmodifications"
          :fetchNext="fetchModifications"
          :inSubmission="in_submission"
        />
        <!-- End Pagination -->
      </div>
    </div>

    <!-- loading -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="modifications.length == 0 && in_submission && !loadingResearch"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- no modifications found yet -->
    <div class="row" v-else-if="modifications.length == 0 && !in_submission">
      <div class="col-auto text-center mt-5 pt-5">
        <img src="/img/oc-error.jpg" class="w-25" alt="no researches" />
        <p class="text-muted fw-bold fs-5 pt-5 text-center">
          there's no modifications has been made yet.
        </p>
      </div>
    </div>
  </div>
  <modification-info-modal
    :modification="currentModification"
    :showQuilEditor="showQuilEditor"
    :rerenderQuill="rerenderQuill"
  />
</template>

<script>
import modificationInfoModal from "@/components/modifications/modificationInfoModal.vue";
import pagination from "@/components/pagination";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "modificationsMain",
  components: {
    modificationInfoModal,
    pagination,
  },
  props: {
    getResearchInfo: {
      required: true,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      in_submission: false,
      showQuilEditor: false,
      modifications: [],
      currentModification: {},
      modificationsLinks: [],
      totalmodifications: 0,
      modificationsPerPage: 0,
    };
  },
  methods: {
    async fetchModifications(page = 1) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      await axiosConfig
        .get(`api/fetchModifications/${researchId}?page=${page}`)
        .then((res) => {
          this.modifications = res.data.data;
          this.modificationsLinks = res.data.links;
          this.totalmodifications = res.data.total;
          this.modificationsPerPage = res.data.per_page;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    fullModification(modification) {
      this.currentModification = modification;
      document.getElementById("modificationInfoModalBtn").click();
      this.rerenderQuill();
    },
    //strange behiavoir of quill that it's must be rerendered to be updated....
    rerenderQuill() {
      this.showQuilEditor = !this.showQuilEditor;
    },
  },
  activated() {
    this.modifications.length == 0 && !this.in_submission
      ? this.fetchModifications()
      : "";
  },
  watch: {
    research() {
      this.fetchModifications();
    },
  },
};
</script>

<style scoped>
.modificationsList-container strong {
  color: #767676;
}
.modificationsList-container ol li {
  margin-bottom: 1rem;
  list-style: none;
  background-color: #f1f1f1;
}
</style>
