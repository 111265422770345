import axios from "axios";

axios.defaults.withCredentials = true;

// local env
// const API_URL = process.env.API_URL || "http://localhost:8000";

//prod env
const API_URL = "https://api.research-portal.limu.edu.ly";

export default axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: "Bearer " + localStorage.getItem("rrs-token"),
  },
});
