<template>
  <RRSheader />
  <div>
    <page-title :refresh="fetchResearches" :in_submission="in_submission">
      <template v-slot:titlePrefix>Researches </template>
      <template v-slot:mainTitle>List </template>
    </page-title>
    <!-- Form Filters -->
    <div class="my-3 container">
      <form>
        <div class="row">
          <div class="col-sm-8 col-md-8 mb-2 mb-md-0">
            <!-- Form for searching -->
            <div class="input-group input-group-merge">
              <span class="input-group-prepend input-group-text">
                <span
                  class="svg-icon svg-icon-sm text-primary"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Search for a research"
                  data-bs-original-title="gen/gen004.svg"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z"
                      fill="#3182ce"
                    ></path>
                    <path
                      opacity="0.3"
                      d="M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z"
                      fill="#7abeef"
                    ></path>
                  </svg>
                </span>
              </span>
              <input
                type="text"
                class="form-control"
                id="Searchforaresearch"
                placeholder="Search for a research"
                aria-label="Search for a research"
                v-model="searchableText"
              />
            </div>
            <!-- End Form -->
          </div>
          <div class="col-4 col-md-4">
            <!-- filter buttons -->
            <button
              class="btn btn-primary"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#researchListFilters"
              aria-controls="researchListFilters"
              title="press to filter the resutls."
            >
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M15 19.5229C15 20.265 15.9624 20.5564 16.374 19.9389L22.2227 11.166C22.5549 10.6676 22.1976 10 21.5986 10H17V4.47708C17 3.73503 16.0376 3.44363 15.626 4.06106L9.77735 12.834C9.44507 13.3324 9.80237 14 10.4014 14H15V19.5229Z"
                  fill="#ffff"
                ></path>
                <path
                  opacity="0.3"
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M3 6.5C3 5.67157 3.67157 5 4.5 5H9.5C10.3284 5 11 5.67157 11 6.5C11 7.32843 10.3284 8 9.5 8H4.5C3.67157 8 3 7.32843 3 6.5ZM3 18.5C3 17.6716 3.67157 17 4.5 17H9.5C10.3284 17 11 17.6716 11 18.5C11 19.3284 10.3284 20 9.5 20H4.5C3.67157 20 3 19.3284 3 18.5ZM2.5 11C1.67157 11 1 11.6716 1 12.5C1 13.3284 1.67157 14 2.5 14H6.5C7.32843 14 8 13.3284 8 12.5C8 11.6716 7.32843 11 6.5 11H2.5Z"
                  fill="#ffff"
                ></path>
              </svg>
              Filters
            </button>
          </div>
          <!-- End Col -->
        </div>
      </form>
    </div>
    <!-- loading -->
    <div class="spinner" v-if="researchesList.length == 0 && in_submission">
      <div class="d-flex justify-content-center text-primary">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <!-- page content -->
    <div class="container-fluid">
      <!-- no research has been found message -->
      <div
        class="row justify-content-center"
        v-if="researchesList.length == 0 && !in_submission"
      >
        <div class="col-auto text-center mt-5 pt-5">
          <img
            src="/img/oc-looking-for-answers.jpg"
            class="w-25"
            alt="no researches"
          />
          <p class="text-muted fw-bold fs-5 pt-5 text-center">
            There's no research has been found.
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.3"
                d="M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z"
                fill="#8c98a4"
              ></path>
              <path
                d="M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.2C9.7 3 10.2 3.20001 10.4 3.60001ZM16 12H13V9C13 8.4 12.6 8 12 8C11.4 8 11 8.4 11 9V12H8C7.4 12 7 12.4 7 13C7 13.6 7.4 14 8 14H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#8c98a4"
              ></path>
              <path
                opacity="0.3"
                d="M11 14H8C7.4 14 7 13.6 7 13C7 12.4 7.4 12 8 12H11V14ZM16 12H13V14H16C16.6 14 17 13.6 17 13C17 12.4 16.6 12 16 12Z"
                fill="#2670b6"
              ></path>
            </svg>
          </p>
        </div>
      </div>
      <!-- researches list section -->
      <div class="row mt-2 mb-3 px-5 pb-5" v-if="researchesList.length > 0">
        <!-- End Form Filters -->
        <div
          class="col-sm-12 col-md-4 col-lg-3"
          v-for="research in researchesList"
          :key="research.id"
        >
          <!-- research card component-->
          <research-card :research="research" :key="research.id" />
        </div>

        <!-- Pagination -->
        <pagination
          :links="researchesLinks"
          :perPage="researchesPerPage"
          :total="totalResearches"
          :fetchNext="fetchResearches"
          :inSubmission="in_submission"
        />
      </div>
    </div>
  </div>
  <popUpMessage />
  <researchListFilters :in_submission="in_submission" @filterResults="filter" />
</template>

<script>
import RRSheader from "@/components/RRSheader.vue";
import pageTitle from "@/components/pageTitle.vue";
import researchCard from "@/components/researchCard.vue";
import store from "@/store/index.js";
import axiosConfig from "@/includes/axiosConfig.js";
import pagination from "@/components/pagination";
import researchListFilters from "@/components/researchListFilters.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    pageTitle,
    RRSheader,
    researchCard,
    pagination,
    researchListFilters,
  },
  name: "researchesList",
  computed: {
    ...mapGetters({
      user: "user",
      faculties: "faculties",
      researchCategories: "categories",
      studiesTypes: "studyTypes",
      researchStatuses: "researchStatuses",
    }),
  },
  data() {
    return {
      researchesList: [],
      in_submission: false,
      researchesLinks: [],
      totalResearches: 0,
      researchesPerPage: 0,
      searchableText: "",
      selectedFaculty: "%",
      selectedCategory: "%",
      selectedTypeOfStudy: "%",
      selectedStatus: "%",
    };
  },
  methods: {
    async fetchResearches({
      page = 1,
      selectedFaculty = this.selectedFaculty,
      selectedCategory = this.selectedCategory,
      selectedTypeOfStudy = this.selectedTypeOfStudy,
      selectedStatus = this.selectedTypeOfStudy,
      searchableText = this.searchableText,
    }) {
      this.in_submission = true;
      let vals = {};
      vals.searchableText = searchableText;
      vals.selectedCategory = selectedCategory;
      vals.selectedTypeOfStudy = selectedTypeOfStudy;
      vals.selectedStatus = selectedStatus;
      vals.selectedFaculty = selectedFaculty;
      await axiosConfig
        .post(`api/fetchResearches?page=${page}`, vals)
        .then((res) => {
          this.researchesList = res.data.data;
          this.researchesLinks = res.data.links;
          this.totalResearches = res.data.total;
          this.researchesPerPage = res.data.per_page;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
          return;
        });

      this.in_submission = false;
    },
    async filter(filters) {
      let vals = filters;
      this.selectedFaculty = vals.selectedFaculty;
      this.selectedCategory = vals.selectedCategory;
      this.selectedTypeOfStudy = vals.selectedTypeOfStudy;
      this.selectedStatus = vals.selectedStatus;
      await this.fetchResearches({
        selectedFaculty: vals.selectedFaculty,
        selectedCategory: vals.selectedCategory,
        selectedTypeOfStudy: vals.selectedTypeOfStudy,
        selectedStatus: vals.selectedStatus,
      });
    },
  },
  async created() {
    await this.fetchResearches({});
  },
  watch: {
    searchableText(after) {
      !this.in_submission || after.length == 0 ? this.fetchResearches({}) : "";
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else {
      next();
    }
  },
};
</script>
