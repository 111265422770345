<template>
  <nav
    v-if="links.length > 0"
    class="d-sm-flex justify-content-sm-between align-items-sm-center text-center px-4"
    aria-label="Page navigation example"
  >
    <ul class="pagination justify-content-center justify-content-sm-end">
      <li
        v-for="link in links"
        :key="link.label"
        class="page-item"
        :class="{
          'disabled not-allowed': link.url == null,
          active: link.active == true,
        }"
      >
        <a
          class="page-link cursor-p"
          @click="
            fetchNext({ page: link.label.slice(link.label.indexOf('=') + 1) })
          "
          v-html="link.label"
          :class="{ 'btn disabled': inSubmission }"
        ></a>
      </li>
    </ul>

    <small class="text-muted">Showing {{ perPage }} of {{ total }}</small>
  </nav>
</template>

<script>
export default {
  name: "pagination",
  props: {
    links: {
      required: true,
      type: Object,
    },
    perPage: {
      required: true,
      type: Number,
    },
    total: {
      required: true,
      type: Number,
    },
    fetchNext: {
      required: true,
      type: Function,
    },
    inSubmission: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    getCurrentPage(label) {
      let isnum = /^\d+$/.test(label);
      if (isnum) {
        console.log(isnum);
      } else {
        console.log(isnum);
      }
    },
  },
};
</script>

<style></style>
