<template>
  <div class="d-flex flex-row justify-content-between m-5">
    <button
      @click="download"
      type="button"
      class="btn btn-md btn-soft-warning rounded-pill border border-warning m-2 h-25"
    >
      <span
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title=""
        data-bs-original-title="fil/fil009.svg"
      >
        <svg
          width="22"
          height="22"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M19 22H5C4.4 22 4 21.6 4 21V3C4 2.4 4.4 2 5 2H14L20 8V21C20 21.6 19.6 22 19 22ZM13 15.4V10C13 9.4 12.6 9 12 9C11.4 9 11 9.4 11 10V15.4H8L11.3 18.7C11.7 19.1 12.3 19.1 12.7 18.7L16 15.4H13Z"
            fill="#b46e26"
          ></path>
          <path d="M15 8H20L14 2V7C14 7.6 14.4 8 15 8Z" fill="#b46e26"></path>
        </svg>
      </span>
      Download certificate as pdf
    </button>
  </div>
  <div class="w-100 d-flex justify-content-center">
    <div class="w-full">
      <vue3-simple-html2pdf
        ref="vue3SimpleHtml2pdf"
        :options="pdfOptions"
        :filename="exportFilename"
      >
        <div class="rounded" id="mainCertificateContainer">
          <div class="p-2 breakPoint">
            <div class="border border-2 border-secondary p-5 breakPoint">
              <!-- the certificate header -->
              <nav class="navbar breakPoint">
                <div class="container-fluid breakPoint">
                  <div
                    class="mx-auto d-flex justify-content-center align-items-center"
                  >
                    <a class="navbar-brand mx-auto" href="#">
                      <img
                        class="img-fluid"
                        style="max-width: 350px"
                        src="/img/Certificate_Reference_Templete_logo.jpg"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
              </nav>
              <!-- certificate date and .no -->
              <div class="mx-5 my-5">
                <small
                  ><b class="text-dark fw-bold">Certificate Reference N0: </b
                  >{{ research.rec_certificate_no || RecCertificateNum }}</small
                >
                <small class="float-end"
                  ><b lass="text-dark fw-bold">Date :</b> {{ currentDate }}
                </small>
              </div>
              <div class="m-4">
                <h3 class="text-center fs-6 fw-bold mb-1 my-2">
                  ETHICAL CLEARANCE CERTIFICATE
                </h3>
                <table class="table text-dark">
                  <tr>
                    <th class="p-2 fw-bold" style="border: 1px solid black">
                      Project Title:
                    </th>
                    <td class="p-2" style="border: 1px solid black">
                      {{ research.title }}
                    </td>
                  </tr>
                  <tr>
                    <th class="p-2 fw-bold" style="border: 1px solid black">
                      Project No.:
                    </th>
                    <td class="p-2" style="border: 1px solid black">
                      {{ research.limu_id }}
                    </td>
                  </tr>
                  <tr>
                    <th class="p-2 fw-bold" style="border: 1px solid black">
                      Name of the Principal Investigator (PI):
                    </th>
                    <td class="p-2" style="border: 1px solid black">
                      {{ research.creator.full_name }}
                    </td>
                  </tr>
                </table>
                <!-- main text -->
                <div class="fw-bold text-dark">
                  <p>
                    On behalf of the Libyan International Medical University's
                    Research Ethics Committee and in compliance with the
                    Helsinki Declaration, I hereby give ethical approval for the
                    above-mentioned research project proposal and its
                    methodology. The research project has been reviewed and
                    approved by an independent ethical review committee, and
                    will be conducted in accordance with relevant national and
                    international laws, regulations, and ethical guidelines. The
                    Research Ethics Committee must be, immediately, informed of:
                  </p>
                </div>
                <!-- final section -->
                <div class="m-1">
                  <ul class="fw-bold">
                    <li>
                      Any material alteration in the conditions or the
                      commitments mentioned in the submitted proposal document.
                    </li>
                    <li>
                      Any ethics violations that may affect the ethical conduct
                      of the appointed research.
                    </li>
                  </ul>
                  <p class="mt-1 fw-bold">
                    The Research Ethics Committee may request procedures for
                    obtaining informed consent before participating and access
                    to any information or data at any time during the course or
                    after completion of the project.
                  </p>
                  <p class="mt-1 fw-bold">
                    The Research Ethics Committee may withdraw or amend this
                    Ethical Clearance Certificate if:
                  </p>
                  <ul class="fw-bold">
                    <li>
                      Any unethical principle or practice is suspected or any
                      participant confidentiality is revealed.
                    </li>
                    <li>
                      Any relevant information has been withheld or
                      misrepresented.
                    </li>
                    <li>
                      Any regulatory changes of whatever nature are required.
                    </li>
                    <li>
                      The conditions contained in the certificate have not been
                      adhered to.
                    </li>
                  </ul>
                </div>
                <!-- certificate footer -->
                <div class="certificate-footer p-0" style="margin-top: 200px">
                  <div class="d-flex justify-content-between">
                    <small class="fw-bold">
                      <b class="d-block fw-bold text-dark"
                        >Prof. Ali Saeid Albarghathi
                      </b>
                      <b class="d-block"
                        >Chairman of the Research Ethics Committee at</b
                      >
                      <b class="d-block">The Libyan International University</b>
                    </small>
                    <small class="fw-bold">
                      <b class="d-block"
                        >Email:
                        <span class="text-primary fw-bold"
                          >ethics@limu.edu.ly</span
                        ></b
                      >
                    </small>
                  </div>
                  <div class="d-inline col-1">
                    <img
                      src="/img/ethical_admin_stamp-removebg-preview.png"
                      alt=""
                      class="stamps"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue3-simple-html2pdf>
    </div>
  </div>
</template>

<script>
export default {
  name: "ethicalClearanceCertificate",
  props: {
    research: {
      required: true,
      type: Object,
    },
    RecCertificateNum: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      pdfOptions: {
        margin: 0,
        padding: 0,
        image: {
          type: "jpeg",
          quality: 1,
        },
        pagebreak: {
          avoid: ["#mainCertificateContainer", ".stamps", ".breakPoint"],
        },
        html2canvas: {
          scale: 3,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "p",
        },
      },
      exportFilename: "ethical clearance certificate.pdf",
      currentDate: null,
    };
  },
  methods: {
    download() {
      this.$refs.vue3SimpleHtml2pdf.download();
    },
  },
  created() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    today = yyyy + "/" + mm + "/" + dd;
    this.currentDate = today;
  },
};
</script>

<style scoped>
.d-flex {
  display: flex !important;
}
.flex-row {
  flex-direction: row !important;
}
.justify-content-between {
  justify-content: space-between !important;
}
.d-inline {
  display: inline !important;
}
.bg-soft-warning {
  --bs-bg-opacity: 1;
  background-color: #fff6de !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.table tr {
  color: #020202;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.border-5 {
  border-width: 5px !important;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}

.container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.float-end {
  float: right !important;
}

.float-start {
  float: left !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.text-center {
  text-align: center !important;
}

.text-end {
  text-align: end !important;
}

.text-end {
  text-align: start !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bold-400 {
  font-weight: 400 !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.m-0 {
  margin: 0 !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.d-block {
  display: block !important;
}

.fs-8 {
  font-size: 0.8rem;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}
.certificate-footer {
  position: relative;
}
.certificate-footer .col-1 {
  text-align: left;
}
.certificate-footer .col-2 {
  text-align: right;
}

.stamps {
  width: 40%;
  height: 40%;
}

.certificate-attr {
  line-height: 0.9;
}

th {
  width: auto;
  white-space: nowrap; /* Prevent text wrapping */
}

td {
  width: 100%;
}
</style>
