<template>
  <!-- Content -->
  <div class="bg-primary">
    <div class="container content-space-1 content-space-t-md-3">
      <div class="mx-auto" style="max-width: 30rem">
        <!-- Card -->
        <div class="card card-lg zi-2 border border-1 shadow rounded">
          <!-- Header -->
          <div class="card-header text-center">
            <h4 class="card-title">Reset your password</h4>
            <p class="card-text">
              Please fill these fields in order to reset your password
            </p>
          </div>
          <!-- End Header -->

          <!-- Card Body -->
          <div class="card-body">
            <vee-form
              :validationSchema="resetPasswordForm"
              @submit="resetPassword"
            >
              <!-- Form -->
              <div class="mb-4">
                <label class="form-label" for="password"
                  >Your New Password</label
                >
                <vee-field
                  type="password"
                  class="form-control"
                  name="password"
                  id="password"
                  placeholder="Enter your password"
                  aria-label="Enter your password"
                />
                <error-message name="password" class="text-danger" />
              </div>
              <!-- End Form -->

              <!-- Form -->
              <div class="mb-4">
                <label class="form-label" for="password_confirmation"
                  >Confirm the new Password</label
                >
                <vee-field
                  type="password"
                  class="form-control"
                  name="password_confirmation"
                  id="password_confirmation"
                  placeholder="Confirm the password"
                  aria-label="Confirm the password"
                />
                <error-message
                  name="password_confirmation"
                  class="text-danger"
                />
                <small id="passwordValidator" class="text-danger"></small>
              </div>
              <!-- End Form -->

              <div class="d-grid mb-4" v-if="!in_Submission">
                <vee-field
                  type="submit"
                  name="submit"
                  value="submit"
                  class="btn btn-primary btn-lg"
                  :disabled="in_Submission"
                />
              </div>
              <!-- spinners -->
              <div
                class="d-flex justify-content-center my-2"
                v-if="in_Submission"
              >
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

              <!-- alert error message -->
              <div
                v-if="errors != null && !in_Submission"
                class="alert alert-danger d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ errors }}</div>
              </div>
              <!-- alert success message -->
              <div
                v-if="success != null && !in_Submission"
                class="alert alert-success d-flex align-items-center text-white"
                role="alert"
              >
                <div>{{ success }}</div>
              </div>

              <div class="text-center">
                <a class="btn btn-link" @click="$router.push({ name: 'Home' })">
                  Back to log in
                </a>
              </div>
            </vee-form>
          </div>
          <!-- End Card Body -->
        </div>
        <!-- End Card -->
      </div>
    </div>
  </div>
  <!-- End Content -->

  <!-- Shape -->
  <div class="shape-container">
    <div class="shape shape-bottom zi-1">
      <svg
        viewBox="0 0 3000 600"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 600V350.234L3000 0V600H0Z" fill="#fff" />
      </svg>
    </div>
  </div>
  <!-- End Shape -->
</template>

<script>
import { checkPasswordStrenth } from "@/includes/helpers.js";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "passwordReset",
  data() {
    return {
      resetPasswordForm: {
        password: "required",
        password_confirmation: "required|confirmed:@password",
      },
      in_Submission: false,
      errors: null,
      success: null,
    };
  },
  methods: {
    async resetPassword(val) {
      this.in_Submission = true;
      let result = checkPasswordStrenth(val.password);
      let email = this.$route.query.email;
      let token = this.$route.params.token;
      if (!result) {
        document.getElementById("passwordValidator").innerHTML =
          "<ul><li>At least 8 characters</li><li>Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number</li> <li>Can contain special characters</li></ul>";
      } else {
        let data = {
          ...val,
          email: email,
          token: token,
        };
        console.log("data");
        await axiosConfig
          .post("/reset-password", data)
          .then((res) => {
            console.log(res);
            this.success = res.data.status;
            setTimeout(() => {
              this.$router.push({ name: "Home" });
            }, 3000);
          })
          .catch((err) => {
            console.log(err.response);
            this.errors = err.response
              ? Object.values(err.response.data.errors)[0][0]
              : "please try again later";
            setTimeout(() => {
              this.$router.push({ name: "Home" });
            }, 5000);
          });
      }
      this.in_Submission = false;
      setTimeout(() => {
        this.errors = null;
        this.success = null;
      }, 5000);
    },
  },
};
</script>

<style></style>
