<template>
  <!-- Modal -->
  <div
    class="modal fade"
    id="assignMemberModal"
    tabindex="-1"
    aria-labelledby="assignMemberModal"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="assignMemberModal">
            Assign members to this proposal
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-12">
                <!-- Form for searching the members -->
                <div class="mb-3">
                  <label for="members" class="form-label visually-hidden"
                    >Choose the member/s</label
                  >
                  <div class="liveSearchDropDown">
                    <input
                      type="text"
                      class="form-control"
                      name="members"
                      id="liveSearchInput"
                      placeholder="Search for members to assign them"
                      v-model="currentSearch"
                      autocomplete="off"
                    />
                    <div
                      class="liveSearchResult"
                      v-if="members.length > 0 && !in_submission"
                    >
                      <template v-for="member in members" :key="member.id">
                        <span
                          class="m-2 p-2 cursor-p"
                          @click="pushInArray(assignedMembers, member.email)"
                          ><svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              opacity="0.3"
                              d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM12 7C10.3 7 9 8.3 9 10C9 11.7 10.3 13 12 13C13.7 13 15 11.7 15 10C15 8.3 13.7 7 12 7Z"
                              fill="#2670b6"
                            ></path>
                            <path
                              d="M12 22C14.6 22 17 21 18.7 19.4C17.9 16.9 15.2 15 12 15C8.8 15 6.09999 16.9 5.29999 19.4C6.99999 21 9.4 22 12 22Z"
                              fill="#2670b6"
                            ></path>
                          </svg>
                          <a class="mx-2 fw-bold">{{ member.full_name }}</a>
                          <small class="text-muted d-block ms-5">{{
                            member.email
                          }}</small></span
                        >
                      </template>
                    </div>
                    <div
                      class="d-flex justify-content-center m-5"
                      v-if="in_submission"
                    >
                      <div class="spinner-grow text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End Form -->
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <!-- members area -->
                <div class="my-3" v-if="assignedMembers.length > 0">
                  <span
                    v-for="email in assignedMembers"
                    :key="email"
                    class="badge bg-primary rounded-pill m-1"
                    >{{ email }}
                    <!-- remove keyword button -->
                    <a
                      class="btn btn-sm p-0"
                      @click="popFromArray(assignedMembers, email)"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z"
                          fill="#ffff"
                        ></path>
                        <path
                          d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z"
                          fill="#ffff"
                        ></path>
                      </svg>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            @click="assignMembersToResearch"
            :disabled="assignedMembers.length == 0"
            v-if="!in_submission"
          >
            Assign Members
          </button>
          <!-- spinners -->
          <div class="d-flex justify-content-center my-2" v-if="in_submission">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
          <button type="button" class="btn btn-white" data-bs-dismiss="modal">
            Close
          </button>
        </div>
        <!-- alert message -->
        <div
          v-if="message != null && !in_submission"
          class="alert d-flex align-items-center text-white m-3 text-start"
          :class="messageClass"
          role="alert"
        >
          <div>{{ message }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig.js";
import { pushInArray, popFromArray } from "@/includes/helpers.js";

export default {
  name: "assignMemberModal",
  props: {
    fetchAssignedMembers: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      in_submission: false,
      message: null,
      messageClass: null,
      currentSearch: null,
      members: [],
      assignedMembers: [],
    };
  },
  methods: {
    async searchMembers(currentSearch) {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      let search = {
        search: currentSearch,
      };
      await axiosConfig
        .post(`api/searchMember/${researchId}`, search)
        .then((res) => {
          console.log(res);
          this.members = res.data;
        })
        .catch((err) => {
          console.log(err.response);
          return;
        });
    },
    async assignMembersToResearch() {
      this.in_submission = true;
      let members = {
        members: this.assignedMembers,
      };
      let researchId = this.$route.params.Id;
      await axiosConfig
        .post(`api/assignMembersToResearch/${researchId}`, members)
        .then((res) => {
          console.log(res);
          this.messageClass = "alert-success";
          this.message = res.data;
          this.assignedMembers = [];
          this.members = [];
          this.fetchAssignedMembers();
        })
        .catch((err) => {
          console.log(err.response);
          this.messageClass = "alert-danger";
          this.message = "Error, please try again later.";
          return;
        });
      setTimeout(() => {
        this.message = null;
      }, 3000);
      this.in_submission = false;
    },
    pushInArray,
    popFromArray,
  },
  watch: {
    currentSearch: async function (newVal) {
      if (newVal) {
        this.members = [];
        await this.searchMembers(newVal);
        this.in_submission = false;
      }
    },
  },
};
</script>
