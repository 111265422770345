<template>
  <RRSheader />
  <page-title :in_submission="true">
    <template v-slot:titlePrefix>Research portal </template>
    <template v-slot:mainTitle>Tutorials </template>
  </page-title>
  <!-- List - Blog -->
  <div class="container mt-5">
    <!-- Card  1-sign up-->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->
              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/IjbsoRRKx5I"
                  target="_blank"
                  rel="noopener noreferrer"
                  >1- LIMU research portal sign up
                </a>
              </h4>
              <p>
                Introducing the research portal system and learn How to sign up
                in the research portal system for the first time as LIMU member.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 2-login and reset password-->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/YGmnq2WYqBE"
                  target="_blank"
                  rel="noopener noreferrer"
                  >2- LIMU research portal log in & reset password
                </a>
              </h4>
              <p>
                Once we created the account let's see how log in into the system
                and how to reset the password in case that you forgot it.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 3 LIMU research portal registering a new proposal -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/Spvtye_sRH4"
                  target="_blank"
                  rel="noopener noreferrer"
                  >3 LIMU research portal registering a new proposal
                </a>
              </h4>
              <p>
                The more important thing that the researcher may consider is how
                to submit a new proposal in the research portal system....
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 3 LIMU research portal registering a new proposal -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/cvT93Bjob2Y"
                  target="_blank"
                  rel="noopener noreferrer"
                  >4 LIMU research portal how to review my uploaded proposal &
                  edit it
                </a>
              </h4>
              <p>
                Once the proposal is registered in the research portal system
                you may want to review all the information that you submitted
                and in some cases you may also want to update the information.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 5 LIMU research portal first stage the under review status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/ULlLPD8KlS0"
                  target="_blank"
                  rel="noopener noreferrer"
                  >5 LIMU research portal first stage the under review status
                </a>
              </h4>
              <p>
                How do you know that the ethical committee is reviewing your
                proposal now in the first stage for a decision to take after.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 6 LIMU research portal first stage the with modification status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/ehB8Dbt9mVQ"
                  target="_blank"
                  rel="noopener noreferrer"
                  >6 LIMU research portal first stage the with modification
                  status
                </a>
              </h4>
              <p>
                In some cases the ethical committee may ask for modification for
                the uploaded proposal, let's see how to update our proposal
                according to the requested modification.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 7 LIMU research portal first stage the pending for RSC status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/GyFZRsjxI1E"
                  target="_blank"
                  rel="noopener noreferrer"
                  >7 LIMU research portal first stage the pending for RSC status
                </a>
              </h4>
              <p>
                "Congrats your proposals has been accepted !" in this video I
                will explain how do you know that you passed from the ethical
                committe in first stage into the second stage to the scientific
                committee that every proposal should pass throught thess stages.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 8 LIMU research portal first stage the rejected status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/SePpuW0O2xg"
                  target="_blank"
                  rel="noopener noreferrer"
                  >8 LIMU research portal first stage the rejected status
                </a>
              </h4>
              <p>
                unfortunately the research ethical committee may take the reject
                decision after reviewing your proposal so let's discuss what
                could happen in that case.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 9 LIMU research portal second stage the with modification status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/SdjlTGNDU_U"
                  target="_blank"
                  rel="noopener noreferrer"
                  >9 LIMU research portal second stage the with modification
                  status
                </a>
              </h4>
              <p>
                In some cases the scientific committee may ask for modification
                for the uploaded proposal, let's see how to update our proposal
                according to the requested modification.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 10 LIMU research portal second stage the under reporting period status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/wePRYs6AIT8"
                  target="_blank"
                  rel="noopener noreferrer"
                  >10 LIMU research portal second stage the under reporting
                  period status
                </a>
              </h4>
              <p>
                What is the under reporting period status exactly mean on my
                proposal ?!.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 11 LIMU research portal third stage how to make reports -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/BMZ1wVGtGlM"
                  target="_blank"
                  rel="noopener noreferrer"
                  >11 LIMU research portal third stage how to make reports
                </a>
              </h4>
              <p>
                After the RSC (Research scientific committee) approval you need
                to make reports periodically, in this video i will explain the
                reporting process.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 12 LIMU research portal third stage the closed status -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/WV3W18nbUUc"
                  target="_blank"
                  rel="noopener noreferrer"
                  >12 LIMU research portal third stage the closed status
                </a>
              </h4>
              <p>
                Congrats you reached your goal by uploading the final report and
                the final version of your research and there's no need for
                making any report further.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
    <!-- Card 13 LIMU research portal third stage how to upload your contract -->
    <div class="card card-sm shadow border bg-light my-3">
      <div class="card-body">
        <div class="row align-items-md-center">
          <div class="col-md-6 mb-3 mb-md-0">
            <img
              class="img-fluid rounded"
              src="/img/researchPortalMainPage.PNG"
              alt="Image Description"
            />
          </div>
          <!-- End Col -->

          <div class="col-md-6">
            <div class="ps-md-5">
              <!-- List Separator -->
              <ul class="list-inline list-separator mb-3">
                <li class="list-inline-item">
                  <a class="text-cap text-muted mb-0" href="#">By Marwan</a>
                </li>
                <li class="list-inline-item">
                  <span class="text-cap text-muted mb-0">fri, 2022-11-11</span>
                </li>
              </ul>
              <!-- End List Separator -->

              <h4 class="mb-3 mb-md-5">
                <a
                  class="text-dark"
                  href="https://youtu.be/fl0S4hdoEJc"
                  target="_blank"
                  rel="noopener noreferrer"
                  >13 LIMU research portal third stage how to upload your
                  contract
                </a>
              </h4>
              <p>
                In case that you choose the with fund option when you submitted
                your proposal for the first time,then you need to upload your
                contract once the scientific committee approved you proposal.
              </p>
            </div>
          </div>
          <!-- End Col -->
        </div>
        <!-- End Row -->
      </div>
    </div>
    <!-- End Card -->
  </div>
  <!-- End List - Blog -->
  <logIn-modal />
  <signUp-Modal />
  <pop-up-message />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "@/components/RRSheader.vue";
import signUpModal from "@/components/signUpModal.vue";
import logInModal from "@/components/logInModal.vue";
export default {
  name: "tutorials",
  components: {
    RRSheader,
    pageTitle,
    signUpModal,
    logInModal,
  },
};
</script>

<style></style>
