<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="d-none"
    data-bs-toggle="modal"
    data-bs-target="#approveContract"
    id="approveContractBtn"
  ></button>

  <!-- Modal -->
  <div
    class="modal fade"
    id="approveContract"
    tabindex="-1"
    aria-labelledby="approveContractLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="approveContractLabel">
            Approve the contract
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          Are you sure that you want to approve this contract ?
        </div>
        <div class="modal-footer">
          <p
            class="fw-bold d-block w-100 mb-2"
            :class="messageClass"
            v-if="message.length > 0"
          >
            {{ message }}
          </p>
          <button
            v-if="!in_submission"
            type="button"
            class="btn btn-primary"
            @click="approveContract"
          >
            Yes
          </button>
          <div
            class="spinner-border m-5 text-primary"
            role="status"
            v-if="in_submission"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
          <button
            type="button"
            class="btn btn-white"
            data-bs-dismiss="modal"
            id="closeApproveContractBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axiosConfig from "@/includes/axiosConfig";

export default {
  name: "approveContract",
  props: {
    researchId: {
      required: true,
      type: Number,
    },
    getResearchInfo: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      in_submission: false,
      message: "",
      messageClass: "",
    };
  },
  methods: {
    async approveContract() {
      this.in_submission = true;
      await axiosConfig
        .get(`api/approveContract/${this.researchId}`)
        .then((res) => {
          console.log(res);
          this.messageClass = "text-success";
          this.message = "The contract has been approved";
          this.getResearchInfo();
          setTimeout(() => {
            document.getElementById("closeApproveContractBtn").click();
          }, 2000);
        })
        .catch((err) => {
          this.messageClass = "text-danger";
          this.message = "An error has been occurred please try again later.";
          console.log(err.response);
        });
      this.in_submission = false;
    },
  },
};
</script>

<style></style>
