<template>
  <div class="container mt-4">
    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary"
      v-if="(research == null && loadingResearch) || in_submission == true"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- add new member button -->
    <div
      class="text-end mb-3"
      v-if="hasRole(['REC-admin', 'RSC-admin']) && !in_submission"
    >
      <a
        class="btn btn-sm btn-outline-primary rounded-pill me-1 fw-bold"
        data-bs-toggle="modal"
        data-bs-target="#assignMemberModal"
        >Assign A New Member
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.3"
            d="M11 13H7C6.4 13 6 12.6 6 12C6 11.4 6.4 11 7 11H11V13ZM17 11H13V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
            fill="#035A4B"
          ></path>
          <path
            d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM17 11H13V7C13 6.4 12.6 6 12 6C11.4 6 11 6.4 11 7V11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V13H17C17.6 13 18 12.6 18 12C18 11.4 17.6 11 17 11Z"
            fill="#3182ce"
          ></path>
        </svg>
      </a>
    </div>
    <!-- End add new member button -->
    <!-- show members list -->
    <div class="row mb-sm-5" v-if="assignedMembers.length > 0">
      <div
        class="col-sm-6 col-lg-4 mb-7"
        v-for="member in assignedMembers"
        :key="member.id"
      >
        <!-- Team -->
        <div class="text-center mx-auto bg-white shadow border p-3 rounded">
          <img
            class="img-fluid rounded-circle mb-5 w-50 step-avatar-img"
            :src="member.profile_img_path || '/img/default_profile_image.jpg'"
            alt="Image Description"
          />
          <h6 class="mb-1 fs-5 fw-bold">
            {{ member.full_name }}
            <!-- unsignedMemberIcon -->
            <a
              v-if="hasRole(['REC-admin', 'RSC-admin'])"
              @click="changeCurrentEmail(member.email)"
              class="mx-1 cursor-p"
              data-bs-toggle="modal"
              data-bs-target="#unassignMemberModal"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM18 12C18 11.4 17.6 11 17 11H7C6.4 11 6 11.4 6 12C6 12.6 6.4 13 7 13H17C17.6 13 18 12.6 18 12Z"
                  fill="#692340"
                ></path>
              </svg>
            </a>
          </h6>
          <!-- all roles -->
          <!-- <span
            class="d-block fs-8 text-muted"
            v-for="role in member.roles"
            :key="role.id"
          >
            <p class="m-0">{{ role.name }}</p>
          </span> -->

          <div
            class="border-2 border-top mx-auto my-3"
            style="max-width: 5rem"
          ></div>

          <p>{{ member.bio || "there's no profile description to show" }}</p>
        </div>
        <!-- End Team -->
      </div>
    </div>
    <!-- end members list -->
    <!-- no members message -->
    <div
      class="row justify-content-center"
      v-if="assignedMembers.length == 0 && !in_submission"
    >
      <div class="col-12 text-center">
        <img
          src="/img/oc-online-communication.jpg"
          alt="research portal"
          class="w-25"
        />
        <h5 class="mt-2 text-muted fw-bold">
          there's no members has been assigned to this proposal.
        </h5>
      </div>
    </div>
    <!-- end no members message -->
    <!-- End Row -->
  </div>

  <!-- assign member modal -->
  <assignMemberModal :fetchAssignedMembers="fetchAssignedMembers" />
  <unassignMemberModal
    :email="email"
    :fetchAssignedMembers="fetchAssignedMembers"
  />
  <!-- end assign member modal -->
</template>

<script>
import assignMemberModal from "@/components/assignedMembers/assignMemberModal.vue";
import unassignMemberModal from "@/components/assignedMembers/unassignMemberModal.vue";
import axiosConfig from "@/includes/axiosConfig.js";
import { hasRole } from "@/includes/helpers.js";

export default {
  name: "assignedMembersMain",
  props: {
    getResearchInfo: {
      required: true,
      type: Function,
    },
    research: {
      required: true,
      type: [null, Object],
    },
    loadingResearch: {
      required: true,
      type: Boolean,
    },
  },
  components: {
    assignMemberModal,
    unassignMemberModal,
  },
  data() {
    return {
      in_submission: false,
      assignedMembers: [],
      email: null,
    };
  },
  methods: {
    async fetchAssignedMembers() {
      this.in_submission = true;
      let researchId = this.$route.params.Id;
      await axiosConfig
        .get(`api/researchAssignedMembers/${researchId}`)
        .then((res) => {
          this.assignedMembers = res.data;
          console.log(res);
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    changeCurrentEmail(value) {
      this.email = value;
    },
    hasRole,
  },
  activated() {
    this.assignedMembers.length == 0 && !this.in_submission
      ? this.fetchAssignedMembers()
      : "";
  },
};
</script>

<style></style>
