<template>
  <RRSheader />
  <div>
    <!-- if the user is editable -->
    <page-title v-if="!userEditable">
      <template v-slot:titlePrefix>Add new </template>
      <template v-slot:mainTitle>Member </template>
    </page-title>
    <!-- if the user is not editable -->
    <page-title v-else>
      <template v-slot:titlePrefix>Edit </template>
      <template v-slot:mainTitle>Member </template>
    </page-title>

    <!-- loading icon -->
    <div
      class="d-flex justify-content-center text-primary pt-5"
      v-if="in_submission"
    >
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <!-- add/update user form -->
    <div class="container" v-if="!in_submission">
      <div class="row justify-content-center">
        <div class="col m-5 p-4 border border-1 rounded shadow">
          <!-- Api message -->
          <div
            class="alert alert-dismissible fade show my-2"
            role="alert"
            v-if="apiMessage != null"
            :class="messageClass"
          >
            <!-- for the message icon -->
            <svg
              v-if="messageClass == 'alert-success'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#ffff"
              class="bi bi-check-circle-fill me-2"
              viewBox="0 0 16 16"
            >
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="currentColor"
              class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
              viewBox="0 0 16 16"
              role="img"
              aria-label="Warning:"
            >
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
              />
            </svg>

            <span v-html="apiMessage"></span>
          </div>
          <!-- end Api message -->
          <vee-form
            :validationSchema="addMemberschema"
            :initialValues="userDefaultValues"
            @submit="addNewMember"
            id="userForm"
          >
            <vee-field v-if="userEditable" name="userId" type="hidden" />
            <!-- USER FULL NAME AND ORCID -->
            <div class="d-flex flex-row justify-content-between mb-3">
              <!-- input for the user Name -->
              <div class="w-100 me-2">
                <label class="form-label" for="exampleFormControlInput1"
                  >Full name<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="text"
                  id="fullName"
                  name="fullName"
                  class="form-control"
                  placeholder="the member's full name"
                />
                <error-message class="text-danger" name="fullName" />
              </div>
              <!-- input for the user ORCID -->
              <div class="w-100 ms-2">
                <label class="form-label" for="exampleFormControlInput1"
                  >ORCID</label
                >
                <vee-field
                  type="text"
                  id="ORCID"
                  name="ORCID"
                  class="form-control"
                  placeholder="the member's ORCID code"
                />
                <error-message class="text-danger" name="ORCID" />
              </div>
            </div>

            <!-- USER EMAIL AND PHONE -->
            <div class="d-flex flex-row justify-content-between mb-3">
              <!-- input for the user email -->
              <div class="w-100 me-2">
                <label class="form-label" for="email"
                  >Email address<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="email"
                  id="email"
                  name="email"
                  class="form-control"
                  placeholder="example@limu.edu.ly"
                />
                <error-message class="text-danger" name="email" />
              </div>
              <!-- input for the user phone -->
              <div class="w-100 me-2">
                <label class="form-label" for="phone">Phone number</label>
                <vee-field
                  type="numeric"
                  id="phone"
                  name="phone"
                  class="form-control"
                  placeholder="the member's phone number"
                />
                <error-message class="text-danger" name="phone" />
              </div>
            </div>

            <!-- USER CATEGORY AND FACULTY AND ROLE -->
            <div class="d-flex flex-row justify-content-between mb-3">
              <!-- select for the user faculty -->
              <div class="w-100 me-2">
                <label class="form-label" for="faculty"
                  >Member's faculty<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  as="select"
                  id="faculty"
                  name="faculty"
                  class="form-control"
                >
                  <option value="" disabled selected>choose a faculty</option>
                  <option
                    v-for="faculty in faculties"
                    :key="faculty.id"
                    :value="faculty.id"
                  >
                    {{ faculty.name }}
                  </option>
                </vee-field>
                <error-message class="text-danger" name="faculty" />
              </div>
              <!-- select for the user category -->
              <div class="w-100 me-2">
                <label class="form-label" for="category"
                  >Member's category<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  as="select"
                  id="category"
                  name="category"
                  class="form-control"
                >
                  <option value="" disabled selected>choose a category</option>
                  <option
                    v-for="category in userCategories"
                    :key="category.id"
                    :value="category.id"
                  >
                    {{ category.name }}
                  </option>
                </vee-field>
                <error-message class="text-danger" name="category" />
              </div>
              <!-- select for the user role -->
              <div class="w-100 ms-2">
                <label class="form-label" for="roles"
                  >Member's role<i class="text-danger fw-bold">*</i></label
                >
                <!-- old way before multi roles switcher -->
                <vee-field
                  v-slot="{ value }"
                  as="select"
                  id="role"
                  name="roles"
                  v-model="initial_roles_names"
                  class="form-control"
                  multiple
                >
                  <option
                    v-for="role in rolesNames"
                    :key="role"
                    :value="role"
                    :selected="value && value.includes(role)"
                  >
                    {{ role }}
                  </option>
                </vee-field>
                <error-message class="text-danger" name="roles" />
              </div>
            </div>

            <!-- USER PASSWORD AND PASSWORD CONFIRMATION -->
            <div class="d-flex flex-row justify-content-between mb-3">
              <!-- input for the user password -->
              <div class="w-100 me-2">
                <label class="form-label" for="password"
                  >Member's password<i class="text-danger fw-bold">*</i></label
                >
                <vee-field
                  type="password"
                  id="password"
                  name="password"
                  class="form-control"
                  placeholder="member's password"
                  autocomplete
                />
                <error-message class="text-danger" name="password" />
              </div>
              <!-- input for the password confirmation -->
              <div class="w-100 me-2">
                <label class="form-label" for="password"
                  >Password confirmation<i class="text-danger fw-bold"
                    >*</i
                  ></label
                >
                <vee-field
                  type="password"
                  id="password_confirmation"
                  name="password_confirmation"
                  class="form-control"
                  placeholder="password confirmation"
                  autocomplete
                />
                <error-message
                  class="text-danger"
                  name="password_confirmation"
                />
              </div>
            </div>

            <!-- USER EMAIL CONFIRMATION CHECK -->
            <!-- Checkbox -->
            <div class="form-check mb-3" v-if="!userEditable">
              <vee-field
                type="checkbox"
                id="verificationRequired"
                name="verificationRequired"
                class="form-check-input"
                value="true"
              />
              <label class="form-check-label" for="formCheck11"
                >If you check this input the new member don't have to verify his
                email.</label
              >
            </div>
            <!-- End Checkbox -->

            <div class="d-grid text-center mt-7">
              <button
                v-if="!in_submission"
                class="btn btn-primary-dark btn-lg"
                type="submit"
              >
                {{ userEditable ? "update member" : "Add member" }}
              </button>
              <div class="d-flex justify-content-center text-light">
                <div v-if="in_submission" class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </vee-form>
        </div>
      </div>
    </div>
  </div>

  <div class="shape-container" v-if="!in_submission">
    <div class="shape shape-bottom text-primary">
      <svg
        width="3000"
        height="400"
        viewBox="0 0 3000 400"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 400H3000V0L0 400Z" fill="#7abeef"></path>
      </svg>
    </div>
  </div>
  <popUpMessage />
</template>

<script>
import pageTitle from "@/components/pageTitle.vue";
import RRSheader from "@/components/RRSheader.vue";
import store from "@/store/index.js";
import { checkPasswordStrenth } from "@/includes/helpers.js";
import { mapGetters } from "vuex";
import { hasRole } from "@/includes/helpers.js";
import axiosConfig from "@/includes/axiosConfig.js";

export default {
  name: "addNewUser",
  components: { RRSheader, pageTitle },
  computed: {
    ...mapGetters({
      categories: "categories",
      faculties: "faculties",
      userCategories: "userCategories",
      roles: "roles",
      rolesNames: "rolesNames",
      user: "user",
    }),
  },
  data() {
    return {
      addMemberschema: {
        fullName: "required|alpha_spaces|max:100",
        ORCID: "regex:^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$",
        email: "required|regex:^[A-Za-z0-9._%+-]+@limu.edu.ly$",
        phone: "numeric|max:14",
        category: "required|numeric",
        faculty: "required|numeric",
        roles: "required",
        verificationRequired: "",
        password: "required",
        password_confirmation: "required|confirmed:@password",
      },
      userDefaultValues: {},
      initial_roles_names: [],
      userEditable: false,
      in_submission: false,
      apiMessage: null,
      messageClass: null,
    };
  },
  methods: {
    async addNewMember(val) {
      console.log(val);
      let result = checkPasswordStrenth(val.password);
      if (result || this.userEditable) {
        this.in_submission = true;
        let editable = this.userEditable ? 1 : 0;
        await axiosConfig
          .post(`api/createOrUpdateUser/${editable}`, val)
          .then(async (res) => {
            console.log(res);
            this.messageClass = "alert-success";
            this.apiMessage = !this.userEditable
              ? "The new member has been added successfully."
              : "The user has been modified successfully.";
            !this.userEditable
              ? document.getElementById("userForm").reset()
              : "";
            if (this.$route.params.id == this.user.id) {
              await this.$store.dispatch("checkIfAuthenticated");
            }
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response) {
              this.messageClass = "alert-danger";
              this.apiMessage =
                err.response.data.message ||
                "an error has been occurred please try again later";
            }
          });

        this.in_submission = false;
      } else {
        this.messageClass = "alert-danger";
        this.apiMessage =
          "The password rules:<ul><li>At least 8 characters</li><li>Must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number</li> <li>Can contain special characters</li></ul>";
      }
      this.userEditable ? this.makeUserEditable(this.$route.params.id) : "";
      setTimeout(() => {
        this.apiMessage = null;
        this.messageClass = null;
      }, 10000);
    },
    async makeUserEditable(id) {
      !this.hasRole(["admin"]) ? this.$route.go(-1) : "";
      await axiosConfig
        .get(`api/user/${id}`)
        .then((res) => {
          console.log(res);
          this.userEditable = true;
          let user = res.data[0];
          this.userDefaultValues.userId = user.id;
          this.userDefaultValues.fullName = user.full_name;
          this.userDefaultValues.ORCID = user.ORCID;
          this.userDefaultValues.email = user.email;
          this.userDefaultValues.phone = user.phone;
          this.userDefaultValues.category = user.user_category_id;
          this.userDefaultValues.faculty = user.faculty_id;
          this.initial_roles_names = [];
          user.roles.forEach((role) => {
            this.initial_roles_names.push(role.name);
          });
          // password is not required anymore
          this.addMemberschema.password = "";
          this.addMemberschema.password_confirmation = "";
        })
        .catch((err) => {
          err.response.status == 404 ? this.$router.go(-1) : "";
          console.log(err.response);
        });
    },
    hasRole,
  },
  async created() {
    this.in_submission = true;
    //determine what ever if the user is editable or not
    this.$route.params.id
      ? await this.makeUserEditable(this.$route.params.id)
      : "";
    this.in_submission = false;
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.authenticated) {
      next({ name: "Home" });
    } else if (!store.state.verified) {
      next({ name: "verifyEmail" });
    } else if (!hasRole(["admin"])) {
      next({ name: "main" });
    } else {
      next();
    }
  },
};
</script>

<style></style>
