<template>
  <!-- chart text -->
  <div class="text-center">
    <h6 class="research-title">Research Portal Doughnut chart totals</h6>
  </div>
  <div v-if="in_submission" class="d-flex justify-content-center text-primary">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!in_submission">
    <canvas id="DoughnutChartTotals"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axiosConfig from "@/includes/axiosConfig";

export default {
  name: "doughnutChartTotals",
  props: {
    destroy: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      in_submission: false,
      labels: [
        "Accepted By REC",
        "Accepted By RSC",
        "Closed",
        "Suspended",
        "Rejected",
      ],
      colors: [
        "#0885ce", //primaryColor1
        "#d96300", //primaryColor2
        "#077c76", //successColor
        "#f1b980", //warningColor
        "#692340", //dangerColor
      ],
      data: [0, 0, 0, 0],
      myChart: null,
    };
  },
  methods: {
    async fetchDoughnutChartTotals() {
      this.in_submission = true;
      await axiosConfig
        .get("api/fetchDoughnutChartTotals")
        .then((res) => {
          console.log(res);
          this.data = res.data;
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
    },
    createChartInctance() {
      const ctx = document.getElementById("DoughnutChartTotals");
      const data = {
        labels: this.labels,
        datasets: [
          {
            label: "Researches Portal totals",
            data: this.data,
            backgroundColor: this.colors,
            hoverOffset: 5,
          },
        ],
      };
      this.myChart = new Chart(ctx, {
        type: "doughnut",
        data: data,
      });
      this.myChart;
    },
  },
  async created() {
    await this.fetchDoughnutChartTotals();
    this.createChartInctance();
  },
  unmounted() {
    this.destroy(this.myChart);
  },
};
</script>

<style></style>
