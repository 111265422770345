<template>
  <!-- Button trigger modal -->
  <button
    type="button"
    class="btn btn-primary d-none"
    data-bs-toggle="modal"
    data-bs-target="#newReportModal"
    id="newReportBtn"
  ></button>
  <!-- End Button trigger modal -->

  <!-- Modal -->
  <div
    id="newReportModal"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
    role="dialog"
    aria-labelledby="newReportModalTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="newReportModalTitle">
            Please fill out the form
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            id="closeRejectProposalModal"
          ></button>
        </div>

        <vee-form :validationSchema="newReport" @submit="makeReport">
          <div class="modal-body">
            <!-- final report Checkbox Switch -->
            <div class="form-check form-switch mb-4">
              <input
                type="checkbox"
                class="form-check-input"
                id="finalReport"
                name="finalReport"
                v-model="finalReport"
                @change="!finalReport"
              />
              <label class="form-check-label" for="formSwitch1"
                >This is my final report.</label
              >
            </div>
            <!-- End Checkbox Switch -->
            <!-- report title -->
            <div class="mb-3">
              <label class="form-label" for="ReportTitle"
                >Report title<i class="text-danger">*</i></label
              >
              <vee-field
                type="text"
                id="ReportTitle"
                name="reportTitle"
                class="form-control"
                placeholder="Report title"
              />
              <error-message class="text-danger" name="reportTitle" />
            </div>
            <!-- report content -->
            <div class="mb-3">
              <label class="form-label" for="reportBody">Aditional info</label>
              <!-- QuillEditor block -->
              <QuillEditor
                theme="snow"
                contentType="html"
                v-model:content="editorContent"
                :toolbar="[
                  'bold',
                  'italic',
                  'underline',
                  'strike',
                  { header: [1, 2, 3, false] },
                  { color: [] },
                  { font: [] },
                  { align: [] },
                  'clean',
                ]"
              />
              <vee-field
                class="d-none"
                as="textarea"
                name="reportContent"
                v-model="editorContent"
              />
              <error-message class="text-danger" name="reportContent" />
              <!-- end QuillEditor -->
            </div>
            <!-- report file -->
            <uploadReport
              :report="report"
              :setReport="setReport"
              :finalReport="finalReport"
            />
            <!-- report file ends here -->
          </div>
          <div class="modal-footer">
            <p
              class="fw-bold d-block w-100 mb-2"
              :class="messageClass"
              v-if="message.length > 0"
            >
              {{ message }}
            </p>
            <button
              v-if="!in_submission"
              type="submit"
              class="btn btn-primary-dark"
              @click="verifyContentLength"
            >
              Send report
            </button>
            <div
              class="spinner-border m-5 text-primary"
              role="status"
              v-if="in_submission"
            >
              <span class="visually-hidden">Loading...</span>
            </div>
            <button
              type="button"
              class="btn btn-white"
              data-bs-dismiss="modal"
              id="closeReportBtn"
            >
              Close
            </button>
          </div>
        </vee-form>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>

<script>
import { QuillEditor } from "@vueup/vue-quill";
import uploadReport from "@/components/reports/uploadReport";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import axiosConfig from "@/includes/axiosConfig";

export default {
  name: "newReport",
  components: {
    QuillEditor,
    uploadReport,
  },
  props: {
    researchId: {
      required: true,
      type: Number,
    },
    getResearchInfo: {
      required: true,
      type: Function,
    },
    fetchReports: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      newReport: {
        reportTitle: "required|alpha_spaces|min:10|max:100",
        reportContent: "min:10|max:500",
      },
      report: [],
      finalReport: false,
      in_submission: false,
      message: "",
      messageClass: "",
      editorContent: null,
    };
  },
  methods: {
    async makeReport(val, { resetForm }) {
      this.in_submission = true;
      val.finalReport = this.finalReport;
      if (this.report.length > 0) {
        let data = new FormData();
        data.append("reportFile", this.report[0]);
        data.append("reportTitle", val.reportTitle);
        data.append("reportContent", val.reportContent);
        data.append("finalReport", val.finalReport);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
            Accept: "application/json",
          },
        };
        await axiosConfig
          .post(`api/makeReport/${this.researchId}`, data, config)
          .then((res) => {
            this.messageClass = "text-success";
            this.message = "the report has been made successfully.";
            setTimeout(() => {
              document.getElementById("closeReportBtn").click();
              this.getResearchInfo();
              this.fetchReports();
              this.report = [];
              this.editorContent = null;
              resetForm();
            }, 2000);
            console.log(res);
          })
          .catch((err) => {
            this.messageClass = "text-danger";
            this.message = "error, please try again later.";
            console.log(err.response);
          });
      } else {
        this.messageClass = "text-danger";
        this.message =
          "please make sure that you have uploaded 1 file that has PDF or word document extensions and the maximum size is 1 MB";
      }
      this.in_submission = false;
    },
    // for setting the report file
    setReport(report) {
      report == null ? (this.report = []) : this.report.push(report);
    },
  },
};
</script>

<style scoped>
.modal-full-height {
  height: 100%;
}
</style>
