<template>
  <!-- CTA -->
  <div class="container content-space-1">
    <!-- Heading -->
    <div class="w-lg-65 text-center mx-lg-auto mb-7">
      <h3>Your connection to the internet has been lost.</h3>
      <div class="fs-6 ps-5 text-start">
        <p>Try:</p>
        <ul>
          <li>Checking the network cables, modem, and router.</li>
          <li>Reconnecting to Wi-Fi.</li>
          <li>
            <a>Running Windows Network Diagnostics.</a>
          </li>
        </ul>
      </div>
    </div>
    <!-- End Heading -->

    <div class="text-center">
      <a class="btn btn-primary" @click="reloadPage">Refresh page</a>
    </div>
  </div>
  <!-- End CTA -->
</template>

<script>
export default {
  name: "noInternetConection.vue",
  methods: {
    reloadPage() {
      location.reload();
    },
  },
};
</script>
