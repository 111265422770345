<template>
  <!-- chart text -->
  <div class="text-center">
    <h6 class="research-title">
      Committee Performance Metrics Mapped on a Radar Chart
    </h6>
  </div>
  <div class="col-8 m-auto mt-5">
    <membersSearch @member-selected="handleMemberSelected" />
  </div>
  <div v-if="in_submission" class="d-flex justify-content-center text-primary">
    <div class="spinner-border" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
  <div v-if="!in_submission">
    <canvas id="radarChartMPI"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js/auto";
import axiosConfig from "@/includes/axiosConfig";
import membersSearch from "@/components/statistics/radarChart/membersSearch.vue";

export default {
  name: "radarChartMPI",
  components: {
    membersSearch,
  },
  props: {
    destroy: {
      required: true,
      type: Function,
    },
  },
  data() {
    return {
      in_submission: false,
      labels: [
        "Top Assignees (most assigned researches)",
        "Faculty Spread (researches assigned from different programs)",
        "High Activity (most actions taken)",
      ],
      datasets: [
        {
          label: "Average Speed Response",
          data: [0, 0, 0],
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          pointBackgroundColor: "rgb(255, 99, 132)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(255, 99, 132)",
        },
      ],
      myChart: null,
      reseacherId: null,
    };
  },
  methods: {
    async fetchradarChartMPI() {
      this.in_submission = true;
      await axiosConfig
        .post("api/radarChartMPI", { reseacher_id: this.reseacherId })
        .then((res) => {
          console.log(res);
          this.datasets = [
            {
              label: `Average Speed Response from the first assigned date to the first taken action for each research (average in days): ${res.data.averageSpeedResponse} days`, // Concatenated label
              data: [
                res.data.totalAssignees,
                res.data.totalSpread,
                res.data.totalActions,
              ],
              backgroundColor: "rgba(255, 99, 132, 0.2)",
              borderColor: "rgb(255, 99, 132)",
              pointBackgroundColor: "rgb(255, 99, 132)",
              pointBorderColor: "#fff",
              pointHoverBackgroundColor: "#fff",
              pointHoverBorderColor: "rgb(255, 99, 132)",
            },
          ];
        })
        .catch((err) => {
          console.log(err.response);
        });
      this.in_submission = false;
      this.destroy(this.myChart);
      this.$nextTick(() => {
        this.createChartInctance();
      });
    },
    createChartInctance() {
      const ctx = document.getElementById("radarChartMPI");
      if (ctx) {
        const data = {
          labels: this.labels,
          datasets: this.datasets,
        };
        this.myChart = new Chart(ctx, {
          type: "radar",
          data: data,
          options: {
            layout: {
              padding: {
                top: 1, // Adjust top padding
                left: 1, // Adjust left padding
                right: 1, // Adjust right padding
                bottom: 1, // Adjust bottom padding
              },
            },
            elements: {
              line: {
                borderWidth: 3,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  // Remove label on hover by returning an empty string
                  label: function (number) {
                    return `${number.label}: ${number.formattedValue}`; // This will remove the tooltip text
                  },
                },
              },
            },
          },
        });
      }
    },
    // Handle the selected member and trigger the fetch function
    handleMemberSelected(reseacherId) {
      this.reseacherId = reseacherId; // Update reseacher_id
      this.fetchradarChartMPI(); // Fetch the data for the new member
    },
  },
  async created() {
    // Initial chart creation
    this.$nextTick(() => {
      this.createChartInctance();
    });
  },
  unmounted() {
    if (this.myChart) {
      this.destroy(this.myChart);
    }
  },
};
</script>

<style></style>
